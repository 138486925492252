import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { CommonService } from '../common.service';
import { isUndefined } from 'util';
import { ApiService } from 'src/app/_services/api.service';
import { SearchComponent } from '../search/search.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-ngx-table',
  templateUrl: './ngx-table.component.html',
  styleUrls: ['./ngx-table.component.css']
})
export class NgxTableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  dataColumnList = [];
  apiLink: any = '';
  filterApi: any = '';
  pagination: any = {
    totalItems: 0,
    startIndex: 0,
    lastIndex: 30
  }
  individualFilterList = {}
  title: any = '';
  limitedPagination: any = {
    totalItems: 0,
    startIndex: 0,
    lastIndex: 15
  }
  perPage: any = 15;
  compact_css: boolean = false;
  limitedList: any = [];
  overAllListCount: any = 0;
  previousDisp: boolean = false;
  nextDisp: boolean = false;
  screentype = "fullscreen";
  last_toolTip: any;
  addLable: any = '';
  routingLable: any = '';
  userBasedCount: any = 30;
  @Input() set tableDatas(value: any) {
    this.dataColumnList = JSON.parse(JSON.stringify(value['dataToDisplay']));
    this.apiLink = value['api'],
      this.filterApi = value['filterApi'],
      this.addLable = value['addLable'],
      this.routingLable = value['routingLable'],
      this.title = value['title'],
      this.userBasedCount = (value['count'] < 30) ? 30 : value['count'];
    this.pagination['lastIndex'] = (value['count'] < 30) ? 30 : value['count'];
  }
  viewList: any = [];
  filterList = [];
  @Input() resetModel: any;
  @Output() selectedata = new EventEmitter();

  constructor(
    private commonService: CommonService,
    private apiService: ApiService,
    private modalService: NgbModal,
  ) {

  }

  tableType: any = '';
  ngOnInit() {
    this.tableType = this.dataColumnList[0]['parentId'];
    this.commonService.loadspinnerService.next(true);
    this.gettotalRows();
    this.dataColumnList.forEach(ele => {
      this.individualFilterList[ele.id] = [];
    });
  }
  gettotalRows() {
    this.apiService.post(this.apiService.getEnvironment()[this.apiLink], { startIndex: 0, lastIndex: 0, totalRows: 0 }).subscribe(resp => {
      if (resp['status']) {
        this.overAllListCount = ('otherInfo' in resp) ? ('totalRows' in resp['otherInfo']) ? resp['otherInfo']['totalRows'] : '' : '';
        this.limitedPagination['totalItems'] = this.overAllListCount;
        this.pagination['totalItems'] = this.overAllListCount;
        this.initializePagination();
      }
      else {
        this.commonService.showToastr.error(resp['message']);
        this.commonService.loadspinnerService.next(false);
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    (!isUndefined(changes.resetModel.previousValue)) && this.resetModel['key'] == 'reload' ? this.recallApi() : '';
  }

  ngAfterViewInit() {
    this.last_toolTip = document.getElementById('last_toolTip');
    this.last_toolTip.setAttribute('tooltip', 'Maximize');
  }

  initializePagination = () => {
    this.apiService.post(this.apiService.getEnvironment()[this.apiLink], this.pagination).subscribe(resp => {
      if (resp['status']) {
        this.overAllListCount = ('otherInfo' in resp) ? ('totalRows' in resp['otherInfo']) ? resp['otherInfo']['totalRows'] : '' : '';
        this.limitedList = ('response' in resp) ? resp['response'] : [];
        this.viewList = this.limitedList.slice(0, 15);
        this.commonService.loadspinnerService.next(false);
      }
      else {
        this.commonService.showToastr.error(resp['message']);
        this.commonService.loadspinnerService.next(false);
      }
      this.paginationDisable();
    })
  }

  resetPagination() {
    this.perPage = 15;
    this.limitedPagination['startIndex'] = 0;
    this.limitedPagination['lastIndex'] = 15;
  }

  previous = () => {
    this.limitedPagination['startIndex'] = this.limitedPagination['startIndex'] - (this.perPage);
    this.limitedPagination['lastIndex'] = this.limitedPagination['lastIndex'] - (this.perPage);
    this.viewList = this.limitedList.slice(this.limitedPagination['startIndex'], this.limitedPagination['lastIndex']);
    this.paginationDisable();
  }
  next = () => {
    this.limitedPagination['startIndex'] = this.limitedPagination['startIndex'] + (this.perPage);
    this.limitedPagination['lastIndex'] = this.limitedPagination['lastIndex'] + (this.perPage);
    this.viewList = this.limitedList.slice(this.limitedPagination['startIndex'], this.limitedPagination['lastIndex']);
    (this.viewList.length < this.perPage && this.limitedList.length < this.overAllListCount) ? this.nextDataList() : '';
    this.paginationDisable();
  }

  paginationDisable = () => {
    this.previousDisp = (this.limitedPagination['startIndex'] == 0) ? true : false;
    this.nextDisp = (this.viewList.length < this.perPage && this.limitedList.length == this.overAllListCount) ? true :
      (this.limitedPagination['lastIndex'] >= this.overAllListCount) ? true : false;
  }

  nextDataList = () => {
    let details = {
      totalRows: this.overAllListCount,
      startIndex: this.limitedList.length,
      lastIndex: this.limitedList.length + 30
    }
    if (this.filterList.length) {
      this.commonService.loadspinnerService.next(true);
      details['smartSearchVb'] = this.filterList;
      this.apiService.post(this.apiService.getEnvironment()[this.filterApi], details).subscribe(resp => {
        if (resp['status']) {
          let list = ('response' in resp) ? resp['response'] : [];
          this.limitedList = [...this.limitedList, ...list];
          this.viewList = this.limitedList.slice(this.limitedPagination['startIndex'], this.limitedPagination['lastIndex']);
          this.paginationDisable();
          this.commonService.loadspinnerService.next(false);
        }
        else {
          this.commonService.loadspinnerService.next(false);
        }
      })
    } else {
      this.commonService.loadspinnerService.next(true);
      this.apiService.post(this.apiService.getEnvironment()[this.apiLink], details).subscribe(resp => {
        if (resp['status']) {
          let list = ('response' in resp) ? resp['response'] : [];
          this.limitedList = [...this.limitedList, ...list];
          this.viewList = this.limitedList.slice(this.limitedPagination['startIndex'], this.limitedPagination['lastIndex']);
          this.paginationDisable();
          this.commonService.loadspinnerService.next(false);
        }
        else {
          this.commonService.showToastr.error(resp['message']);
          this.commonService.loadspinnerService.next(false);
        }
      })
    }
  }

  getPaginationDet = () => {
    let first = this.limitedPagination['startIndex'] + 1;
    let last = (this.limitedPagination['lastIndex'] > this.overAllListCount) ? this.overAllListCount :
      this.limitedPagination['lastIndex'];
    let aa = `${first} - ${last}  of 
      ${this.overAllListCount}`;
    return aa;
  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  filterModal = (type?) => {
    const modelRef = this.modalService.open(SearchComponent, {
      size: 'lg',
      backdrop: 'static'
    });
    modelRef.componentInstance.dataToDisplay = this.dataColumnList;
    modelRef.componentInstance.searchData = this.filterList;
    modelRef.componentInstance.filterData.subscribe((e) => {
      if (e.flag == "apply") {
        this.filterList = e.data;
        this.applyFilterData(this.filterList);
        this.individualFilterDisp();
      } else if (e.flag == "reset") {
        this.filterList = e.data;
        this.resetFilter();
        this.individualFilterDisp();
      }
      modelRef.close();
    });
  }

  individualFilterDisp = () => {
    this.dataColumnList.map(ele => { ele['filter'] = false; });
    this.dataColumnList.forEach(ele => {
      this.individualFilterList[ele.id] = [];
    });
    this.filterList.map(ele => {
      let pos = this.dataColumnList.map(ele => { return ele.id }).indexOf(ele.object);
      if (pos != -1) {
        this.dataColumnList[pos]['filter'] = true;
        this.individualFilterList[ele.object] = [...this.individualFilterList[ele.object], ...[ele]];
      }
    });
  }


  individualFilter = (key) => {
    const modelRef = this.modalService.open(SearchComponent, {
      size: 'lg',
      backdrop: 'static'
    });
    modelRef.componentInstance.dataToDisplay = [key];
    modelRef.componentInstance.type = 'single';
    modelRef.componentInstance.searchData = this.individualFilterList[key.id];
    modelRef.componentInstance.filterData.subscribe((e) => {
      if (e.flag == "apply") {
        this.filterList = this.filterList.filter((resp, ind, arr) => {
          if (resp.object != key.id) {
            return true;
          }
        })
        this.individualFilterList[key.id] = e.data;
        this.filterList = [...this.filterList, ...e.data]
        this.applyFilterData(this.filterList);
        this.individualFilterDisp();
      } else if (e.flag == "reset") {
        this.filterList = this.filterList.filter((resp, ind, arr) => {
          if (resp.object != key.id) {
            return true;
          }
        })
        // this.filterList = e.data;
        this.individualFilterList[key.id] = [];
        this.individualFilterDisp();
        if (this.filterList.length) {
          this.applyFilterData(this.filterList);
        } else {
          this.resetFilter();
        }
      }
      modelRef.close();
    });
  }

  applyFilterData = (filterList) => {
    let details = Object.assign({}, this.pagination);
    this.perPage = 15;
    // delete details['totalRows'];
    details['smartSearchVb'] = filterList;
    this.commonService.loadspinnerService.next(true);
    this.limitedList = [];
    this.viewList = [];
    this.overAllListCount = 0;
    this.limitedPagination['startIndex'] = 0;
    this.limitedPagination['lastIndex'] = 15;
    this.apiService.post(this.apiService.getEnvironment()[this.filterApi], details).subscribe(resp => {
      if (resp['status']) {
        this.overAllListCount = ('otherInfo' in resp) ? ('totalRows' in resp['otherInfo']) ? resp['otherInfo']['totalRows'] : '' : '';
        this.limitedPagination['totalItems'] = this.overAllListCount;
        this.pagination['totalItems'] = this.overAllListCount;
        this.limitedList = ('response' in resp) ? resp['response'] : [];
        this.viewList = this.limitedList.slice(0, 15);
        this.paginationDisable();
        this.commonService.loadspinnerService.next(false);
      }
      else {
        this.commonService.loadspinnerService.next(false);
      }
    })
    this.paginationDisable();
  }

  recallApi = () => {
    if (this.filterList.length) {
      this.applyFilterData(this.filterList);
    } else {
      this.initializePagination();
    }
    this.resetPagination();
  }

  listCountDisp = () => {
    this.perPage = parseInt(this.perPage);
    this.limitedPagination['startIndex'] = 0;
    this.limitedPagination['lastIndex'] = this.perPage;
    this.viewList = this.limitedList.slice(this.limitedPagination['startIndex'], this.limitedPagination['lastIndex']);
    this.paginationDisable();
  }

  resetFilter() {
    this.resetPagination();
    this.initializePagination();
  }


  listInlineFunc = (item, action) => {
    var data = {
      items: item,
      action: action
    };
    this.selectedata.emit(data);
  }


  shareIconVisible = (value: any) => {
    let return_value = true;
    return_value = this.tableType != 'catalog' || (this.tableType == 'catalog' &&
      value.hasOwnProperty('vcStatusDesc') && value.vcStatusDesc == 'Published') ? true : false;
    return return_value;
  }

  styleClass = (key: any, array: any) => {
    if (key == 'Catalog ID') {
      return "wd70 right-padding-5";
    }
    else if (key == 'Flag') {
      return "wd10";
    }
    else if (key == 'Status' || key == 'Script Type' || key == 'Connector ID' || key == 'Maker') {
      if (array[0].columnName.split(' ')[0] == 'Connector')
        return "wd100 "
    }
  }

  displayProp = (align: any, key: any) => {
    let css_prop = `${align} `;
    if (key.hasOwnProperty('display')) {
      css_prop = css_prop + key.display;
    }
    return css_prop;
  }


  statusStyle = (key: any, items: any) => {
    if (key == 'vcrStatusDesc' || key == 'vcqStatus' || key == 'statusDesc') {
      let css_prop = 'status-btn ';
      if (items == 'Delete') {
        css_prop += 'status-btn-danger'
      }
      else if (items == 'Active') {
        css_prop += 'status-btn-success'
      }
      else if (items == 'Old') {
        css_prop += 'status-btn-warning'
      }
      return css_prop;
    } else {
      return ''
    }
  }


  previewScreen = () => {
    let screensize = document.getElementById('maximize');
    screensize.classList.contains('fullscreen') ? this.minimize(screensize) :
      this.maximize(screensize);
  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  minimize = (minimize: any) => {
    document.getElementById("add").style.display = "block";
    document.getElementById("filter").style.display = "block";
    document.getElementById("link").style.display = "block";
    document.getElementById("sep").style.display = "block";
    minimize.classList.remove('fullscreen');
    this.screentype = 'fullscreen';
    this.last_toolTip.setAttribute('tooltip', 'Maximize');
    let tooltip = document.getElementsByClassName('tool_tip');
    for (var i = 0; i < tooltip.length; i++) {
      tooltip[i].removeAttribute('flow')
    }
  }


  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  maximize = (maximize: any) => {
    document.getElementById("add").style.display = "none";
    document.getElementById("filter").style.display = "none";
    document.getElementById("link").style.display = "none";
    document.getElementById("sep").style.display = "none";
    maximize.classList.add('fullscreen');
    this.screentype = 'fullscreen_exit';
    this.last_toolTip.setAttribute('tooltip', 'Minimize');
    let tooltip = document.getElementsByClassName('tool_tip');
    for (var i = 0; i < tooltip.length; i++) {
      tooltip[i].setAttribute('flow', 'left')
    }
  }

  ngOnDestroy() {
  }

}