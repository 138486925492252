import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import { isUndefined } from 'util';
import { CommonFilterPaginationService } from 'src/app/sharedModules/common-filter-pagination.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-magnifier-popup',
  templateUrl: './magnifier-popup.component.html',
  styleUrls: ['./magnifier-popup.component.css']
})
export class MagnifierPopupComponent implements OnInit, OnDestroy,AfterViewInit{

  constructor(
    private filterService: CommonFilterPaginationService,
  ) { };
  overAll_check: boolean = false;
  magDetails: any = {};
  magArray: any = {};
  dataColumnList: any = [];
  no_dataFoud: any = false;
  searchToggle: any = true;
  subscription: Subscription;
  totalData: any = '';
  first_index: any = '';
  totalItems: any = 15;
  allDisabled: any=false;
  allType: any = false;
  key : any =[];
  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  @Input() set getDetails(magDet: any) {
    this.magArray = magDet['magList']
    this.magDetails = magDet;
    this.key = magDet['magList'].length ? Object.keys(magDet['magList'][0]).slice(0, 2) : [];
    this.allType = magDet['allType'] || false;
  }
  @Output() repBack = new EventEmitter();
  @Output() afterSelected = new EventEmitter();

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  ngOnInit() {
    this.framingTable();
  }

  ngAfterViewInit(){
    if(this.magDetails['selection_type']=='multiple' && this.magDetails['selectedList'][0] =='ALL'){
        document.getElementById("allCheck")['checked']=true;
    }
  }

  allCheck(event) {
    this.allDisabled=event.target.checked;
    if (event.target.checked) {
      this.filterService.allCheck();
    }
  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  framingTable() {
    if (!isUndefined(this.magArray)) {
      this.magArray.forEach((element, index, array) => {
        if (this.magDetails['selectedList'].includes(element[this.magDetails['useColumn']])) {
          array[index]['isChecked'] = true;
        }
        else {
          array[index]['isChecked'] = false;
        }
      });
      let key = this.magArray.length ? Object.keys(this.magArray[0]).slice(0, 2) : [];
      this.dataColumnList = [...['isChecked'], ...key];
      this.filterService.getDummyData(this.magArray);
      this.subscription = this.filterService.limited_data.subscribe(resp => {
        this.dataFormating(resp);
      })
    }
    else {
      this.no_dataFoud = true;
    }
  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  individualCheck(selectedData: any, key) {
    this.filterService.mainCheckbox();
    this.filterService.individualCheck(this.magDetails.selection_type, selectedData);
  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  overallSelect() {
    this.filterService.mainCheckbox();
    this.overAll_check = !this.overAll_check;
    this.filterService.overAllCheck(this.overAll_check);

  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  mainChecked() {
    this.overAll_check = this.filterService.mainCheckbox();
  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  dataFormating(resp) {
    if (resp.length) {
      if (resp[0].hasOwnProperty('total')) {
        this.totalData = resp[0]['total'];
        this.totalItems = resp[0]['totaldata_display']
        this.first_index = resp[0]['first'];
        this.magArray = resp[0]['list_user'];
      }
      else {
        this.magArray = resp[0]['list_user'];
        this.totalItems = resp[0]['totaldata_display'];
      }
    }
  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  next() {
    this.filterService.next();
  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  previous() {
    this.filterService.previous();
  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  paginationDisplay() {
    let Start = isUndefined(this.magArray[0]) ? 0 : this.magArray[0]['pagination_id'];
    let end = isUndefined(this.magArray[this.magArray.length - 1]) ? 0 :
      this.magArray[this.magArray.length - 1]['pagination_id'];
    return ` ${Start} - ${end} of ${this.totalData}`
  }


  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  onChange(event) {
    this.filterService.totalDataToDisplay(event);
  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  searchData() {
    let filter_obj = {}
    filter_obj[this.magDetails['filter_key']] = document.getElementById(this.magDetails['filter_key'])['value'];
    filter_obj['type'] = 'contains';
    this.filterService.filterData([filter_obj]);
    this.searchToggle = false;
  }


  customFilter(event){
    this.filterService.filterData2(event,this.key);
  }
  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  refreshFilter() {
    let filter_obj = {}
    document.getElementById(this.magDetails['filter_key'])['value'] = '';
    this.filterService.filterData([filter_obj]);
    this.filterService.resetFilter();
    this.searchToggle = true;
  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  closeModel() {
    this.repBack.emit(true);
  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  apply() {
    let selected_data = this.filterService.apply();
    let key_words: any = [];
    selected_data.forEach(element => {
      key_words.push(element[this.magDetails.useColumn])
    });
    key_words = (key_words.length) ?  key_words : ['ALL'];    
    this.magDetails['selectedList'] = key_words;
    this.afterSelected.emit(this.magDetails);
    this.repBack.emit(true);
  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  ngOnDestroy() {
    this.filterService.resetPagination();
  }

}
