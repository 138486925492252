import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvService } from '../env.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'charset': 'UTF-8',
    'Access-Control-Allow-Origin': '*'
  })
}
@Injectable({
  providedIn: 'root'
})

export class RootService {
  nodeUrl: string;
  client: string;

  constructor(private http: HttpClient, private env: EnvService) {
    this.nodeUrl = env.nodeUrl;
    this.client = env.client;
  }

  //------------------------------------------------------------
  // HANDLE ERROR
  //------------------------------------------------------------
  private handleError = (error: any) => {
    let errorMessage = '';
    // this.modelService.dismissAll();
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      errorMessage = `${error.error.message}`;
    }
    return throwError(errorMessage);
  }
  //------------------------------------------------------------
  // GET METHOD
  //------------------------------------------------------------
  public get(relativeUrl: string): Observable<any> {
    return this.http.get(this.nodeUrl + relativeUrl, httpOptions).pipe(
      catchError(this.handleError)
    )
  }


  //------------------------------------------------------------
  // POST METHOD
  //------------------------------------------------------------
  public post(relativeUrl: string, data: any) {
    return this.http.post(this.nodeUrl + relativeUrl, data,
      httpOptions).pipe(
        catchError(this.handleError)
      );

  }
}
