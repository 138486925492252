export const overallData={
    formData:{
         queryId: '',
         queryDescription:'',
         databaseConnectivityDetails:'',
         recordIndData:'',
         recordIndicator:0,
         statusData:'',
         vcqStatus:0,
         lookupDataLoading: '',
         sqlQuery: '',
         stgQuery1: "",
         stgQuery2: "",
         stgQuery3: "",
         postQuery: "",
         queryColumnXML:"",
         hashVariableScript:'',
         queryType:''
       },
       screenData:{
        
       }
}