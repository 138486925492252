import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Renderer2, Input, Output, EventEmitter, HostListener, AfterViewInit } from '@angular/core';
import { DummyService } from '../dummy.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { Subscription } from 'rxjs';
import { isUndefined } from 'util';
import { element } from '@angular/core/src/render3';

@Component({
selector: 'app-user-management',
templateUrl: './user-management.component.html',
styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit, OnDestroy, AfterViewInit {

  listOfusers: any = [];
  selectedUserDetail: any = [];
  totalData: any = '';
  first_index: any = '';
  @ViewChild('overallcheck') overallcheck: ElementRef;
  @ViewChild('tableData') tableData: ElementRef;
  @Input('userDetails') userDetails;
  @Input('readability') readability: boolean = false;

  @Output('finaluserDetails') finaluserDetails = new EventEmitter;

  constructor(private service: DummyService,
    private modalService: NgbModal,
    private renderer: Renderer2,
    private _fb: FormBuilder) {
  }



  //----------------------------------------------------------------
  //  
  //----------------------------------------------------------------  
  ngOnInit() {
    this.listOfusers = this.userDetails;
  }

  //----------------------------------------------------------------
  //  
  //----------------------------------------------------------------
  ngAfterViewInit() {
    this.overAllChecked();
  }

  searchData(event) {

  }

  individualCheck(user: any) {
    this.listOfusers.forEach(element => {
      if (user.visionId == element.visionId) {
        element['isChecked'] = user['isChecked'];
      }
    });
    this.overAllChecked();
    this.getCheckedData();
  }

  overallSelect(event: any) {
    this.listOfusers.forEach((element, index) => {
      element['isChecked'] = event.target.checked;
    });
    this.getCheckedData();
  }

  overAllChecked() {
    let count = 0;
    this.listOfusers.forEach(element => {
      if (element['isChecked']) {
        count++;
      }
    });
    this.overallcheck.nativeElement.checked = (count == this.listOfusers.length) ? true : false;
    this.getCheckedData();
  }

  //------------------------------------------------------------
  //  AUTO SUGGESTION SEARCH
  /** @param event, @param allData, @param resultedData, @param searchBy */
  //------------------------------------------------------------
  public autoSuggestionSearch(event, allData, resultedData, searchBy, id) {
    const initialData = this[allData];
    this[resultedData] = initialData.filter(n =>
      (n[searchBy].toLowerCase().indexOf(event.target.value.toLowerCase()) >= 0) ||
      (n[id].toString().indexOf(event.target.value) >= 0));
    this.overAllChecked();
  }


  ngOnDestroy() {

  }

  //Here we are getting the set of userlist with selected Items
  getCheckedData() {    
    this.finaluserDetails.emit(this.listOfusers);
  }

}
