// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
    production: true,
    get_all_catalog: 'configCatalog/getAll',
    publish: 'configCatalog/catalogPublish',
    get_all_connector: 'dataSourceConnector/getAll',
    get_all_designQuery:'designAnalysis/getAll',
    get_listOf_catalog:'designAnalysis/listCatalog',
    designAnalysis_search:'designAnalysis/smartSearchFilter',
    get_all_MQ:'designAnalysis/getAllMQ',
    mq_searchList:'dcManualQuery/smartSearchFilter',
    get_tagList_DB: 'dataSourceConnector/getTagListDbCon',
    get_all_connector_name: 'dataSourceConnector/getAllValidConnectors',
    get_data_connector_table: 'configCatalog/getConnectorTables',
    get_pNumTab: 'numalpha/getNumTab',
    get_pAlphaTab: 'numalpha/getAlphaTab',
    get_data_connector_table_col: 'configCatalog/getConnectorTableCols',
    get_config_filter: 'configCatalog/smartSearchFilter',
    get_dataConnector_filter: 'dataSourceConnector/smartSearchFilter',
    get_data_design_analysis_par: 'designAnalysis/getQueryTree',
    get_data_design_analysis_child: 'designAnalysis/getQueryTreeColumns',
    get_editCatalog_data: 'configCatalog/get',
    get_connector_table_with_columns: 'configCatalog/getConnectorTableWithColsInfo',
    get_userGroupProfile: 'levelOfDisplay/userGroupProfile',
    get_restrictionTree: 'configCatalog/getRestrictionTree',
    get_catalogSrcByID: 'configCatalog/getDistinctCatalogSrc',
    get_tableAndColumnList: 'configCatalog/getTableColumnAlias',
    post_saveOverAllCatalog: 'configCatalog/saveAll',
    get_tableAndColumnProperties: 'configCatalog/getTableColumnData',
    get_tableRelations: 'configCatalog/getRelationDetailsForCatalog',
    post_userListExcludingUserGrpAndProf: 'levelOfDisplay/userListExcludingUserGrpAndProf',
    post_saveCatalog: 'configCatalog/saveLevelOfDisplay',
    delete_catalog: 'configCatalog/deleteCatalog',

    post_saveDataSource: "dataSourceConnector/saveLevelOfDisplay",
    get_dataConnector_shareDet: 'dataSourceConnector/getLevelOfDisplay',
    
    get_catalog_shareDet: 'configCatalog/getLevelOfDisplay',
    get_designAnalysis_magData: 'designAnalysis/getMagnefierData',
    get_designAnalysis_Execute: 'designAnalysis/execute',
    get_designAnalysis_Save: 'designAnalysis/save',
    get_designAnalysis_Hash: 'designAnalysis/getHashVariableList',
    get_designAnalysis_Load: 'designAnalysis/getReportListCatalogBased',
    get_designAnalysis_Render: 'designAnalysis/loadReportMetaData',
    get_designAnalysis_ExecuteSaved: 'designAnalysis/executeSaved',
    get_getHashVariableListForSavedReport: 'designAnalysis/getHashVariableListForSavedReport',
    delete_designAnalysis:'designAnalysis/deleteDesignQuery',

    getDynamicDate:'designAnalysis/generateDynamicDate',

    get_Widget_context: "widgetDesign/get",
    save_dashboard: "dashboardDesign/saveAll",
    get_widget_shareDet:"widgetDesign/getLevelOfDisplay",
    post_saveWidgetShare:"widgetDesign/saveLevelOfDisplay",
    delete_widget: "widgetDesign/deleteWidgetData",

    post_saveDashboardShare: "dashboardDesign/saveLevelOfDisplay",
    get_dashboard_shareDet: 'dashboardDesign/getLevelOfDisplay',
    set_share_privileges: 'dashboardDesign/updatePrivileges',
    save_default_dashboard:'dashboardDesign/saveFavoriteDashboard',

    getDashboardDetails: 'dashboards/getDashboardOnLoad',
    getMdmDashboardTabData:'dashboards/getDashboardTabData',
    getDrillDownData: 'dashboards/getDashboardDrillDownData',
    getTileData:'dashboards/getDashboardResultData',
    getDashboardGroupList:'dashboards/getDashboardList',

    delete_dashboard: "dashboardDesign/delete",
    get_widgets_List: "widgetDesign/getAll",
    get_dashboard_List: "dashboardDesign/getAll",
    edit_dashboard: "dashboardDesign/get",
    publish_dashboard: "dashboardDesign/dashboardPublish",

    get_colorPallet: "colorPalette/getActiveColors",
    get_chartType_Listing: "colorPalette/getChartTypes",
    post_colorPallet: "colorPalette/addOrModifyColor",
    get_chartXML: "widgetDesign/getWidgetData",
    post_widgetData: "widgetDesign/addWidgetData",
    post_modifywidgetData: "widgetDesign/updateWidgetData",
    get_widgetData: "widgetDesign/get",
    get_queryMetaData: "widgetDesign/getQueryMetadata",

    
  profile_page_load_values: 'raProfileSetup/pageLoadValues',
  profile_get_all_values: 'raProfileSetup/getAllQueryResults',
  profile_add: 'raProfileSetup/addProfileRa ',
  profile_modify: 'raProfileSetup/modifyProfileRa ',
  profile_delete: 'raProfileSetup/deleteProfileRa',


  get_visionMenu_page_value: 'visionMenu/pageLoadValues',
  get_menuGroup_list: 'visionMenu/getAllVisionMenuGroup',
  add_menuGroup: 'visionMenu/addMenu',
  update_menuGroup: 'visionMenu/modifyMenu',
  delete_menu: 'visionMenu/deleteMenu',
  reject_menu: 'visionMenu/rejectMenu',
  approve_menu: 'visionMenu/approveMenu',
  get_menuGroup_grid_list: 'visionMenu/getAllVisionMenu ',
  updateHomeDashboard:'raProfileSetup/updateHomeDashboard',
  get_Branch_List: 'magnifierControls/magnifierQuery',

    public_key: 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC3lNQKgVZJaTPu7DwlUcKLyOVr\n' +
        'fmHCpbMhf0b555sQodNqWaNJTO2dDzKrpuL5C829OOqIwrPMEAlQOjzUraJDa18/\n' +
        'lIrkY5jUCf18Ie6eTRXgBqzu84ZInDI2EqKJiFLR+ciIMAdPSRDXCsrtWfEuNUIV\n' +
        'jPFjU48MhnrBeIKJRQIDAQAB',
    private_key: 'MIICXQIBAAKBgQC3lNQKgVZJaTPu7DwlUcKLyOVrfmHCpbMhf0b555sQodNqWaNJTO2dDzKrpuL5C829OOqIwrPMEAlQOjzUraJDa18/lIrkY5jUCf18Ie6eTRXgBqzu\n' +
        '84ZInDI2EqKJiFLR+ciIMAdPSRDXCsrtWfEuNUIVjPFjU48MhnrBeIKJRQIDAQAB\n' +
        'AoGBAIA/4MJWCMnQTQ+kyX/o1liBSIUIDZPF3paQLC15t8KuytuJM6GJtJX8gBGa\n' +
        'zQXfUSPYF0DjhGgS6+64QLbzdl8kIUtYmMNKgEtQp25BhNg0GsSLt8ZBpNUowhV0\n' +
        'vpSc/xbxTCgAMX1HDQ911/DqQ0Jcx5yFBSkZZJOoLxEa0FoBAkEA34OL+usZW556\n' +
        'lMRTqTOaGFpHm0aNKyhfNKd64PR1tvrHhv0lvI4yBnZISnNjrc1DT1oYUQB6ncTJ\n' +
        '0l8MW4TVsQJBANJDe3N9X2Njv+7g3RfebzL4vXdGQOWaqghyRd6AQ7Sa+3YK5X2p\n' +
        '4tZibXRkBl9P/0uo9HDZb3NJcxOj3SM+zdUCQEeujoQIRfemGbYASLxo+jB5M6HO\n' +
        'vJNsvSvL0pK2k9H9F2RpZ2LTT6PW51RJMVyaCc43HLlNqAVVCUzYgsQPE+ECQDFK\n' +
        '6LIWfjpKtEBILwzddKw6bBB+Q9CQBbSdNYwH/ddlTfZKb6qaHTetM4PJv0Sw+GTA\n' +
        'Yy3hi6M3YOcFz13n51ECQQDGmpuPTKKyIyiyVf3A324qb01ogIeTPs7Z6uAcY+gA\n' +
        '4s/goOlfHWo46lVdHBdUgCtHpSko5vKB0NW1EO3KXLMh'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
