import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-generic-popup',
  templateUrl: './generic-popup.component.html',
  styleUrls: ['./generic-popup.component.css']
})
export class GenericPopupComponent implements OnInit {
  isPintitle: any;
  @Input() title: string;
  @Input() message: string;
  @Input() popupType: string = 'alert';
  @Output() userConfirmation: EventEmitter<any> = new EventEmitter<any>();

  constructor() {this.isPintitle="Pin" }

  ngOnInit() {
    
  }

  userCofirm(e) {    
    if (e == 'pin') {
      const isPin = document.getElementById('Layer_2').classList.contains('pin');
      this.isPintitle = (!isPin) ? "Unpin" : "Pin";
      (isPin) ? document.getElementById('Layer_2').classList.remove('pin') :
        document.getElementById('Layer_2').classList.add('pin');
    }
    this.userConfirmation.emit(e);
  }

}
