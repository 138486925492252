import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CatologService {


  databaseCon = new BehaviorSubject([]);
  manualquery = new BehaviorSubject({});
  fileuploaddata = new BehaviorSubject({});
  modifyData = new BehaviorSubject(false)


  constructor() { }


  databaseConection(data: any){
    this.databaseCon.next(data);
  }


  manualqueryConnection(data: any){
    this.manualquery.next(data);
  }


  fileConnection(data: any){
    this.fileuploaddata.next(data);
  }

  
  modifyDatas(data: any){
    this.modifyData.next(data);
  }
}
