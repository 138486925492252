import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  public apiUrl = '';
  public nodeUrl = '';
  public ssoUrl ='';
  public client = '';
  public clientId = '';
  public redirectUrl = '';
  public domain = '';
 public SSBIUrl = '';
 public ssoAuth:boolean;
 public MDMUrl = '';
 public VisionBIUrl = '';
 public AccountsUrl = ''
public product = [];
public dbConnectorDetails ='';

  // Whether or not to enable debug mode
  public enableDebug = true;

  constructor() {
  }

}