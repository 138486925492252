import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { isArray } from 'util';

@Injectable({
  providedIn: 'root'
})
export class ExecutedService {

  constructor() { }
  headers: any = [];
  rows: any = [];
  headerData = new BehaviorSubject([]);
  bodyData = new BehaviorSubject([]);

  unWrappingHeader(resp: any) {
    this.headers = isArray(resp['HEADER']) ? resp['HEADER'] : [resp['HEADER']];
    this.headers.forEach((element, index, array) => {
      let header = JSON.parse(array[index]);
      // let header = array[index];
      let ind = index + 1;
      array[index] = header['ROW' + ind]
    });
    return this.headers;
  }

 queryUnWrapping(resp: any) {
    this.rows = isArray(resp['BODY']) ? resp['BODY'] : [resp['BODY']];
    this.rows.forEach((element, index, array) => {
      let row = JSON.parse(array[index]);
      let ind = index + 1;
      array[index] = row['Row' + ind];
    });
    this.rows.forEach((element,ind,array) => {
      element.forEach((element_1,ind_1,array_1) => {
        element_1['class']= this.headers[0][ind_1]['data'];
      });
    });
    let display_data={
      'tableList':this.rows,
      'overAllData':this.rows.length
    }
    return display_data;
  }

}

