import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-deactivate-route',
  templateUrl: './deactivate-route.component.html',
  styleUrls: ['./deactivate-route.component.css']
})
export class DeactivateRouteComponent implements OnInit {

  @Output() closeBox = new EventEmitter();
  subject: Subject<boolean>;

  constructor() { }

  ngOnInit() {
  }
  exit(val){
    this.subject.next(val);
    this.closeBox.emit(val);
  }
}
