import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.css']
})
export class TreeViewComponent implements OnInit,OnChanges{

  private _collapseAll: boolean;
  private _selectAll: boolean;
  public checkbox: boolean=false;
  public nodes: any[] = [];
  public collapseAttr: string = 'isCollapsed';
  public selectAttr: string = 'isSelected';

  public deleteOption: boolean=false;
  public inDeterminateAttr: string = 'isIndeterminate';
  public optiondata: any={};
  public readability: boolean = false;
  @Input('data') data: any[];
  @Input('prepareData') prepareData: boolean = true;

  @Input('idAttr') idAttr: string = 'ID';

  @Input('parentAttr') parentAttr: string = 'userGroup';

  @Input('childrenAttr') childrenAttr: string = 'children';

  @Input('options') set options(value: {}){
     this.checkbox=value['checkbox'];
     this.optiondata=value;
     this.deleteOption=value['delete'];
     this.readability=false;
  }

  @Input('collapseAll')
  set collapseAll(value: boolean) {
    this._collapseAll = value;
    this._recursiveEdit(
        this.nodes, this.childrenAttr, this.collapseAttr, value);
  }

  @Input('selectAll')
  set selectAll(value: boolean) {
    this._selectAll = value;
    this._recursiveEdit(this.nodes, this.childrenAttr, this.selectAttr, value);
    this._recursiveEdit(
        this.nodes, this.childrenAttr, this.inDeterminateAttr, false);
  }

  @Output() onChange = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();


  @Output() onClick = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {
    const cloned = this.data.map(x => Object.assign({}, x));
    this.nodes = this.prepareData ? this._getPreparedData(cloned) : this.data;
  }
  ngOnChanges(){
    const cloned = this.data.map(x => Object.assign({}, x));
    this.nodes = this.prepareData ? this._getPreparedData(cloned) : this.data;
  }

  onModelChange(node) {
    if (node[this.childrenAttr].length) {
      this._recursiveEdit(
          [node], this.childrenAttr, this.selectAttr, node[this.selectAttr]);
    }
    this.onChange.emit(node);
  }


  nodeDataGet(node){
  }


  click(node: any) {
    if (node[this.childrenAttr].length) {
      node[this.collapseAttr] = !node[this.collapseAttr]
    }
    this.onClick.emit(node);
  }


  change(value: any) {
    const parent = this.nodes.filter(
        (item) => {return item.ID === value[this.parentAttr]})[0];
    if (parent) {
      let hasDifferent = false, duplicate = {},
          isIndeterminate = value[this.inDeterminateAttr] || false;

      parent[this.childrenAttr].forEach((item) => {
        duplicate[item[this.selectAttr]] =
            (duplicate[item[this.selectAttr]] || 0) + 1;
        if (item[this.inDeterminateAttr]) {
          isIndeterminate = true;
        }
      });
      if (Object.keys(duplicate).length === 1 && !isIndeterminate) {
        parent[this.inDeterminateAttr] = false;
        parent[this.selectAttr] = JSON.parse(Object.keys(duplicate)[0]);
        this.onChange.emit(parent);
      } else {
        parent[this.inDeterminateAttr] = true;
        this.onChange.emit(parent);
      }
    }
  }

  private _recursiveEdit(list, childrenAttr, attr, value) {
    if (Array.isArray(list)) {
      for (let i = 0, len = list.length; i < len; i++) {
        list[i][attr] = value;
        if (list[i][childrenAttr].length) {
          this._recursiveEdit(list[i][childrenAttr], childrenAttr, attr, value);
        }
      }
    }
  }

  private _getPreparedData(list) {
    let tree = [], lookup = {};
    for (let i = 0, len = list.length; i < len; i++) {
      lookup[list[i][this.idAttr]] = list[i];
      list[i][this.childrenAttr] = [];
      list[i][this.collapseAttr] = true;
      list[i][this.selectAttr] =  list[i][this.selectAttr] || false;
      list[i][this.inDeterminateAttr] = list[i][this.inDeterminateAttr] || false;
    }
    for (let i = 0, len = list.length; i < len; i++) {
      if (list[i][this.parentAttr]) {
        lookup[list[i][this.parentAttr]][this.childrenAttr].push(list[i]);
      } else {
        tree.push(list[i]);
      }
    }
    return tree;
  };
  onDeleteChange(value: any){
    this.onDelete.emit(value);
  }
 
  showPropertyRelationIcon(index: any){
    event.stopPropagation();
    var data= document.getElementById('selected'+index)['children'][0]
    data['style']['display']='inline-block';
  }
  hidePropertyRelationIcon(index: any){
    event.stopPropagation();
    var data= document.getElementById('selected'+index)['children'][0]
    data['style']['display']='none';
  }
  properties: any={
    relation: false,
    property: false
  }
  showRelation(event: any){
    event.stopPropagation();
    this.properties.relation=true;
    this.properties.property=false;
    document.getElementById('tablePropertyContainer').style.display = 'none';
    document.getElementById('relationContainer').style.display = 'block';
  }
  showProperty(event: any){
    event.stopPropagation();
    this.properties.relation=false;
    this.properties.property=true;
    document.getElementById('relationContainer').style.display = 'none';
    document.getElementById('tablePropertyContainer').style.display = 'block';
  }
}

