import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "../../../_services/api.service";
import { forkJoin } from "rxjs";
import { environment } from "../../../../environments/environment";
import { isUndefined, isArray } from 'util';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { filterModel } from '../Model/filterCategory';
import { MagnifierPopupComponent } from 'src/app/query-module/popup-components/magnifier-popup/magnifier-popup.component';
import { CommonService } from 'src/app/sharedModules/common.service';

@Component({
    selector: 'app-filter-screen',
    templateUrl: './filter-screen.component.html',
    styleUrls: ['./filter-screen.component.css']
})
export class FilterScreenComponent implements OnInit {


    //------------------------------------------------------------
    //  
    //------------------------------------------------------------
    displayName = '';
    seletedFileterColumn = '';
    selectedCategoryType = '';
    filterCategoryTypes = [];
    filterForm: FormGroup;
    filterFormSubmitted: boolean = false;
    filterAppliedStatus: boolean = false;
    marked: any = false;
    filterCategory = [
        {
            id: 'dropdown',
            text: 'Dropdown',
            type: [{ id: 'single', text: 'Single Select' }, { id: 'multi', text: 'Multiple Select' }]
        },
        {
            id: 'magnifier',
            text: 'Magnifier',
            type: [{ id: 'single', text: 'Single Select' }, { id: 'multi', text: 'Multiple Select' }]
        },
        {
            id: 'range',
            text: 'Range',
            type: [{ id: 'date', text: 'Date' }, { id: 'number', text: 'Number' }]
        },
        {
            id: 'default',
            text: 'Default',
            type: [
                { id: 'input', text: 'Input' },
                { id: 'number', text: 'Number' },
                { id: 'date', text: 'Date' }
            ]
        }
    ];

    config: any = {
        format: "DD-MMM-YYYY",
        allowMultiSelect: false,
        appendTo:'body'
    }
    magQueryID: any = [];
    magDisplayCol: any = [];
    magUseCol: any = [];
    dropDownCol: any = [];

    @Input('filterScreenData') filterScreenData;
    @Input('closeModal') closeModal;
    @Output('publishFilterScreenData') publishFilterScreenData = new EventEmitter();


    //------------------------------------------------------------
    //  
    //------------------------------------------------------------
    constructor(private formBuilder: FormBuilder,
        private modalService: NgbModal,
        private service: ApiService,
        private commonService: CommonService,
    ) {
        this.filterForm = this.formBuilder.group({
            filter_enable_flag: [true],
            display_name: ['', [Validators.required]],
            category: ['', [Validators.required]],
            selection_type: ['', [Validators.required]],
            query_id: [''],
            display_column: [''],
            use_column: [''],
            col_display_type: [''],
            tab_id: [''],
            col_id: [''],
            filter_category: [''],
            value1: [''],
            value2: [''],
            dropDownDetails: [''],
            // display_flag:[true],
            default_value1: [''],
            default_value2: [''],
            dateConversionSyntax:[''],
            dateFormattingSyntax:[''],
            // read_only_flag:[false],
            col_alias_name: ['']
        });
    }


    //------------------------------------------------------------
    //    "TAB_ID":element.tabelId,
    // "COL_ID":element.colId,
    // "COL_DISPLAY_TYPE":element.colDisplayType,
    // "COL_ALIAS_NAME": element.alias,
    // "DISPLAY_NAME": "",
    // "DEFAULT_VALUE": "",
    // "CATEGORY": "",
    // "SELECTION_TYPE": "",
    // "QUERY_ID": "",
    // "USE_COLUMN": "",
    // "DISPLAY_COLUMN": "",
    // "filtered":false
    //------------------------------------------------------------
    ngOnInit() {
        this.filterForm.patchValue({
            col_alias_name: this.filterScreenData.COL_ALIAS_NAME || '',
            display_name: this.filterScreenData.DISPLAY_NAME || this.filterScreenData.COL_ALIAS_NAME,
            query_id: this.filterScreenData.QUERY_ID || '',
            display_column: this.filterScreenData.DISPLAY_COLUMN || '',
            use_column: this.filterScreenData.USE_COLUMN || '',
            filter_enable_flag: this.filterScreenData.FILTER_ENABLE_FLAG || true,
            category: this.filterScreenData.CATEGORY || '',
            selection_type: this.filterScreenData.SELECTION_TYPE || '',
            col_display_type: this.filterScreenData['COL_DISPLAY_TYPE'],
            tab_id: this.filterScreenData['TAB_ID'],
            default_value1: this.changeType(this.filterScreenData['DEFAULT_VALUE1'], this.filterScreenData) || '',
            default_value2: this.filterScreenData['DEFAULT_VALUE2'] || '',
            value1: this.filterScreenData['value1'],
            value2: this.filterScreenData['value2'],
            col_id: this.filterScreenData['COL_ID'],
            filter_category: this.filterScreenData['COL_DISPLAY_TYPE'] == 'D' ? filterModel['D'] : filterModel['ND'],
            dateConversionSyntax:this.filterScreenData['dateConversionSyntax'],
            dateFormattingSyntax:this.filterScreenData['dateFormattingSyntax'],
        });
        this.marked = this.filterScreenData.FILTER_ENABLE_FLAG || true;
        this.filterScreenData.QUERY_ID.length ?
            this.callChange(this.filterScreenData.QUERY_ID) : '';
        this.filterScreenData.SELECTION_TYPE.length ? this.setDropDownValue(this.filterScreenData.CATEGORY) : '';

        this.filterFormControlValueChanged();
        this.dropDownCall();
        this.getdropDowDetails().subscribe(resp => {
            this.getDropDownList(resp);
        });
    }

    changeType(value, type) {
        if ( !isArray(value)  && type['SELECTION_TYPE'] == 'multi' && type['CATEGORY']=='Dropdown') {
            value = value.split(',');
        }
        return value;
    }


    //------------------------------------------------------------
    //  
    //------------------------------------------------------------
    setDropDownValue(event) {
        if (event != '') {
            const index = this.filterCategory.findIndex(x => x.text === event);
            if (index != -1) {
                this.filterCategoryTypes = this.filterCategory[index].type;
            }
        }
    }


    //------------------------------------------------------------
    //  
    //------------------------------------------------------------
    onSubmitFilterForm() {
        this.filterFormSubmitted = true;
        if (this.filterForm.invalid) {
            return
        } else {
            var final = {};
            var cc = Object.keys(this.filterForm.value);
            cc.forEach(element => {
                if (element != "value1" && element != "value2")
                    final[element.toUpperCase()] = this.filterForm.value[element];
                else
                    final[element] = '';
            });

            this.publishFilterScreenData.emit(final);

        }
    }


    //------------------------------------------------------------
    //  
    //------------------------------------------------------------
    selectFilterCategory(event) {
        if (event != '') {
            this.filterForm.patchValue({
                query_id: '',
                display_column: '',
                use_column: '',
                selection_type: '',
                value1: '',
                value2: '',
                dropDownDetails: '',
                default_value1: '',
                default_value2: ''
            });
            const index = this.filterCategory.findIndex(x => x.text === event);
            if (index != -1) {
                this.filterCategoryTypes = this.filterCategory[index].type;
            }
        }
    }


    //------------------------------------------------------------
    //  
    //------------------------------------------------------------
    filterFormControlValueChanged() {
        const magnifier_query_id = this.filterForm.get('query_id');
        const magnifier_display_column = this.filterForm.get('display_column');
        const magnifier_use_column = this.filterForm.get('use_column');
        this.filterForm.get('category').valueChanges.subscribe(
            (mode: string) => {
                if (mode === 'Magnifier' || mode === 'Dropdown') {
                    magnifier_query_id.setValidators([Validators.required]);
                    magnifier_display_column.setValidators([Validators.required]);
                    magnifier_use_column.setValidators([Validators.required]);
                }
                else {
                    magnifier_query_id.clearValidators();
                    magnifier_display_column.clearValidators();
                    magnifier_use_column.clearValidators();
                }
                magnifier_query_id.updateValueAndValidity();
                magnifier_display_column.updateValueAndValidity();
                magnifier_use_column.updateValueAndValidity();
            });

    }


    //------------------------------------------------------------
    //  
    //------------------------------------------------------------
    readOnly() {
        const readOnly = this.filterForm.get('read_only_flag');
        const defaultValue = this.filterForm.get('default_value');
        (readOnly.value) ?
            defaultValue.setValidators([Validators.required]) : defaultValue.clearValidators();
        defaultValue.updateValueAndValidity();
    }


    //------------------------------------------------------------
    //  
    //------------------------------------------------------------
    public closeModalFn() {
        this.publishFilterScreenData.emit(this.filterScreenData);
    }


    //------------------------------------------------------------
    //  
    //------------------------------------------------------------
    getdropDowDetails() {
        return forkJoin(
            this.service.get(environment.get_pNumTab + '?pNumTab=2002'),
            this.service.get(environment.get_pNumTab + '?pNumTab=2003'),
            this.service.post('dcManualQuery/getAllMagQuery?queryType=4', ''),
        );
    }


    //------------------------------------------------------------
    //  
    //------------------------------------------------------------
    getDropDownList(resp: any) {
        const magQueryID = resp[2]['response'];
        magQueryID.forEach(element => {
            this.magQueryID.push({ id: element.queryId, value: element.queryId, text: element.queryDescription })
        });
    }


    //------------------------------------------------------------
    //  
    //------------------------------------------------------------
    callChange(event) {

        this.magDisplayCol = [''];
        this.magUseCol = [''];
        this.dropDownCol = ['']

        this.service.post('dcManualQuery/getAllQueryCols?queryID=' + event, '').subscribe(
            (success) => {
            if(success['status']){
                this.magDisplayCol = JSON.parse(success['response']).columns.column;
                this.magUseCol = JSON.parse(success['response']).columns.column;
                this.dropDownCol = JSON.parse(success['response']).columns.column
            }
            else{
                this.commonService.showToastr.error("Query Configuration is Invalid");
            }
            },
            (error) => {
                this.magDisplayCol = [''];
                this.magUseCol = [''];
                this.dropDownCol = [''];
            }
        )
    }




    dropDownCall(param?) {
        this.filterForm.patchValue({
            "dropDownDetails": ''
        })
        if (
            (this.filterForm.get("category").value == 'Magnifier' || this.filterForm.get("category").value == 'Dropdown') &&
            this.filterForm.get("use_column").value.length && this.filterForm.get("query_id").value.length &&
            this.filterForm.get("display_column").value.length
        ) {
            let obj = {
                useColumn: this.filterForm.get("use_column").value,
                queryId: this.filterForm.get("query_id").value,
                displayColumn: this.filterForm.get("display_column").value,
            }
            this.service.post(environment.get_designAnalysis_magData, obj).subscribe(resp => {
                if (resp['status']) {
                    if (resp['response'].length) {
                        this.filterForm.patchValue({
                            "dropDownDetails": (this.filterForm.get("category").value == 'Dropdown') ?
                                this.listOfValues(resp['response']) : (this.filterForm.get("category").value == 'Magnifier') ?
                                    resp['response'] : [],
                        })
                        if (param) {
                            this.filterForm.patchValue({
                                default_value1: '',
                                default_value2: ''
                            })
                        }
                    }
                }
                else {

                }
            })
        }
    }

    displayColumnCheck() {
        this.filterForm.patchValue({
            use_column: '',
        })
    }

    dropDownCheck(element) {
        let arr = element;
        if (this.filterForm.get('display_column').value.length) {
            arr = element.filter(element => {
                if (element.name != this.filterForm.get('display_column').value) {
                    return true;
                }
            })
        }
        return arr
    }


    selectionTypeChange() {
        this.filterForm.patchValue({
            default_value1: '',
            default_value2: ''
        })
    }

    queryTypeChange() {
        this.filterForm.patchValue({
            display_column: '',
            use_column: '',
            dropDownDetails: ''
        })
    }



    selectionChange() {
        if (this.filterForm.get("selection_type").value == 'multi') {
            let bol_start = this.filterForm.get("default_value1").value.some(element => element == 'All');
            if (bol_start == true) {
                this.filterForm.patchValue({
                    default_value1: ['All']
                });
            }
        }
    }


    listOfValues(resp) {
        let data = Object.assign({}, resp[0]);
        for (let val in data) {
            data[val] = 'All'
        }
        return [data, ...resp];
    }

    listOfMultiValues(resp) {
        let data = Object.assign({}, resp[0]);
        for (let val in data) {
            if (val != 'isChecked' && val != 'id' && val != 'pagination_id') {
                data[val] = 'All'
            }
        }
        return [data, ...resp];

    }




    openModal() {
        const modelRef = this.modalService.open(MagnifierPopupComponent, {
            size: <any>'md',
            backdrop: 'static'
        });
        let filter = this.filterForm.value;
        let data = {
            selected_index: 1,
            input_key: 'default_value1',
            useColumn: filter.use_column,
            selectedList: Array.isArray(filter.default_value1) ? filter.default_value1 : filter.default_value1.split(','),
            selection_type: filter.selection_type == 'multi' ? 'multiple' : 'single',
            filter_key: filter.display_name,
            magList: filter.selection_type == 'multi' ? filter['dropDownDetails'] : this.listOfMultiValues(filter['dropDownDetails']),
            allType: filter.selection_type == 'multi' ? true : false
        }
        modelRef.componentInstance.getDetails = data;
        modelRef.componentInstance.afterSelected.subscribe((selected_element) => {
            this.filterForm.patchValue({
                default_value1: selected_element['selectedList']
            });
        });
        modelRef.componentInstance.repBack.subscribe((e) => {
            if (e) {
                modelRef.close();
            }
        });
    }


    //------------------------------------------------------------
    //  
    //------------------------------------------------------------
    toggleVisibility(e, type) {
        this.marked = e.target.checked;
    }
}