import { Component, OnInit, Input, HostBinding } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
@Component({
  selector: 'app-sidechild-menu',
  templateUrl: './sidechild-menu.component.html',
  styleUrls: ['./sidechild-menu.component.css'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({transform: 'rotate(0deg)'})),
      state('expanded', style({transform: 'rotate(180deg)'})),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})
export class SidechildMenuComponent implements OnInit {

  constructor() { }
  @Input() menu;
  expanded: boolean;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  ngOnInit() {
  }

  onItemSelected(item: any) {
    if (item.sub && item.sub.length) {
      this.expanded = !this.expanded;
      document.getElementById(item.name).classList.add('active');
    }
  }
}
