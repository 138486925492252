import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GenericPopupComponent } from './../sharedModules/generic-popup/generic-popup.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService {


  //------------------------------------------------------------
  // EMITOR/SUBJECTS
  //------------------------------------------------------------
  sideNav = new BehaviorSubject(true);
  dltFilter = new BehaviorSubject([]);
  compactTableProp = new BehaviorSubject(false);
  loadspinnerService = new BehaviorSubject(false);
  reportDetails = new BehaviorSubject({});
  // reportEditDetails = new BehaviorSubject({});
  toolBarTitle = new BehaviorSubject('');
  hashTagValue = new BehaviorSubject([]);
  favDashboard = new BehaviorSubject(false);

  //------------------------------------------------------------
  // PUBLIC VARIABLES AND DECLARATIONS
  //------------------------------------------------------------  
  data: boolean = true;


  //------------------------------------------------------------
  // CONSTRUCTOR
  //------------------------------------------------------------
  constructor(
    public translate: TranslateService,
    private toastr: ToastrService,
    private modalService: NgbModal) {
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('fr');
    translate.use(this.currentLanguage);
  };


  //------------------------------------------------------------
  // SIDEBAR NAVIGATION
  //------------------------------------------------------------
  sidenav() {
    this.data = !this.data;
    if (this.sideNav.observers.length > 1) {
      this.sideNav.observers[0].next(this.data)
    } else {
      this.sideNav.next(this.data);
    }
  }


  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  compactTable(data: any) {
    this.compactTableProp.next(data);
  }


  //------------------------------------------------------------
  // FILTER DATA
  //------------------------------------------------------------
  filterData(data: any) {
    this.dltFilter.next(data);
  }


  //------------------------------------------------------------
  //  LANGUAGE SETTINGS
  //-------------------------------------------------------------
  currentLanguage = 'en';


  //------------------------------------------------------------
  // Toastr Service
  //-------------------------------------------------------------
  showToastr = {
    success: (msg: any) => {
      this.toastr.success(msg);
    },
    warning: (msg: any) => {
      this.toastr.warning(msg);
    },
    error: (msg: any) => {
      let isPinMsg: boolean = false;
      const modelRef = this.modalService.open(GenericPopupComponent, {
        size: 'sm',
        backdrop: false,
        windowClass: 'errorPopup'
      });
      modelRef.componentInstance.title = 'Error';
      modelRef.componentInstance.message = msg;
      modelRef.componentInstance.popupType = 'error';
      modelRef.componentInstance.userConfirmation.subscribe((e) => {
        if (e == 'copy') {
          this.showToastr.success('Error Copied');
        }
        if (e == 'No') {
          modelRef.close();
        }
        if (e == 'pin') {
          isPinMsg = !isPinMsg;
          setTimeout(() => {
            if (!isPinMsg) {
              modelRef.close();
            }
          }, 1000);
        }
      });
      setTimeout(() => {
        if (!isPinMsg) {
          modelRef.close();
        }
      }, 5000);
    },
  }

}
