import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class TreeViewService {
  secondTree= new BehaviorSubject([]);
  constructor() { }

  pushTree(data){
    this.secondTree.next(data);
  }
}
