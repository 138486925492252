import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ExecutedService } from 'src/app/query-module/query-service/executedQueryService/executed.service';
import { CommonService } from '../common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ApiService } from 'src/app/_services/api.service';
import { environment } from 'src/environments/environment';
import { isNull } from 'util';

@Component({
  selector: 'app-execute-query-new',
  templateUrl: './execute-query-new.component.html',
  styleUrls: ['./execute-query-new.component.css']
})
export class ExecuteQueryNewComponent implements OnInit {

  @Input() getDetails: any;
  theadDetails: any = [];
  scrollingDetails: any = [];
  tbodyDetails: any = [];
  totalRows: any;
  startIndex: any;
  lastIndex: any;
  @Output() closeModelPopup = new EventEmitter();
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  constructor(private executeService: ExecutedService, private service: ApiService,
    private commonService: CommonService, private modelService: NgbModal) {
  }

  scalingFactor(value) {
    let factor = value == '1000' ? '(t)' : value == "1000000" ? '(m)' : value == "1000000000" ? "(b)" : '';
    return factor;
  }

  ngOnInit() {
    if ('response' in this.getDetails) {
      this.theadDetails = this.executeService.unWrappingHeader(this.getDetails['response']);
      let obj = this.executeService.queryUnWrapping(this.getDetails['response']);
      this.tbodyDetails = obj['tableList'];
    }
    this.getDetails['mainModel']['reportFields'].forEach(element => {
      if (element.colDisplayType == 'M') {
        const index = this.theadDetails[0].findIndex(x => x.data == element.alias);
        !isNull(element.scalingFormat) ? this.theadDetails[0][index]['scalingFormat'] = element.scalingFormat : '';
      }
    });

    this.theadDetails[0].forEach((element, i, a) => {
      this.theadDetails[0][i]['style'] = element.property.colDisplayType == 'M' ? 'align-right' : 'align-left';
      this.theadDetails[0][i]['colScaleFormatLabel'] = element.property.colDisplayType == 'M' ? element.property.colScaleFormatLabel:'';
    });
  }

  ngAfterViewInit() {
    this.totalRows = this.getDetails['totalRows'];
    this.startIndex = this.getDetails['startIndex'];
    this.lastIndex = this.getDetails['lastIndex'];
  }

  Flag: boolean = false;
  handler() {
    const vpDataLength = this.viewport.getRenderedRange().end;
    const dataLength = this.viewport.getDataLength();
    this.Flag = ((this.Flag) && (vpDataLength <= this.startIndex));
    if (!(this.Flag) && (vpDataLength >= dataLength - 100 && dataLength < this.totalRows)) {
      this.Flag = true;
      this.reportPopup();
    }
  }

  reportPopup() {
    this.startIndex = this.startIndex + 1000;
    this.lastIndex = this.lastIndex + 1000;
    let data = this.getDetails['mainModel'];
    data['mainModel']["startIndex"] = this.startIndex;
    data['mainModel']["lastIndex"] = this.lastIndex;
    // (this.getDetails['apilink'] == 'designAnalysis/executeSaved') ?
    data['mainModel']["tableName"] = this.getDetails['tableName'];
    let api = this.getDetails['apilink'];
    this.service.post(api, data).subscribe(resp => {
      if (resp['status']) {
        if ('response' in resp['response']) {
          let obj = this.executeService.queryUnWrapping(resp['response']['response']);
          this.tbodyDetails = [...this.tbodyDetails, ...obj['tableList']];
        }
      }
      else {
        this.commonService.loadspinnerService.next(false);
        this.commonService.showToastr.error(resp['message'])
      }
    })
  }

  scrolling() {
    this.tbodyDetails = [...this.tbodyDetails, this.scrollingDetails.slice(11, 20)];
  }

  closeModel() {
    this.modelService.dismissAll();
  }

}
