import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'formatDate'
})
export class DatePipe implements PipeTransform {
  transform(date: any, args?: any): any {
    var datepipe = date.split(' ');
    var obj = {
      '01': 'Jan',
      '02': 'Feb',
      '03': 'Mar',
      '04': 'Apr',
      '05': 'May',
      '06': 'Jun',
      '07': 'Jul',
      '08': 'Agu',
      '09': 'Sep',
      '10': 'Oct',
      '11': 'Nov',
      '12': 'Dec'
    }
    var date = datepipe[0].split('-');

    var dateformat = date[0] + "-" + obj[String(date[1])] + "-" + date[2];
    return dateformat;
  }
}