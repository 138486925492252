export * from './api.service';
export * from './jwt.service';
export * from './global.service';
export * from './loader.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { Interceptor } from './interceptors';

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
];