import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ApiService, JwtService, GlobalService } from '../../_services';
import { environment } from '../../../environments/environment';
import { isObject } from 'util';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-magnifier',
  templateUrl: './magnifier.component.html'
})
export class MagnifierSharedComponent implements OnInit {


  // ------------------------------------------------------------
  //  
  // ------------------------------------------------------------
  @Input() query;
  @Input() specialFilterValue;
  @Input() searchData;
  @Input() title;
  @Output() filterData: EventEmitter<any> = new EventEmitter<any>();
  branchList1;
  selectedData;
  startIndex;
  lastIndex;
  totalRows;
  branchList;
  Flag: boolean = false;
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  currentPage: any = 1;
  filterVal: boolean = false;
  checkEnter: boolean = false;
  requestPage: any;
  sendPage: any;


  // ------------------------------------------------------------
  //  
  // ------------------------------------------------------------
  constructor(private globalService: GlobalService,
    private _apiService: ApiService,
    private jwtService: JwtService,
    private cd:ChangeDetectorRef,
    private translate: TranslateService) {
    this.translate.setDefaultLang(this.jwtService.get('setLanguage'));
    this.translate.use(this.jwtService.get('setLanguage'));
    // GET LANGUAGE
    this.translate.currentLoader.getTranslation(this.translate.currentLang)
      .subscribe(data => {
      });
  }


  // ------------------------------------------------------------
  //  
  // ------------------------------------------------------------
  ngOnInit() {
    this.loadMagnifierData()
  }

  // ------------------------------------------------------------
  //  
  // ------------------------------------------------------------
  loadMagnifierData(searchData?) {
    this.startIndex = 0;
    this.lastIndex = 1000;
    let data: any = {};
    this.sendPage=this.currentPage;
    if (typeof (this.query) == 'object') {
      data = JSON.parse(JSON.stringify(this.query));
      data['startIndex'] = this.startIndex;
      data['lastIndex'] = this.lastIndex;
      data['currentPage'] = this.currentPage;
    } else {
      data = {
        'startIndex': this.startIndex,
        'lastIndex': this.lastIndex,
        'currentPage': this.currentPage,
        'query': this.query,
        'specialFilterValue': this.specialFilterValue
      };
    }

    //this.branchList1 = [{'id':'0', 'value':'aaaa'}, {'id':'1', 'value':'bbbb'}, {'id':'2', 'value':'cccc'},{'id':'3', 'value':'dddd'}]


    this._apiService.post(environment.get_Branch_List, data).subscribe((resp) => {
      if (resp['status']) {
        this.branchList1 = resp['response'];
        this.branchList = this.branchList1;
        this.totalRows = resp['otherInfo'].totalRows;
        this.currentPage = resp['otherInfo'].currentPage + 1;
        this.requestPage=resp['otherInfo'].currentPage;
        this.cd.detectChanges();
      }
    });
  }


  // ------------------------------------------------------------
  //  
  // ------------------------------------------------------------
  vpDataLength;
  dataLength;
  handler() {
      this.vpDataLength = this.viewport.getRenderedRange().end;
      this.dataLength = this.viewport.getDataLength();
    if (this.viewport.measureScrollOffset('bottom') < 25 && this.requestPage == this.sendPage) {
      //this.loadData();
     (this.Flag) ? (this.vpDataLength <= this.startIndex) ? this.Flag = false : '' : '';
      (!this.Flag) ? (this.vpDataLength >= this.dataLength - 500 && this.dataLength < this.totalRows) ? this.loadData() : '' : '';
    }
  }


  // ------------------------------------------------------------
  //  
  // ------------------------------------------------------------
  loadData() {
    if (!this.filterVal) {
      this.Flag = true;
      this.checkEnter = true
      this.startIndex = this.startIndex + 1000;
      this.lastIndex = this.lastIndex + 1000;
      this.sendPage=this.currentPage;
      let data: any = {};
      if (typeof (this.query) == 'object') {
        data = JSON.parse(JSON.stringify(this.query));
        data['startIndex'] = this.startIndex;
        data['lastIndex'] = this.lastIndex;
        data['currentPage'] = this.currentPage;
        data['totalRows'] = this.totalRows;
      } else {
        data = {
          'startIndex': this.startIndex,
          'lastIndex': this.lastIndex,
          'currentPage': this.currentPage,
          'query': this.query,
          'specialFilterValue': this.specialFilterValue
        };
      }
      this._apiService.post(environment.get_Branch_List, data).subscribe((resp) => {
        if (resp['status']) {
          this.branchList = [...this.branchList, ...resp['response']];
          this.cd.detectChanges();
          this.branchList1 = this.branchList;
          this.currentPage = resp['otherInfo'].currentPage + 1;
          this.requestPage=resp['otherInfo'].currentPage;
        }
      });
    }
  }


  // ------------------------------------------------------------
  //  
  // ------------------------------------------------------------
  filter = (searchData) => {
    if (searchData.length == 0) {
      this.Flag = false
      this.currentPage = 1;
      this.loadMagnifierData();
      this.filterVal = false
    }
    else {
      this.branchList1 = [];
      this.branchList = [];
      this.startIndex = 0;
      this.lastIndex = 1000;
      let data: any = {};
      this.sendPage=this.currentPage;
      this.filterVal = true
      if (typeof (this.query) == 'object') {
        data = JSON.parse(JSON.stringify(this.query));
        data['startIndex'] = this.startIndex;
        data['lastIndex'] = this.lastIndex;
        data['smartSearchOpt'] = [{
          object: 'search',
          joinType: "AND",
          criteria: 'LIKES',
          value: searchData ? searchData : '',
        }];
      } else {
        data = {
          'startIndex': this.startIndex,
          'lastIndex': this.lastIndex,
          'query': this.query,
          'specialFilterValue': this.specialFilterValue,
          'smartSearchOpt': [{
            object: 'search',
            joinType: "AND",
            criteria: 'LIKES',
            value: searchData ? searchData : '',
          }]
        };
      }
      // const data1 = {
      //   startIndex: this.startIndex,
      //   lastIndex: this.lastIndex,
      //   query: this.query,
      //   specialFilterValue: this.specialFilterValue,
      //   smartSearchOpt: [{
      //     object: 'search',
      //     joinType: "AND",
      //     criteria: 'LIKES',
      //     value: searchData ? searchData : '',
      //   }]
      // };

      this._apiService.post(environment.get_Branch_List, data).subscribe((resp) => {
        if (resp['status'] == 1) {
          this.branchList1 = [...this.branchList1, ...resp['response']];
          this.branchList = [...this.branchList, ...this.branchList1];
          this.totalRows = resp['otherInfo'].totalRows
          this.currentPage = resp['otherInfo'].currentPage + 1;
          this.requestPage=resp['otherInfo'].currentPage;
          this.cd.detectChanges();
        }
      });
    }
  }


  // ------------------------------------------------------------
  //  
  // ------------------------------------------------------------
  updateSelectedData = (selectedData) => {
    this.selectedData = selectedData;
  }


  // ------------------------------------------------------------
  //  
  // ------------------------------------------------------------
  emitSelectedData(data) {
    this.filterData.emit(data);
  }


  // ------------------------------------------------------------
  //  
  // ------------------------------------------------------------
  closeModal = () => {
    this.filterData.emit('close');
  }
}
