import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataConnectorService {


  //------------------------------------------------------------
  // PUBLIC VARIABLES AND DECLARATIONS
  //------------------------------------------------------------
  searchData: any = {};

  //------------------------------------------------------------
  // CONSTRUCTOR
  //------------------------------------------------------------
  constructor(private apiService: ApiService) { }


  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  public getAll(startIndex, lastIndex, totalRows): Observable<any> {
    var data = {
      startIndex: startIndex,
      lastIndex: lastIndex,
      totalRows: totalRows
    }
    return this.apiService.post(this.apiService.getEnvironment().get_all_connector, data);
  }


  //------------------------------------------------------------
  // Retrun all Data connectors name for Auto Complete
  //------------------------------------------------------------
  public getDataConnectors() {
    return this.apiService.get(this.apiService.getEnvironment().get_all_connector_name);
  }


  //------------------------------------------------------------
  // Retrun all Data Connector Tables
  //------------------------------------------------------------
  public getDataConnectorTables(dataConnector: any) {
    let dataConnectorPostData = dataConnector;
    return this.apiService.post(this.apiService.getEnvironment().get_data_connector_table, dataConnectorPostData);
  }


  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  public getDataConntorTableCols(dataConnector, scriptType, table) {
    let colsData = { "macroVar": dataConnector, "scriptType": scriptType, "tableName": table }
    return this.apiService.post(this.apiService.getEnvironment().get_data_connector_table_col, colsData);
  }


  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  public searchFilter(startIndex, lastIndex, totalRows, searchData): Observable<any> {
    this.searchData['smartSearchVb'] = searchData;
    this.searchData["startIndex"] = startIndex;
    this.searchData["lastIndex"] = lastIndex;
    this.searchData["totalRows"] = totalRows;
    return this.apiService.post(this.apiService.getEnvironment().get_dataConnector_filter, this.searchData);
  }


}
