import { Component, OnInit } from '@angular/core';
import { EnvService } from '../app/env.service';

@Component({
  selector: 'redirect',
  template: 'redirecting...'
})
export class RedirectComponent implements OnInit {
  private redirectUrl: string = "";
  private linkUrl = window.location.origin +'/#/welcome/selfBI'
  private accountsUrl : string = ""

  constructor(private env: EnvService,
    ) {
      this.redirectUrl = env.redirectUrl
      this.accountsUrl = env.AccountsUrl
    
     }

  ngOnInit() {
   if(history.state.data == false){
      window.location.href = this.accountsUrl
    }
    else{
      window.location.href = this.redirectUrl+'?url='+this.linkUrl
     }
  }
}