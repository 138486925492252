import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { FormArray, FormGroup, Validators, FormBuilder, FormControl, AbstractControl } from '@angular/forms';
import { isArray } from 'util';
import { Router } from '@angular/router';
import { Subject, BehaviorSubject } from 'rxjs';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class GlobalService {


  // ------------------------------------------------------------
  //
  // ------------------------------------------------------------
  pageTitle = '';
  pagePath='';
  last_toolTip;
  pageLevelAccess;
  validNumber = true;
  themeNow = new BehaviorSubject('');
  sidebarDectect= new BehaviorSubject(0);
  // ------------------------------------------------------------
  //
  // ------------------------------------------------------------
  constructor(private toastr: ToastrService,
    private _translate: TranslateService,private router:Router) {

    // INITIALIZE TRANSLATE SERVICE
    this._translate.addLangs(['en', 'ar']);
    this._translate.setDefaultLang('en');
    this.useLang('en');
  }

  getTodayDate() {
    const currentDate = new Date(new Date().getTime());
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    return { year: year, month: month, day: day + 1 };
  }

  // ------------------------------------------------------------
  // USE LANGUAGE
  // ------------------------------------------------------------
  useLang(langCode) {
    this._translate.use(langCode);
  }



  // ------------------------------------------------------------
  //
  // ------------------------------------------------------------
  pattern = {
    number: '^[0-9]*',
    numberWithScaling: '^[0-9.,]*',
    string: '^[a-zA-Z]*'
  }

  // ------------------------------------------------------------
  // Toastr Service
  // -------------------------------------------------------------
  showToastr = {
    success: (msg: any) => {
      this.toastr.success(msg);
    },
    warning: (msg: any) => {
      this.toastr.warning(msg);
    },
    error: (msg: any) => {
      this.toastr.error(msg);
    }
  }



  //------------------------------------------------------------
  // COMMON VALIDATION
  //------------------------------------------------------------
  validation(formControl, fromError, formMsgs) {
    const form = formControl;

    for (const field in fromError) {

      if (isArray(fromError[field])) {
        //VALIDATION FOR DYNAMIC FIELDS
        const tempForm = <FormArray>form.controls[field];;
        let tempLength = tempForm.length;

        //CHECK ALL ROWS
        for (let i = 0; i <= tempLength - 1; i++) {
          const errorMsg = fromError[field][i];
          for (const dynamicField in errorMsg) {
            errorMsg[dynamicField] = '';
            const tmpControl = form.controls[field]['controls'];
            const control = tmpControl[i].controls[dynamicField];
            if (control && control.dirty && !control.valid) {
              const messages = formMsgs.validationMessages[field][dynamicField];
              for (const key in control.errors) {
                errorMsg[dynamicField] = messages[key];
              }
            }
          }
        }

      } else {

        //VALIDATION FOR STATIC FIELDS
        fromError[field] = '';
        const control1 = form.get(field);

        if (control1 && control1.dirty && !control1.valid) {
          const messages1 = formMsgs.validationMessages[field];
          for (const key in control1.errors) {
            fromError[field] = messages1[key] + ' ';
          }
        }
      }
    }
  }


  // ------------------------------------------------------------
  // COMMON VALIDATION
  // ------------------------------------------------------------
  validation1(formControl, fromError, formMsgs) {
    const form = formControl;
    for (const field in fromError) {
      if (isArray(fromError[field])) {
        const tempForm = <FormArray>form.controls[field];
        let tempLength = tempForm.length;
        for (let i = 0; i <= tempLength - 1; i++) {
          for (const checkField1 in fromError[field][i]) {
            if (checkField1 == 'confLevellst') {
              this.validateArray(form.controls['dealStagelst']['controls'][i]['controls']['confLevellst'], i, 'confLevellst', fromError[field][i]['confLevellst'], formMsgs.validationMessages[field].confLevellst);
            } else {
              const errorMsg = fromError[field][i];
              for (const dynamicField in errorMsg) {
                if (dynamicField != 'confLevellst') {
                  errorMsg[dynamicField] = '';
                  const tmpControl = form.controls[field]['controls'];
                  const control = tmpControl[i].controls[dynamicField];
                  if (control && control.dirty && !control.valid) {
                    const messages = formMsgs.validationMessages[field][dynamicField];
                    for (const key in control.errors) {
                      errorMsg[dynamicField] = messages[key];
                    }
                  }
                }

              }
            }
          }
        }
      } else {
        //VALIDATION FOR STATIC FIELDS
        fromError[field] = '';
        const control1 = form.get(field);
        if (control1 && control1.dirty && !control1.valid) {
          const messages1 = formMsgs.validationMessages[field];
          for (const key in control1.errors) {
            fromError[field] = messages1[key] + ' ';
          }
        }
      }
    }
  }

  validateArray(tempForm, index, field, fromError, formMsgs) {
    const tempForm1 = <FormArray>tempForm;
    let tempLength = tempForm1.length;
    for (let i = 0; i <= tempLength - 1; i++) {
      const errorMsg = fromError[i];
      for (const dynamicField in errorMsg) {
        errorMsg[dynamicField] = '';
        const tmpControl = tempForm1;
        const control = <FormGroup>tmpControl.controls[i]['controls'][dynamicField];
        if (control && control.dirty && !control.valid) {
          const messages = formMsgs[dynamicField];
          for (const key in control.errors) {
            errorMsg[dynamicField] = messages[key];
          }
        }
      }

    }
  }

  // ------------------------------------------------------------
  //
  // ------------------------------------------------------------
  getLabelValue = (array, checkField1, checkField2, outputValue) => {
    let index;
    index = array.findIndex((element) => element[checkField1] == checkField2);
    return array[index][outputValue];
  }


  // ------------------------------------------------------------
  // CHECK CONTROLS
  // ------------------------------------------------------------
  markAllDirty(control: AbstractControl) {
    if (control.hasOwnProperty('controls')) {
      control.markAsDirty({ onlySelf: true }) // mark group
      let ctrl = <any>control;
      for (let inner in ctrl.controls) {
        this.markAllDirty(ctrl.controls[inner] as AbstractControl);
      }
    }
    else {
      (<FormControl>(control)).updateValueAndValidity();
      (<FormControl>(control)).markAsDirty({ onlySelf: true });
    }
  }


  // ------------------------------------------------------------
  //
  // ------------------------------------------------------------
  convertStringToArray(str) {
    if (str != null) {
      return str.split();
    } else {
      return '';
    }
  }


  // ------------------------------------------------------------
  //  Pagination
  // ------------------------------------------------------------
  activePage: number = 1;
  pageSize: number = 15;

  showLoader = new Subject<boolean>();

  // ------------------------------------------------------------
  // SHOW LOADER
  // ------------------------------------------------------------
  show() {
    this.showLoader.next(true);
  }


  // ------------------------------------------------------------
  // HIDE LOADER
  // ------------------------------------------------------------
  hide() {
    this.showLoader.next(false);
  }


  isNumberKey(evt) {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  isCharacterOnly(evt) {
    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }


  isNumberKeyWithDot(evt) {
    if (evt.keyCode === 46 && evt.target.value.split('.').length === 2) {
      return false;
    }

    const val = evt.target.value.split('.');
    if (val && val[1] && val[1].length) {
      if (val[1].length === 4) {
        return false;
      }
    }

    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode == 110 || charCode == 190 || charCode == 46) {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }



  isNumberKeyWithDott(evt, digit, digit1) {
    // const decimalPatternMatch = '^[0-9]{1,4}(\.[0-9][0-9])?$';

    const charCode = (evt.which) ? evt.which : evt.keyCode;
    if (evt.keyCode === 46 && evt.target.value.split('.').length === 2) {
      return false;
    }

    const val = evt.target.value.split('.');

    // const regexpTest =  /^[0-9]{10}\.[0-9]{0, 2}?$/;
    const regexpTest = (/^\d{0,7}(,\d{0,3})?(\.\d{1,4})?$/);
    this.validNumber = regexpTest.test(evt.target.value);

    if (val && val[0] && val[0].length) {
      if (val[0].length > digit) {
        return false;
      }
      if (val[0].length === digit) {
        if (charCode == 110 || charCode == 190 || charCode == 46) {
          return true;
        } else {
          return false;
        }
      }
    }

    if (val && val[1] && val[1].length) {
      if (val[1].length === digit1) {
        return false;
      }
    }

    if (charCode == 110 || charCode == 190 || charCode == 46) {
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    return true;
  }

  removeComma(event) {
    const removeComma = event.target.value.replace(/,/g, '');
    event.target.value = removeComma;
  }
  addComma(event) {
    const num = event.target.value;
    const numParts = num.toString().split('.');
    numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // return numParts.join('.');
    event.target.value = numParts.join('.');
  }

  themeChanges(data:any){
    if(data != ''){
    this.themeNow.next(data);
    let dataLower=data.toLowerCase();
    if(this.router.url == '/welcome/commonDashboard'){
    if(dataLower == 'ds_default')
    document.body.classList.add("ds_default");
    document.getElementById("mainPanel").style.background = "unset";
    if(dataLower == 'ds_gray')
    document.body.classList.add("ds_gray");
    document.getElementById("mainPanel").style.background = "unset";
    if(dataLower == 'ds_purple')
    document.body.classList.add("ds_purple");
    document.getElementById("mainPanel").style.background = "unset";
    }
    else{
    document.body.classList.remove("ds_default");
    document.body.classList.remove("ds_gray");
    document.body.classList.remove("ds_purple");
    }
    }
    else{
    document.body.classList.remove("ds_default");
    document.body.classList.remove("ds_gray");
    document.body.classList.remove("ds_purple");
    }
    }


  getThemeChanges(){
    return this.themeNow;
  }

  sideBarChangesDectect(data){
    this.sidebarDectect.next(data)
  }

  getsideBarChangesDectect(){
   return this.sidebarDectect;
  }
}
