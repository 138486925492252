import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

//-------------------------------------------------------------------
// SERVICES/PROVIDERS
//-------------------------------------------------------------------
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './sharedModules/shared.module';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Globals } from "./globals";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { EnvServiceProvider } from './env.service.provider';
import { EncrDecrService } from '../app/_services/encr-decr.service';

//-------------------------------------------------------------------
// COMPONENTS
//-------------------------------------------------------------------
import { AppComponent } from './app.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { httpInterceptorProviders } from './_services';
import { Interceptor } from './_services/interceptors';
import { ChartRenderComponent  } from './chart/chart-render.component';
import {RedirectComponent}from './../app/redirect.component'


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
  suppressScrollY: false
};


@NgModule({
  declarations: [
    AppComponent,
    ChartRenderComponent,
    RedirectComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    PerfectScrollbarModule,
    BrowserAnimationsModule,
    NgIdleKeepaliveModule.forRoot(),
    AccordionModule.forRoot(),

  ],
  providers: [
    EncrDecrService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,

    },
    httpInterceptorProviders,
    EnvServiceProvider,
    Globals],
  bootstrap: [AppComponent]
})
export class AppModule { }
