import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/_services/api.service';
import { BehaviorSubject } from 'rxjs';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { isUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class DummyService {

  dummyData: any = [];
  dummyData_cpy: any = [];
  filter_data: any = [];
  limited_data = new BehaviorSubject([]);
  overall_check = new BehaviorSubject(false);
  start: any = 0;
  end: any = 10;

  constructor(private api: ApiService) { }


  //----------------------------------------------------------------
  //  Getting data from
  //----------------------------------------------------------------
  getDummyData(respon: any, checking?) {

    // console.log(this.dummyData_cpy.length)
    // let resp =  this.api.dummyData();
    //  (checking=='individualCheck') ?  this.onlyCheck(respon) : this.formatData(respon);
    this.formatData(respon)

  }


  //----------------------------------------------------------------
  //  
  //----------------------------------------------------------------
  formatData(resp: any, checking?) {
    if (resp.length > 0) {
      this.dummyData = resp;
      this.dummyData_cpy = resp;
      this.filter_data = resp;
      this.dummyData_cpy.forEach((element, index, array) => {
        element['id'] = index + 1;
        element['pagination_id'] = index + 1;
        this.dummyData[index]['isChecked'] = element['isChecked'] || false;
        array[index]['isChecked'] = array[index]['isChecked'] || false;
      });
      let data = {
        total: resp.length,
        first: this.dummyData[0]['pagination_id'],
        list_user: this.dummyData_cpy.slice(this.start, this.end)
      }
      this.limited_data.next([data])
    }
  }

  individualCheck(resp: any) {
    this.dummyData_cpy.forEach(element => {
      if (resp.id == element.id) {
        element['isChecked'] = resp['isChecked'];
      }
    });
  }

  //----------------------------------------------------------------
  //  
  //----------------------------------------------------------------
  next() {
    this.start = this.start + 10;
    this.end = this.end + 10;
    let data = {
      total: this.dummyData_cpy.length,
      first: this.dummyData_cpy[0]['pagination_id'],
      list_user: this.dummyData_cpy.slice(this.start, this.end)
    };
    this.limited_data.next(this.dummyData_cpy.slice(this.start, this.end));
  }

  //----------------------------------------------------------------
  //  
  //----------------------------------------------------------------
  previous() {
    this.start = this.start - 10;
    this.end = this.end - 10;
    this.limited_data.next(this.dummyData_cpy.slice(this.start, this.end))
  }

  //----------------------------------------------------------------
  //  
  //----------------------------------------------------------------
  overallSelect(checked: any) {
    this.dummyData_cpy.forEach((element, index) => {
      if (checked) {
        element['isChecked'] = true;
      }
      else {
        element['isChecked'] = false;
      }
    })
  }

  //----------------------------------------------------------------
  //  
  //----------------------------------------------------------------
  overAllChecked() {
    let count = 0;
    this.dummyData_cpy.forEach(element => {
      if (element.isChecked) {
        count++;
      }
    });
    if (count && count == this.dummyData_cpy.length) {
      return true;
    } else {
      return false;
    }
  }

  //----------------------------------------------------------------
  //  
  //----------------------------------------------------------------
  filterData(searched_list: any) {
    let fields = this.filter_data[0],
      items = this.filter_data;
    if (items && items.length) {
      let filldata = items.filter(item => {
        for (let prop in fields) {
          for (let i = 0; i < searched_list.length; i++) {
            if (searched_list[i]['types'] == 'contains' || searched_list[i]['types'] == null) {
              if (searched_list[i][prop] &&
                item[prop].toString().toLowerCase().indexOf(searched_list[i][prop].toString().toLowerCase()) === -1) {
                return false;
              }
            }
            else if (searched_list[i]["types"] == "endWith") {
              if (
                searched_list[i][prop] &&
                item[prop]
                  .toLowerCase()
                  .endsWith(searched_list[i][prop].toLowerCase()) - 1
              ) {
                return false;
              }
            }
            else if (searched_list[i]["types"] == "startWith") {
              if (
                searched_list[i][prop] &&
                item[prop]
                  .toLowerCase()
                  .startsWith(searched_list[i][prop].toLowerCase()) - 1
              ) {
                return false;
              }
            }
            else if (searched_list[i]["types"] == "equals") {
              if (
                searched_list[i][prop] &&
                item[prop].toLowerCase() !==
                searched_list[i][prop].toLowerCase()
              ) {
                return false;
              }
            }
          }
        }
        return true;
      })
      this.dummyData_cpy = filldata;
      this.resetPagination();
      filldata.forEach((element, index, array) => {
        array[index]['pagination_id'] = index + 1;
      });
      let final_data = filldata.slice(0, 10);
      let last = isUndefined(filldata[filldata.length - 1]) ? 10 : filldata[filldata.length - 1]['pagination_id'];
      let first = isUndefined(filldata[0]) ? 1 : filldata[0]['pagination_id'];
      let data = {
        total: last,
        first: first,
        list_user: final_data
      }

      this.limited_data.next([data]);
      this.overall_check.next(true);
    }
  }
  resetPagination() {
    this.start = 0;
    this.end = 10;
  }

  //----------------------------------------------------------------
  //  
  //----------------------------------------------------------------
  resetData() {
    this.dummyData_cpy = this.dummyData.slice(0);
    let data = {
      total: this.dummyData_cpy.length,
      first: this.dummyData_cpy[0]['pagination_id'],
      list_user: this.dummyData_cpy.slice(0, 10)
    }
    this.start = 0;
    this.end = 10;
    this.limited_data.next([data]);
    this.overall_check.next(true);
  }

  //----------------------------------------------------------------
  //  
  //----------------------------------------------------------------
  getResult() {
    let selected_data = [];
    selected_data = this.dummyData.filter(resp => {
      if (resp['isChecked']) {
        return true;
      }
      else {
        return false;
      }
    })
    return selected_data;
  }

  arrayListCheckBinging(overAllData: any, selectedArray: any) {
    overAllData.forEach(element => { element['isChecked'] = false; });
    selectedArray.forEach((selected, selected_index, selected_array) => {
      overAllData.forEach((overall, overall_index, overall_array) => {
        if (overall['visionId'] == selected['visionId']) {
          overall_array[overall_index]['isChecked'] = true;
          overall_array[overall_index]['writeFlag'] =
            selected['writeFlag'] == 'Y' ? true : false;
        }
      });
    });
    return overAllData;
  }


}



