import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnChanges, Injector, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { GlobalService, ApiService, JwtService } from '../../_services';
import { SearchComponent } from '../search/search.component';
import { LoaderService } from '../../_services/loader.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { isUndefined, isArray, isObject,isNull } from 'util';
import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'; 
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-smart-table',
  templateUrl: './smart-table.component.html',
  styleUrls: ['./smart-table.component.css'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('.5s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ],
})
export class SmartTableComponent implements OnInit {

  dataColumnList = [];
  screentypeToolTip: any= 'maximize';
  apiLink: any = '';
  filterApi: any = '';
  pagination: any = {
    totalItems: 0,
    startIndex: 0,
    lastIndex: 30
  };
  individualFilterList = {};
  title: any = '';
  limitedPagination: any = {
    totalItems: 0,
    startIndex: 0,
    lastIndex: 15
  };
  perPage: any = 15;
  compact_css: boolean = false;
  limitedList: any = [];
  overAllListCount: any = 0;
  previousDisp: boolean = false;
  nextDisp: boolean = false;
  screentype = 'fullscreen';
  last_toolTip: any;
  addLable: any = '';
  routingLable: any = '';
  userBasedCount: any = 30;
  viewList: any = [];
  filterList = [];
  monthList = [
    {
      name: 'January',
      id: '01'
    },
    {
      name: 'February',
      id: '02'
    },
    {
      name: 'March',
      id: '03'
    },
    {
      name: 'April',
      id: '04'
    },
    {
      name: 'May',
      id: '05'
    },
    {
      name: 'June',
      id: '06'
    },
    {
      name: 'July',
      id: '07'
    },
    {
      name: 'August',
      id: '08'
    },
    {
      name: 'September',
      id: '09'
    },
    {
      name: 'October',
      id: '10'
    },
    {
      name: 'November',
      id: '11'
    }, {
      name: 'December',
      id: '12'
    }
  ];
  data = {};
  userGroupFilterData;
  userProfileFilterData;
  homeScreenData='';
  homeScreenArry=[];
  filterTypeTwoData;
  filterTypeTwoData1 = {};
  showFilterTypeTwo = false;
  // ------------------------------------------------------------
  @Input() tableType?;
  @Input() tableDatails;
  @Input() tableData;
  @Input() totalRows;
  @Input() filter;
  @Input() resetModel: any;
  @Input() set tableDatas(value: any) {
    this.apiLink = value['api'];
    this.filterApi = value['filterApi'];
    this.userBasedCount = (value['count'] < 30) ? 30 : value['count'];
    this.pagination['lastIndex'] = (value['count'] < 30) ? 30 : value['count'];
    this.homeScreenArry = value['homeDashboard'];
    if (value['data']) {
      this.showFilterTypeTwo = true;
      this.filterTypeTwoData = value['data'];
      this.filterTypeTwoData.forEach(element => {
        this.filterTypeTwoData1[element.alphaSubTab] = element.children;
      });
    }
  }
  @Output() eventType: EventEmitter<any> = new EventEmitter();
  loaderService = this.injector.get(LoaderService);
  showData = false;

  filterUserData() {
    this.data['userGroup'] = this.userGroupFilterData;
    this.data['userProfile'] = this.userProfileFilterData;
    this.initializePagination();
  }

  // ------------------------------------------------------------
  constructor(
    private _sanitizer: DomSanitizer,
    private globalService: GlobalService,
    private modalService: NgbModal,
    private injector: Injector,
    private apiService: ApiService,
    public translate: TranslateService,
    public jwtService: JwtService
  ) { 
    this.translate.setDefaultLang(this.jwtService.get('setLanguage'));
      this.translate.use(this.jwtService.get('setLanguage'));
      // GET LANGUAGE
      this.translate.currentLoader.getTranslation(this.translate.currentLang)
        .subscribe(data => {
        });
  }


  pageLevelAccess;
  // ------------------------------------------------------------
  ngOnInit() {
    ((this.tableType != undefined) && (this.apiLink == '')) ? this.viewList = this.tableData : this.gettotalRows();
    if (this.showFilterTypeTwo) {
      this.userGroupFilterData = this.filterTypeTwoData[0]['alphaSubTab'];
      this.userProfileFilterData = this.filterTypeTwoData1['ADMIN'][0]['alphaSubTab'];
      this.filterUserData();
    };
    this.pageLevelAccess = this.globalService.pageLevelAccess;
  }

  homeDashapiCall(){
    let data = {
      "userGroup": this.userGroupFilterData,
      "userProfile": this.userProfileFilterData,
      "homeDashboard": this.homeScreenData
    }
    this.apiService.post(this.apiService.getEnvironment()['updateHomeDashboard'],data).subscribe(resp=>{
      if(resp['status']){
          this.globalService.showToastr.success(resp['message']);
      }else{
          this.globalService.showToastr.error(resp['message']);
      }
    })
  }

  // ------------------------------------------------------------
  gettotalRows() {
    !isUndefined(this.apiLink) ? this.apiService.post(this.apiService.getEnvironment()[this.apiLink], { startIndex: 0, lastIndex: 0, totalRows: 0 }).subscribe(resp => {
      if (resp['status']) {
        this.overAllListCount = ('otherInfo' in resp) ? ('totalRows' in resp['otherInfo']) ? resp['otherInfo']['totalRows'] : '' : '';
        this.limitedPagination['totalItems'] = this.overAllListCount;
        this.pagination['totalItems'] = this.overAllListCount;
        this.initializePagination();
      } else {
        this.globalService.showToastr.error(resp['message']);
      }
    }) : this.viewList = this.tableData;
  }

  // ------------------------------------------------------------
  ngOnChanges(changes: SimpleChanges) {
    ((this.tableType != undefined) && (this.apiLink == '')) ? this.viewList = this.tableData :
      (this.resetModel['key'] == 'reload') ? this.recallApi() : '';
  }

  // ------------------------------------------------------------
  ngAfterViewInit() {
    this.last_toolTip = document.getElementById('last_toolTip');
    this.last_toolTip.setAttribute('tooltip', 'Maximize');
    if (!isUndefined(this.filter)) {
      const tempArray = [];
      this.filter.forEach(element => {
        if (!element.hasOwnProperty('filterReferField')) {
          element.filterReferField = element.name;
        }
        tempArray.push((element));
      });
      this.dataColumnList = tempArray;
    } else {
      const tempArray = [];
      this.tableDatails.headers.forEach(element => {
        if (!element.hasOwnProperty('filterReferField')) {
          element.filterReferField = element.name;
        }
        tempArray.push((element));
      });
      this.dataColumnList = tempArray;
    }
  }

  // ------------------------------------------------------------
  closeModal() {
    this.modalService.dismissAll();
  }

  // ------------------------------------------------------------
  resetFilter() {
    const data = {
      startIndex: 0,
      lastIndex: 1000,
      smartSearchOpt: []
    };
    this.emitActionType('pagination', data);
  }

  // ------------------------------------------------------------
  secureInputHtml(html) {
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }

  // ------------------------------------------------------------
  bulkSelect(e) {
    this.tableData.forEach(element => {
      element.checked = e.target.checked === true ? true : false;
    });
  }

  // ------------------------------------------------------------
  toggleCheckBoxSelect = (e, index) => {
    if (e.target.checked) {
      this.tableData[index].checked = true;
    } else {
      this.tableData[index].checked = false;
    }
  }

  // ------------------------------------------------------------
  emitActionType(type, data?) {
    const obj = {
      type,
      data
    };
    this.eventType.next(obj);
  }

  // ------------------------------------------------------------
  showApproveOrRejectButton = () => {
    if (this.tableData) {
      return this.tableData.some((element) => element.checked === true);
    } else {
      return false;
    }
  }

  // ------------------------------------------------------------
  previewScreen = () => {
    let screensize = document.getElementById('maximize');
    screensize.classList.contains('fullscreen') ? this.minimize(screensize) :
      this.maximize(screensize);
  }

  // screentype = 'fullscreen';

  // ------------------------------------------------------------
  minimize = (minimize: any) => {
    this.screentypeToolTip = 'maximize';
    minimize.classList.remove('fullscreen');
    this.screentype = 'fullscreen';
    //this.last_toolTip.setAttribute('tooltip', 'maximize');
    let tooltip = document.getElementsByClassName('tool_tip');
    for (var i = 0; i < tooltip.length; i++) {
      tooltip[i].removeAttribute('flow');
    }
  }

  // ------------------------------------------------------------
  maximize = (maximize: any) => {
    this.screentypeToolTip = 'minimize';
    maximize.classList.add('fullscreen');
    this.screentype = 'fullscreen_exit';
    //this.last_toolTip.setAttribute('tooltip', 'Minimize');
    let tooltip = document.getElementsByClassName('tool_tip');
    for (var i = 0; i < tooltip.length; i++) {
      tooltip[i].setAttribute('flow', 'left');
    }
  }

  getClass(data) {
    if (data == 'Active') {
      return 'text-success';
    } else if (data == 'Approved') {
      return 'text-warning';
    } else if (data == 'Inactive') {
      return 'text-danger';
    }
  }

  // ------------------------------------------------------------
  initializePagination = () => {
    if (isObject(this.data)) {
      this.pagination = { ...this.pagination, ...this.data };
    }
    !isUndefined(this.apiLink) ? this.apiService.post(this.apiService.getEnvironment()[this.apiLink], this.pagination).subscribe(resp => {
      if (resp['status']) {
        this.showData = true;
        this.overAllListCount = ('otherInfo' in resp) ? ('totalRows' in resp['otherInfo']) ? resp['otherInfo']['totalRows'] : '' : '';
        if(this.apiLink == 'profile_get_all_values'){
          this.homeScreenData = ('otherInfo' in resp && !isNull(resp['otherInfo'])) ? resp['otherInfo']['homeDashboard'] :'';
        }
        if (this.apiLink == 'get_segmentation_list') {
          resp['response'] && isArray(resp['response']) && resp['response'].length >= 1 && resp['response'].forEach(element => {
            element['monthDesc'] = this.monthList[element.month - 1].name;
          });
        }
        if (this.apiLink == 'get_budget_configuration_data') {
          resp['response'] && isArray(resp['response']) && resp['response'].length >= 1 && resp['response'].forEach(element => {
            element['balTypeFlagDesc'] = (element.balTypeFlag == 3) ? 'Income' : 'Turn Over';
          });
        }
        this.limitedList = resp['response'] != null ? resp['response'] : [];
        this.viewList = this.limitedList.slice(0, 15);
      }
      else {
        this.globalService.showToastr.error(resp['message']);
      }
      this.paginationDisable();
    }) : this.viewList = this.tableData;
  }

  // ------------------------------------------------------------
  resetPagination() {
    this.perPage = 15;
    this.limitedPagination['startIndex'] = 0;
    this.limitedPagination['lastIndex'] = 15;
  }

  // ------------------------------------------------------------
  previous = () => {
    this.limitedPagination['startIndex'] = this.limitedPagination['startIndex'] - (this.perPage);
    this.limitedPagination['lastIndex'] = this.limitedPagination['lastIndex'] - (this.perPage);
    this.viewList = this.limitedList.slice(this.limitedPagination['startIndex'], this.limitedPagination['lastIndex']);
    this.paginationDisable();
  }

  // ------------------------------------------------------------
  next = () => {
    this.limitedPagination['startIndex'] = this.limitedPagination['startIndex'] + (this.perPage);
    this.limitedPagination['lastIndex'] = this.limitedPagination['lastIndex'] + (this.perPage);
    this.viewList = this.limitedList.slice(this.limitedPagination['startIndex'], this.limitedPagination['lastIndex']);
    (this.viewList.length < this.perPage && this.limitedList.length < this.overAllListCount) ? this.nextDataList() : '';
    this.paginationDisable();
  }

  // ------------------------------------------------------------
  paginationDisable = () => {
    this.previousDisp = (this.limitedPagination['startIndex'] == 0) ? true : false;
    this.nextDisp = (this.viewList.length < this.perPage && this.limitedList.length == this.overAllListCount) ? true :
      (this.limitedPagination['lastIndex'] >= this.overAllListCount) ? true : false;
  }

  // ------------------------------------------------------------
  nextDataList = () => {
    let details = {
      totalRows: this.overAllListCount,
      startIndex: this.limitedList.length,
      lastIndex: this.limitedList.length + 30
    };
    if (isObject(this.data)) {
      details = { ...details, ...this.data };
    }
    if (this.filterList.length) {
      details['smartSearchOpt'] = this.filterList;
      this.apiService.post(this.apiService.getEnvironment()[this.filterApi], details).subscribe(resp => {
        if (resp['status']) {
          if (this.apiLink == 'get_segmentation_list') {
            resp['response'] && isArray(resp['response']) && resp['response'].length >= 1 && resp['response'].forEach(element => {
              element['monthDesc'] = this.monthList[element.month - 1].name;
            });
          }
          if (this.apiLink == 'get_budget_configuration_data') {
            resp['response'] && isArray(resp['response']) && resp['response'].length >= 1 && resp['response'].forEach(element => {
              element['balTypeFlagDesc'] = (element.balTypeFlag == 1) ? 'Income' : 'Turn Over';
            });
          }
          let list = resp['response'] != null ? resp['response'] : [];
          this.limitedList = [...this.limitedList, ...list];
          this.viewList = this.limitedList.slice(this.limitedPagination['startIndex'], this.limitedPagination['lastIndex']);
          this.paginationDisable();
        }
      });
    } else {
      this.apiService.post(this.apiService.getEnvironment()[this.apiLink], details).subscribe(resp => {
        if (resp['status']) {
          if (this.apiLink == 'get_segmentation_list') {
            resp['response'] && isArray(resp['response']) && resp['response'].length >= 1 && resp['response'].forEach(element => {
              element['monthDesc'] = this.monthList[element.month - 1].name;
            });
          }
          if (this.apiLink == 'get_budget_configuration_data') {
            resp['response'] && isArray(resp['response']) && resp['response'].length >= 1 && resp['response'].forEach(element => {
              element['balTypeFlagDesc'] = (element.balTypeFlag == 1) ? 'Income' : 'Turn Over';
            });
          }
          let list = resp['response'] != null ? resp['response'] : [];
          this.limitedList = [...this.limitedList, ...list];
          this.viewList = this.limitedList.slice(this.limitedPagination['startIndex'], this.limitedPagination['lastIndex']);
          this.paginationDisable();
        }
        else {
          this.globalService.showToastr.error(resp['message']);
        }
      });
    }
  }

  // ------------------------------------------------------------
  getPaginationDet = () => {
    let first = this.limitedPagination['startIndex'] + 1;
    let last = (this.limitedPagination['lastIndex'] > this.overAllListCount) ? this.overAllListCount :
      this.limitedPagination['lastIndex'];
    let aa = `${first} - ${last}  of
      ${this.overAllListCount}`;
    return aa;
  }

  // ------------------------------------------------------------
  listCountDisp = () => {
    this.perPage = parseInt(this.perPage);
    this.limitedPagination['startIndex'] = 0;
    this.limitedPagination['lastIndex'] = this.perPage;
    this.viewList = this.limitedList.slice(this.limitedPagination['startIndex'], this.limitedPagination['lastIndex']);
    this.paginationDisable();
  }

  // ------------------------------------------------------------
  openSearchModal = (type?) => {
    const modelRef = this.modalService.open(SearchComponent, {
      size: 'lg',
      backdrop: 'static'
    });
    modelRef.componentInstance.dataToDisplay = this.dataColumnList;
    modelRef.componentInstance.searchData = this.filterList;
    modelRef.componentInstance.filterData.subscribe((e) => {
      if (e.flag == 'apply') {
        this.filterList = e.data;
        this.applyFilterData(this.filterList);
        this.individualFilterDisp();
      } else if (e.flag == 'reset') {
        this.filterList = e.data;
        this.resetFilter();
        this.individualFilterDisp();
      }
      modelRef.close();
    });
  }

  individualFilterDisp = () => {
    this.dataColumnList.map(ele => { ele['filter'] = false; });
    this.dataColumnList.forEach(ele => {
      this.individualFilterList[ele.id] = [];
    });
    this.filterList.map(ele => {
      let pos = this.dataColumnList.map(ele => { return ele.id; }).indexOf(ele.object);
      if (pos != -1) {
        this.dataColumnList[pos]['filter'] = true;
        this.individualFilterList[ele.object] = [...this.individualFilterList[ele.object], ...[ele]];
      }
    });
  }

  individualFilter = (key) => {
    const modelRef = this.modalService.open(SearchComponent, {
      size: 'lg',
      backdrop: 'static'
    });
    modelRef.componentInstance.dataToDisplay = [key];
    modelRef.componentInstance.type = 'single';
    modelRef.componentInstance.searchData = this.individualFilterList[key.id];
    modelRef.componentInstance.filterData.subscribe((e) => {
      if (e.flag == 'apply') {
        this.filterList = this.filterList.filter((resp, ind, arr) => {
          if (resp.object != key.id) {
            return true;
          }
        });
        this.individualFilterList[key.id] = e.data;
        this.filterList = [...this.filterList, ...e.data];
        this.applyFilterData(this.filterList);
        this.individualFilterDisp();
      } else if (e.flag == 'reset') {
        this.filterList = this.filterList.filter((resp, ind, arr) => {
          if (resp.object != key.id) {
            return true;
          }
        });
        // this.filterList = e.data;
        this.individualFilterList[key.id] = [];
        this.individualFilterDisp();
        if (this.filterList.length) {
          this.applyFilterData(this.filterList);
        } else {
          this.resetFilter();
        }
      }
      modelRef.close();
    });
  }

  applyFilterData = (filterList) => {
    let details = Object.assign({}, this.pagination);
    if (this.data) {
      details = { ...details, ...this.data };
    }
    this.perPage = 15;
    details['smartSearchOpt'] = filterList;
    this.limitedList = [];
    this.viewList = [];
    this.overAllListCount = 0;
    this.limitedPagination['startIndex'] = 0;
    this.limitedPagination['lastIndex'] = 15;
    this.apiService.post(this.apiService.getEnvironment()[this.filterApi], details).subscribe(resp => {
      if (resp['status']) {
        this.overAllListCount = ('otherInfo' in resp) ? ('totalRows' in resp['otherInfo']) ? resp['otherInfo']['totalRows'] : '' : '';
        this.limitedPagination['totalItems'] = this.overAllListCount;
        this.pagination['totalItems'] = this.overAllListCount;
        if (this.apiLink == 'get_segmentation_list') {
          resp['response'] && isArray(resp['response']) && resp['response'].length >= 1 && resp['response'].forEach(element => {
            element['monthDesc'] = this.monthList[element.month - 1].name;
          });
        }
        if (this.apiLink == 'get_budget_configuration_data') {
          resp['response'] && isArray(resp['response']) && resp['response'].length >= 1 && resp['response'].forEach(element => {
            element['balTypeFlagDesc'] = (element.balTypeFlag == 1) ? 'Income' : 'Turn Over';
          });
        }
        this.limitedList = resp['response'] != null ? resp['response'] : [];
        this.viewList = this.limitedList.slice(0, 15);
        this.paginationDisable();
      }
    });
    this.paginationDisable();
  }

  recallApi = () => {
    if (this.filterList.length) {
      this.applyFilterData(this.filterList);
    } else {
      this.initializePagination();
    }
    this.resetPagination();
  }

  iconVisible = (value: any) => {
    let return_value = true;
    return_value = (value.hasOwnProperty('minCallReportFileName') && value.minCallReportFileName != null) ? true : false;
    return return_value;
  }

  iconVisible1 = (actionData, value: any) => {
    let return_value = true;
    if (actionData.validations) {
      let tempReturnValue;
      actionData.conditon.value.forEach(element => {
        tempReturnValue = !actionData.conditon.value.includes(value[actionData.conditon.key]);
      });
      return_value = tempReturnValue;
    }
    return return_value;
  }

  checkAccess(data) {
    if (data.actionType == 'edit') {
      if (this.globalService.pageLevelAccess.profileModify == 'Y' || this.globalService.pageLevelAccess.profileView == 'Y') {
        return true;
      } else {
        return false;
      }
    }

    if (data.actionType == 'copy') {
      if (this.globalService.pageLevelAccess.profileAdd == 'Y') {
        return true;
      } else {
        return false;
      }
    }

    if (data.actionType == 'view' || data.actionType == 'childrenView' || data.actionType == 'acountOfficerView') {
      if (this.globalService.pageLevelAccess.profileView == 'Y') {
        return true;
      } else {
        return false;
      }
    }

    if (data.actionType == 'delete') {
      if (this.globalService.pageLevelAccess.profileDelete == 'Y') {
        return true;
      } else {
        return false;
      }
    }

    if (data.actionType == 'download') {
      if (this.globalService.pageLevelAccess.profileDownload == 'Y') {
        return true;
      } else {
        return false;
      }
    }

    if (data.actionType == 'upload') {
      if (this.globalService.pageLevelAccess.profileUpload == 'Y') {
        return true;
      } else {
        return false;
      }
    }

    if (data.actionType == 'approve' || data.actionType == 'reject') {
      if (this.globalService.pageLevelAccess.profileVerification == 'Y') {
        return true;
      } else {
        return false;
      }
    }

    if (data.actionType == 'update' || data.actionType == 'dealstage' || data.actionType == 'dealcalls' || data.actionType == 'confidentlevel' || data.actionType == 'statusupdate') {
      if (this.globalService.pageLevelAccess.profileModify == 'Y') {
        return true;
      } else {
        return false;
      }
    }

    return true;
  }
}
