import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl, FormControl } from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {


  @Input() dataToDisplay;
  @Input() type = 'multiple'
  @Input() searchData;
  @Output() filterData: EventEmitter<any> = new EventEmitter<any>();
  @Output() closemodal: EventEmitter<any> = new EventEmitter<any>();
  searchTypeList = [
    { id: 'LIKE', name: 'Contains...' },
    { id: 'STARTSWITH', name: 'Begins With...' },
    { id: 'ENDSWITH', name: 'Ends With...' },
    { id: 'EQUALS', name: 'Equals...' },
  ]

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  constructor(private _fb: FormBuilder) { }


  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  ngOnInit() {
    this.initSearchForm();

    if (this.searchData && this.searchData.length >= 1) {
      this.searchData.forEach((element, ind, arr) => {
        this.formArr.push(this.applyFilterData(element));
      });
    } else {
      if (this.type == 'single') {
        this.formArr.push(this.singletItemRows(this.dataToDisplay[0]['id']));
      } else {
        this.formArr.push(this.initItemRows());
      }
    }
  }


  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  applyFilterData(data) {
    return this._fb.group({
      object: [data.object, Validators.required],
      criteria: [data.criteria, Validators.required],
      value: [data.value, Validators.required],
    });
  }


  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  get formArr() {
    return this.dSSearchForm.get('smartSearchVb') as FormArray;
  }


  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  dSSearchForm;
  initSearchForm() {
    this.dSSearchForm = this._fb.group({
      smartSearchVb: this._fb.array([])
    });
  }


  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  addNewRow() {
    if (!this.formArr.valid) {
      this.markAllDirty(this.formArr);
      return false;
    }
    if (this.type == 'single') {
      this.formArr.push(this.singletItemRows(this.dataToDisplay[0]['id']));
    } else {
      this.formArr.push(this.initItemRows());
    }
  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  initItemRows() {
    return this._fb.group({
      object: ['', Validators.required],
      criteria: ['', Validators.required],
      value: ['', Validators.required]
    });
  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  singletItemRows(data) {
    return this._fb.group({
      object: [data, Validators.required],
      criteria: ['', Validators.required],
      value: ['', Validators.required]
    });
  }




  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  deleteRow(index: any) {
    this.formArr.removeAt(index);
  }


  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  resetModal() {
    this.formArr.reset();
    this.formArr.controls = [];
    this.dSSearchForm.value.smartSearchVb = [];
    this.formArr.push(this.initItemRows());
    let data = {
      flag: 'reset',
      data: []
    }
    this.filterData.emit(data);
  }


  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  searchTable() {
    if (!this.formArr.valid) {
      this.markAllDirty(this.formArr);
      return false;
    }
    let data = {
      flag: 'apply',
      data: this.joinType(this.dSSearchForm.get('smartSearchVb').value)
    }
    this.filterData.emit(data);
  }


  //------------------------------------------------------------
  //
  //------------------------------------------------------------
  closeModal() {
    let data = {
      flag: 'close',
      data: []
    }
    this.filterData.emit(data);
  }

  joinType(value) {
    return value;
  }


  //------------------------------------------------------------
  //
  //------------------------------------------------------------
  markAllDirty(control: AbstractControl) {
    if (control.hasOwnProperty('controls')) {
      control.markAsDirty({ onlySelf: true }) // mark group
      let ctrl = <any>control;
      for (let inner in ctrl.controls) {
        this.markAllDirty(ctrl.controls[inner] as AbstractControl);
      }
    }
    else {
      (<FormControl>(control)).updateValueAndValidity();
      (<FormControl>(control)).markAsDirty({ onlySelf: true });
    }
  }
}
