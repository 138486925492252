import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// CUSTOM IMPORTS
import { ResolveService } from './_services/resolve.service';
import { AuuthGaurdGuard } from './_services/gaurd/auuth-gaurd.guard';
import { MainModule } from './main-module/main.module';
import { ChartRenderComponent } from './chart/chart-render.component';
import {RedirectComponent}from './../app/redirect.component'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: 'auth',
    loadChildren: './login-module/login.module#LoginModule'   

  },
  {
    path: 'chart',
    component: ChartRenderComponent,
  },
  {
    path: 'welcome',
    loadChildren: './main-module/main.module#MainModule',
    canActivate: [AuuthGaurdGuard]
  },
  { path: 'redirectlogin',
  component: RedirectComponent
 },
      
 {
   path: '**',
   component: RedirectComponent
   // loadChildren: './login-module/login.module#LoginModule'
 }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload', useHash: true})],
  exports: [RouterModule],
  providers: [ResolveService]
})

export class AppRoutingModule {


}
