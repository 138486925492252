import { Injectable } from '@angular/core';

import { ApiService } from '../api.service';
import { Observable } from 'rxjs';
import { EncrDecrService } from '../encr-decr.service';
import * as CryptoJS from 'crypto-js';
import  Cookies from 'universal-cookie';
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import jwt_decode from "jwt-decode";
import { EnvService } from '../../env.service'



@Injectable({
  providedIn: 'root'
})

export class AuthService {

  public private_key = this.apiService.private_key;
  public public_key = this.apiService.public_key;
  public _fd: FormData = new FormData();
  private domain:string = '';

  //------------------------------------------------------------
  // CONSTRUCTOR
  //------------------------------------------------------------
  constructor(private apiService: ApiService, private EncrDecr: EncrDecrService, private env : EnvService) {
    this.domain = env.domain
  
   }

  //------------------------------------------------------------
  // AUTHENTICATION FUNCTION
  //------------------------------------------------------------
  public authCheck(postData: any, tempToken: any): Observable<any> {
    //return this.apiService.get('authenticate?username=' + postData['user_name'] + '&password=' + window.btoa(postData['password']));
    this._fd = new FormData();
    var e ='cd9c54024b84fd7d770680da2795cb9650620728451d009b7d877039b40b675d5646882af559e840c2b8780e5fecafff5f73e6d20c3aeb792fa1daaedd0d20cf'
    var tempToken1 = tempToken.substr(0,16);
    const key = CryptoJS.enc.Utf8.parse(tempToken1);
    const iv = CryptoJS.enc.Utf8.parse(tempToken1);
    const encrypted = CryptoJS.AES.encrypt(postData['password'], key, {
      keySize: 16,
      iv: iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    
    this._fd.append('username',postData['user_name']);
    this._fd.append('password',encrypted.toString());

    // return this.apiService.get('authenticate?username=' + postData['user_name'] + '&password=' + encodeURIComponent(encrypted.toString()));
    return this.apiService.authenticate('authenticate',this._fd);
  }


  //------------------------------------------------------------
  // GET TOKEN
  //------------------------------------------------------------
  getToken(): Observable<any> {
    return this.apiService.get('generateKeypair');
  }

  //------------------------------------------------------------
  // GET REFRESH TOKEN FOR SSO
  //------------------------------------------------------------
  refreshToken(): Observable<any>  {
    let cookies = new Cookies()
    let token = cookies.get('refreshToken')
    const body = new HttpParams()
    .set('refresh_token',token)
    .set('grant_type', 'refresh_token');

    return this.apiService.refreshToken(body);
  }

  getMenuList():Observable<any>{
    return this.apiService.get('loginServices/getMenuDetails');
  }



  //------------------------------------------------------------
  // GET USER DETAILS
  //------------------------------------------------------------
  getUserDetails():Observable<any>{
    return this.apiService.get('loginServices/getUserDetails');
  } 
  //------------------------------------------------------------
  // HEALTHCHECK FOR SSO
  //------------------------------------------------------------

  healthCheck(){
    return this.apiService.get1('actuator/health')
  }
}
