import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry, mergeMap, map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
// CUSTOM IMPORT
import { JwtService } from './jwt.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { CommonService } from '../sharedModules/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnvService } from '../env.service';
import Cookies from 'universal-cookie';

@Injectable({
  providedIn: 'root'
})
export class ApiService {


  //------------------------------------------------------------
  // PUBLIC VARIABLES AND DECLARATIONS
  //------------------------------------------------------------
  private baseUrl: string = "";
  private ssoUrl :string = "";
  private clientId : string = ""
  private header: any;
  public private_key: string = environment.private_key;
  public public_key: string = environment.public_key;
  public ssoAuth 



  //------------------------------------------------------------
  // CONSTRUCTOR
  //------------------------------------------------------------
  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private commonService: CommonService,
    private modelService: NgbModal,
    private router: Router,
    private env: EnvService
  ) {
    this.baseUrl = env.apiUrl
    this.ssoUrl = env.ssoUrl
    this.clientId = env.clientId
    const cookies = new Cookies()
    this.ssoAuth = cookies.get('ssoAuth')?JSON.parse(cookies.get('ssoAuth')):false 
  
  }

  //------------------------------------------------------------
  // GET ENVIRONMENT
  //------------------------------------------------------------
  public getEnvironment(): any {
    return environment;
  }


  //------------------------------------------------------------
  // SET CONTENT TYPE
  //------------------------------------------------------------
  private setContentType() {
    this.header = {
      'content-type': 'application/json',
    }
  }

  //------------------------------------------------------------
  // for sso
  //------------------------------------------------------------
  
  private setUserContentType() {
    this.header = {
      'Authorization':this.clientId,  
      'content-type': 'application/x-www-form-urlencoded',
    }  
  }
  //------------------------------------------------------------
  // SET FILE CONTENT TYPE
  //------------------------------------------------------------
  private setFileContentType() {
    this.header = {
      reportProgress: true,
      responseType: 'text'
    }
  }


  //------------------------------------------------------------
  // SET TEMP TOKEN
  //------------------------------------------------------------
  private setTemproryToken() {
    const temprory_token: string = this.jwtService.get('temporary-token');
    if (temprory_token) {
      this.header['temporary-token'] = temprory_token;
    }
  }


  //------------------------------------------------------------
  // SET AUTH TOKEN
  //------------------------------------------------------------
  private setAuthToken() {
    const authToken: string = this.jwtService.get('VisionAuthenticate');
    let Cookie = new Cookies()
    const token : string = Cookie.get('accessToken')

    if (this.ssoAuth) {
      this.header['Authorization'] = "Bearer "+token;

    }
    else if(!this.ssoAuth && authToken){
      this.header['VisionAuthenticate'] = authToken;

    }
  }


  //------------------------------------------------------------
  // HEADERS
  //------------------------------------------------------------
  private get headers(): any {
    this.setContentType();
    this.setTemproryToken();
    this.setAuthToken();
    return this.header;
  }
  //------------------------------------------------------------
  // for sso
  //------------------------------------------------------------
  private get checkRefreshTokenHeaders(): any {
    this.setUserContentType();    
    return this.header;
  }

  //------------------------------------------------------------
  // FILE HEADERS
  //------------------------------------------------------------
  private get fileHeaders(): any {
    this.setFileContentType();
    this.setTemproryToken();
    this.setAuthToken();
    return this.header;
  }

  //------------------------------------------------------------
  // Authenticate
  //------------------------------------------------------------
  public authenticate(relativeUrl: string, data: any) {
    return this.http.post(this.baseUrl + relativeUrl, data,
      { headers: new HttpHeaders(this.fileHeaders) }).pipe(catchError(this.handleError));

  }

   //------------------------------------------------------------
  // refreshtoken for sso
  //------------------------------------------------------------

  refreshToken(data): Observable<any>{
    return this.http.post(this.ssoUrl + 'oauth/token', data,
      { headers: new HttpHeaders(this.checkRefreshTokenHeaders) }).pipe(
        catchError(this.handleError)
      );
  }

  //------------------------------------------------------------
  // GET METHOD
  //------------------------------------------------------------
  public get(relativeUrl: string): Observable<any> {
    return this.http.get(this.baseUrl + relativeUrl, { headers: new HttpHeaders(this.headers) }).pipe(
      catchError(this.handleError)
    )
  }
  public get1(relativeUrl: string): Observable<any> {
    return this.http.get(this.ssoUrl + relativeUrl).pipe(
      catchError(this.handleError)
    )
  }

  //------------------------------------------------------------
  // POST METHOD
  //------------------------------------------------------------
  public post(relativeUrl: string, data: any) {
    return this.http.post(this.baseUrl + relativeUrl, data,
      { headers: new HttpHeaders(this.headers) }).pipe(
        catchError(this.handleError)
      );

  }


  //------------------------------------------------------------
  // PUT METHOD
  //------------------------------------------------------------
  public put(relativeUrl: string, data: any) {
    return this.http.put(this.baseUrl + relativeUrl, data,
      { headers: new HttpHeaders(this.headers) })
      .pipe(

        mergeMap((data: Response) => {
          return of(data && data['success'] === true)
        }
        ), catchError(this.handleError));
  }


  //------------------------------------------------------------
  // DELETE METHOD
  //------------------------------------------------------------
  public delete(relativeUrl: any, data: any) {
    const options = {
      headers: new HttpHeaders(this.headers),
      body: data
    };
    return this.http.delete(this.baseUrl + relativeUrl, options)
      .pipe(
        mergeMap((data: Response) => {
          return of(data)
        }), catchError(this.handleError));
  }


  //------------------------------------------------------------
  // FILE UPLOAD METHOD
  //------------------------------------------------------------
  public fileUploads(relativeUrl: string, data: any) {
    return this.http.post(this.baseUrl + relativeUrl, data,
      { headers: new HttpHeaders(this.fileHeaders) }).pipe(catchError(this.handleError));

  }

  // ------------------------------------------------------------
  // FILE Download METHOD
  // ------------------------------------------------------------
  public fileDownloads(relativeUrl: string, data: any) {
    return this.http.post(this.baseUrl + relativeUrl, data,
      { responseType: 'blob', headers: new HttpHeaders(this.fileHeaders) }).pipe(catchError(this.handleError));

  }

  //------------------------------------------------------------
  // HANDLE ERROR
  //------------------------------------------------------------
  private handleError = (error: any) => {
    let errorMessage = '';
    // this.modelService.dismissAll();
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      // errorMessage = `Error Code: ${error.status}\nMessage: ${error.error.message}`;
      errorMessage = `${error.error.message}`;
    }
    return throwError(errorMessage);
  }
}


