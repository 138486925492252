import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: "root"
})
export class LoaderService {


  //------------------------------------------------------------
  // PUBLIC VARIABLES AND DECLARATIONS
  //------------------------------------------------------------
  showLoader = new Subject<boolean>();


  //------------------------------------------------------------
  // CONSTRUCTOR
  //------------------------------------------------------------
  constructor() { }


  //------------------------------------------------------------
  // SHOW LOADER
  //------------------------------------------------------------
  show() {
    this.showLoader.next(true);
  }


  //------------------------------------------------------------
  // HIDE LOADER
  //------------------------------------------------------------
  hide() {
    this.showLoader.next(false);
  }

}
