import { EnvService } from './../../env.service';
import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { ApiService } from 'src/app/_services/api.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { overallData } from './data';
import { forkJoin, Subscription } from 'rxjs';
import { keyframes } from '@angular/animations';
import { CatologService } from '../../catalog-module/catolog.service';

import { environment } from 'src/environments/environment';
import { element } from '@angular/core/src/render3';
import { isNull } from 'util';
import { CommonService } from '../common.service';
import { RootService } from 'src/app/_services/root.service';


@Component({
  selector: 'app-manual-query',
  templateUrl: './manual-query.component.html',
  styleUrls: ['./manual-query.component.css']
})
export class ManualQueryComponent implements OnInit, OnChanges, OnDestroy {


  hashTagForm: FormGroup;
  dimensureForm: FormGroup;
  @Input() selectedQuery;
  formData = overallData.formData;
  queryMode = 'SQLQuery';
  validateResponse: any;
  cleardata = Object.assign({}, overallData.formData);
  myForm: boolean = false;
  dimensureFormtoggle: boolean = false;
  dropDownToggle: any = overallData.screenData;
  hashTag: any = [];
  hashData: any = {};
  dimensure: any = [];
  recordIndicator: any;
  queryType: any = [];
  status: any;
  dataLoadingMethods: any;
  editformData: any = [];
  subscription: Subscription;
  connDetails: any;
  Flag: boolean = false;
  dispMode = 'table1';
  confirmationBoxTitle: any;
  confirmationMessage: any;
  type: any;
  client: string;
  dbConnectorDetails: string

  constructor(
    private service: ApiService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private catlogService: CatologService,
    private root: RootService,
    private commonService: CommonService,
    private env : EnvService
  ) {
    this.client = root.client;
    this.subscription = this.catlogService.manualquery.subscribe(resp => {
      this.editformData = resp;
      if (this.editformData.length) {
        this.Flag = true;
        this.editData(resp)
      }
    })
    if(this.client == 'BNR'){
      this.formData.databaseConnectivityDetails = env.dbConnectorDetails
    }    
  }


  editData(resp) {
    Object.keys(this.formData).forEach(element => {
      this.formData[element] = resp[0][element];
    });
  }


  ngOnInit() {
    this.callDropDownDet();
  }

  callDropDownDet() {
    this.getdropDowDetails().subscribe(resp => {
      this.getRecords(resp)
    },
      error => {

      }
    )
  }


  ngOnChanges() {
  }


  getdropDowDetails() {
    return forkJoin(
      this.service.get(environment.get_pNumTab + "/?pNumTab=1"),
      this.service.get(environment.get_pNumTab + "/?pNumTab=7"),
      this.service.get("numalpha/getAlphaTab/?pAlphaTab=1088"),
      this.service.get("dcManualQuery/getdbCon?searchPattern="),
      this.service.get(environment.get_pNumTab + "/?pNumTab=2008")
    );
  }


  getRecords(resp: any) {
    this.status = resp[0].response;
    this.recordIndicator = resp[1].response;
    this.dataLoadingMethods = resp[2].response;
    this.connDetails = resp[3].response;
    this.queryType = resp[4].response;
    if (this.editformData.length) {
      this.recordIndicator.forEach(element => {
        if (element.numSubTab == this.editformData[0].recordIndicator) {
          this.formData['recordIndData'] = element.description;
        }
      })
      this.dataLoadingMethods.forEach(element => {
        if (element.alphaSubTab == this.editformData[0].lookupDataLoading) {
          this.formData['lookupDataLoading'] = element.alphaSubTab;
        }
      });
      this.formData['statusData'] = this.editformData[0].statusdescription;
    }
  }

  notHashvalue = ['TVC_SESSIONID_STG_1', 'TVC_SESSIONID_STG_2', 'TVC_SESSIONID_STG_3']

  getHashTag(content, type, content2, content3) {
    this.type = type;
    var res = [];
    var resp = [];
    var data = {
      "sqlQuery": this.formData.sqlQuery,
      "stgQuery1": this.formData.stgQuery1,
      "stgQuery2": this.formData.stgQuery2,
      "stgQuery3": this.formData.stgQuery3,
      "postQuery": this.formData.postQuery
    };

    (data['sqlQuery'] != "") ? res.push(data['sqlQuery'].match(/#(.*?)#/g)) : res;
    (data['stgQuery1'] != "") ? res.push(data['stgQuery1'].match(/#(.*?)#/g)) : res;
    (data['stgQuery2'] != "") ? res.push(data['stgQuery2'].match(/#(.*?)#/g)) : res;
    (data['stgQuery3'] != "") ? res.push(data['stgQuery3'].match(/#(.*?)#/g)) : res;
    (data['postQuery'] != "") ? res.push(data['postQuery'].match(/#(.*?)#/g)) : res;
    res.forEach(element => {
      if (!isNull(element)) {
        if (element.length > 1) {
          let count = element.toString().split(",").length;
          for (let i = 0; i < count; i++) {
            if (!this.notHashvalue.some(o2 => (element.toString().replace(/#/g, '').split(",")[i]).toUpperCase() == o2) && !resp.some(r2=>(element.toString().replace(/#/g, '').split(",")[i]).toUpperCase() == r2.toUpperCase()))
              resp.push(element.toString().replace(/#/g, '').split(",")[i]);
          }
        } else {
          if (!this.notHashvalue.some(o2 => (element.toString().replace(/#/g, '')).toUpperCase() == o2) && !resp.some(r2=>(element.toString().replace(/#/g, '')).toUpperCase() == r2.toUpperCase()))
            resp.push(element.toString().replace(/#/g, ''));
        }
      }
    })


    if (resp.length != 0 && resp[0] != null && !this.Flag) {
      this.generateHashForm(resp, content, content2, content);
    }
    else {
      if (this.type == 'add' || this.type == 'modify') {
        if (this.Flag) {
          this.doAdd();
        } else {
          // this.commonService.showToastr.error("Please validate before save your changes");
          this.doValidate('', '', content2, content3);
        }
      }
      else {
        this.doValidate('', '', content2, content3);
      }

    }
  }

  hasTagKeyValue: any = [];
  hashTagSubmit(hashTagForm: any, content2: any, content3: any) {
    this.formData['hashVariableScript'] = '';
    this.hasTagKeyValue = [];
    var keys = Object.keys(hashTagForm);
    var values = Object.values(hashTagForm);
    var column = '', value = '';
    keys.forEach(function (element, index) {
      column = column + ',' + element;
      value = value + ',' + values[index];
    });
    if (column.slice(1).split(",") && value.slice(1)) {
      let c = column.slice(1).split(",").length;
      for (let i = 0; i < c; i++) {
        this.hasTagKeyValue.push({ "name": column.slice(1).split(",")[i], "value": value.slice(1).split(",")[i] });
        this.formData['hashVariableScript'] += `{${column.slice(1).split(",")[i]}:#CONSTANT$@!${value.slice(1).split(",")[i]}#}`
      }
    }
    if (this.type == 'add' || this.type == 'modify') {
      if (this.Flag) {
        this.doAdd();
      } else {
        this.doValidate(column.slice(1), value.slice(1), content2, content3);
      }
    }
    else {
      this.doValidate(column.slice(1), value.slice(1), content2, content3);
    }
  }


  doValidate(column: any, value: any, content2: any, content3: any) {

    this.service.post("dcManualQuery/validate?hashArr=" + column + "&hashValArr=" + value, this.formData)
      .subscribe(
        resp => {
          this.validateResponse = resp;
          if (this.validateResponse.status == 1) {
            this.generateResp(this.validateResponse.response, content2);
            // this.Flag = true;

          }
          else {
            this.modalService.open(content3);
            this.confirmationBoxTitle = 'Confirmation?';
            this.confirmationMessage = this.validateResponse.message;

          }
          error => {
            this.commonService.showToastr.error(error);

          }
        })
  }


  haddSmodify: boolean = true;
  doAdd() {
    var apiLink = '';
    if (this.type == 'add') {
      apiLink = "dcManualQuery/add?forceFlag=";
    } else if (this.type == 'modify') {
      apiLink = "dcManualQuery/modify?forceFlag=";
    } else {
      if (!this.editformData.length) {
        apiLink = "dcManualQuery/add?forceFlag=";
      } else {
        apiLink = "dcManualQuery/modify?forceFlag=";
      }
    }

    this.service.post(apiLink + this.Flag, this.formData)
      .subscribe(
        resp => {
          this.validateResponse = resp;
          if (resp['status'] == 1) {
            this.commonService.showToastr.success(resp['message']);
            this.haddSmodify = false;
            this.catlogService.modifyDatas(true);
          }
          else {
            this.commonService.showToastr.error(resp['message']);
          }

        },
        error => {
          console.log("Erro" + error);

        })
  }


  dimensureSubmit(formValue: any) {
    this.formData.queryColumnXML = `<columns>${this.xmlForm(formValue)}</columns>`;
    this.Flag = true;
    if (this.type == 'add' || this.type == 'modify') {
      this.doAdd();
    }
  }


  xmlForm(formValue: any) {
    var key = Object.keys(formValue);
    var data = '';
    key.forEach(element => {
      data = data + '<column>' +
        '<name>' + element + '</name>' +
        '<type>' + formValue[element] + '</type>' +
        '</column>'
    });
    return data;
  }


  generateResp(resp: any, content2: any) {
    this.dimensure = [];
    var keys = Object.keys(resp);
    this.modalService.open(content2);
    keys.forEach(element => {
      this.dimensure.push({
        name: element, type: "radio", value: resp[element], options: [
          { key: 'D', value: 'Dimension' },
          { key: 'M', value: 'Measures' }
        ],
      });
    })
    this.dimensureForm = this.dynamicForm(this.dimensure);
    this.dimensureFormtoggle = true;
  }

  isExists: boolean = false;
  generateHashForm(resp: any, content, content2, content3) {
    this.modalService.open(content);
    this.hashTag = [];
    var hash = resp;
    if (hash != null || hash != undefined) {
      if (this.formData['hashVariableScript'] != '' && this.hasTagKeyValue.length > 0 && !this.Flag) {
        hash.forEach(element => {
          this.isExists = false;
          this.hasTagKeyValue.forEach(ele => {
            if (ele.name == element) {
              this.hashTag.push({ name: element, type: "text", value: ele.value });
              this.isExists = true;
            }
          });
          if (!this.isExists) {
            this.hashTag.push({ name: element, type: "text", value: '' });
          }
        });
      } else if (this.formData['hashVariableScript'] != '' && this.Flag) {

        var count = this.formData['hashVariableScript'].split("#}{").length;
        if (count > 0) {
          for (let i = 0; i < count; i++) {
            var text = this.formData['hashVariableScript'].split("#}");
            text = `${text[i]}#}`;
            this.hashTag.push({ name: text.match("\\{(.*?):#(.*?)\\$@!(.*?)\\#}")[1], type: "text", value: text.match("\\{(.*?):#(.*?)\\$@!(.*?)\\#}")[3] });
          }
        }
      } else {
        hash.forEach(element => {
          this.hashTag.push({ name: element, type: "text", value: '' });
        });
      }
      if (this.hashTag.length) {
        this.hashTagForm = this.dynamicForm(this.hashTag);
        this.myForm = true;
      }
    }
  }


  dynamicForm(data: any) {

    const group = this.fb.group({});
    data.forEach(field => {
      const control = this.fb.control(
        field.value, Validators.required
      );
      group.addControl(field.name, control);
    });
    return group;
  }


  clear() {
    Object.keys(this.formData).forEach(element => {
      this.formData[element] = '';
    });
    if(this.client == 'BNR'){
      this.formData.databaseConnectivityDetails =this.env.dbConnectorDetails
    } 
  }


  sayYes() {
    this.Flag = true;
    this.doAdd();
  }


  sayNo() {
    this.Flag = false;
  }

  convertingToUpperCase(event: any, updateValue: any) {
    let hashString = event.target.value;
    let arrayData = hashString.split("#");
    arrayData.forEach((element, index, array) => {
      if (index % 2 != 0) {
        array[index] = `#${element.toUpperCase().replace(/[^a-zA-Z0-9_]/g, "")}#`;

      }
    });
    this.formData[updateValue] = arrayData.join('');
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.clear();
  }
}