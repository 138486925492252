import { Injectable, Injector } from "@angular/core";
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { environment } from 'src/environments/environment';

// CUSTOM IMPORTS
import { LoaderService } from "./loader.service";
import { CommonService } from '../sharedModules/common.service';
import { EnvService } from '../env.service';

@Injectable()

export class Interceptor implements HttpInterceptor {

  private baseUrl: string = "";
  //------------------------------------------------------------
  // CONSTRUCTOR
  //------------------------------------------------------------
  constructor(private injector: Injector, private env: EnvService) { this.baseUrl = env.apiUrl }

  private requests: HttpRequest<any>[] = [];
  loaderService = this.injector.get(LoaderService);
  //------------------------------------------------------------
  // INTERCEPT METHOD
  //------------------------------------------------------------
  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i != -1) { this.requests.splice(i, 1); }
    if (this.requests.length - 1 < 0) { this.loaderService.hide(); }
    // this.commonService.loadspinnerService.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // this.commonService.loadspinnerService.next(true);
    this.loaderService.show();
    if (req.url == `${this.baseUrl}${environment.get_chartXML}` || req.url == `${this.baseUrl}${environment.getTileData}` || req.url == `${this.baseUrl}${environment.getDrillDownData}`) {
      this.loaderService.hide();
    } else {
      this.requests.push(req);
    }
    return Observable.create(observer => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => { this.removeRequest(req); observer.error(err); },
          () => { this.removeRequest(req); observer.complete(); });
      // teardown logic in case of cancelled requests
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }

  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   const loaderService = this.injector.get(LoaderService);

  //   loaderService.show();
  //   return next.handle(req).pipe(
  //     finalize(() => {

  //       loaderService.hide();
  //     })
  //   );
  // }
}

