import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[alphanumeric]'
})
export class alphanumeric {

  constructor(private el: ElementRef) { }

  @Input() alphanumeric: boolean;
  @Input() alphanumericspecialchar: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent>event;
    var specialKeys = new Array();
    specialKeys.push(8);  //Backspace
    specialKeys.push(9);  //Tab
    specialKeys.push(46); //Delete
    specialKeys.push(36); //Home
    specialKeys.push(35); //End
    specialKeys.push(37); //Left
    specialKeys.push(39); //Right

    var specialChars = new Array();
    specialChars.push(189); //Underscore
    specialChars.push(188); //comma
    specialChars.push(190); //colon

    if (this.alphanumeric) {
      var keyCode = e.keyCode == 0 ? e.charCode : e.keyCode;
      if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90)
        || keyCode == 32 || (keyCode >= 97 && keyCode <= 122)
        || (specialKeys.indexOf(e.keyCode) != -1 && e.charCode != e.keyCode)) {
        return;
      }
      else {
        e.preventDefault();
      }
    }
    if (this.alphanumericspecialchar) {
      var keyCode = e.keyCode == 0 ? e.charCode : e.keyCode;
      if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90)
        || keyCode == 32 || (keyCode >= 97 && keyCode <= 122)
        || (specialKeys.indexOf(e.keyCode) != -1 && e.charCode != e.keyCode)
        || (specialChars.indexOf(e.keyCode) != -1 && e.charCode != e.keyCode)) {
        return;
      }
      else {
        e.preventDefault();
      }
    }
  }
}