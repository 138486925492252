import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeactivateRouteComponent } from './deactivate-route/deactivate-route.component';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateService {
  constructor(private modelService: NgbModal,private router : Router){

  }
  confirm(message?: string,url?){
    const subject = new Subject<boolean>();
    const modelRef = this.modelService.open(DeactivateRouteComponent,{
      size: 'lg',
      backdrop: true
    });
    modelRef.componentInstance.subject = subject;
    modelRef.componentInstance.closeBox.subscribe(resp=>{
      if(!resp){
        modelRef.close();
      }
    })
    return subject.asObservable();
  };
}
