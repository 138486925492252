import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';

// CUSTOM IMPORTS
import { DataConnectorService } from './data-connector/data-connector.service';
import { CatalogService } from './catalog/catalog.service';

@Injectable({
  providedIn: 'root'
})

export class ResolveService implements Resolve<Observable<any>>{


  //------------------------------------------------------------
  // CONSTRUCTOR
  //------------------------------------------------------------
  constructor(private service: CatalogService,
    private dataConnectorService: DataConnectorService) { }


  //------------------------------------------------------------
  // RESOLVE METHOD
  //------------------------------------------------------------
  resolve() {
    return this.service.getAll(0, 0, 0);
  }

}
