import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CompleterService, CompleterData, CompleterItem } from 'ng2-completer';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';

@Component({
  selector: 'app-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.css']
})
export class AutoCompleteComponent implements OnInit {

  @Input() completerConfig: any = {};
  @Input() dataSource: any = [];
  @Input() excludeList: any = [];
  @Output() selectedItem: EventEmitter<any> = new EventEmitter<any>();

  public selectedValues: any = [];
  public placeHolder: string;
  public textNoResults: string;
  public clearSelected: boolean;
  public openOnFocus: boolean;
  public fillHighlighted: boolean;

  public searchStr: string;
  public dataService: CompleterData;
  public searchData = this.dataSource;


  constructor(private completerService: CompleterService) {
    //this.dataService = completerService.local(this.searchData, 'color', 'color');
  }

  ngOnInit() {
    this.setOptions();
    const rippleLine = document.createElement('div');
    const createLabel = document.createElement('label');
    const labelText = document.createTextNode('Add connectors');
    createLabel.appendChild(labelText);
    rippleLine.setAttribute('class', 'rippleLine');
    document.getElementsByClassName('completer-holder')[0].appendChild(rippleLine).after(createLabel);
  }

  ngOnChanges() {
    this.searchData = this.dataSource;
    let sourceList = Object.assign([], this.dataSource);
    //this.searchData = this.deleteExcludeList(sourceList);
    let aaa = Observable.from([this.searchData]).delay(500);    
    this.dataService = this.completerService.local(aaa);
  }

  public deleteExcludeList(sourceList) {
    for (let i = 0; i < this.excludeList.length; i++) {
      sourceList.splice(sourceList.indexOf(this.excludeList[i]), 1);
    }
    return sourceList;
  }

  private setOptions() {
    this.placeHolder = this.completerConfig['placeholder'] ? this.completerConfig['placeholder'] : 'Auto Complete';
    this.textNoResults = this.completerConfig['textNoResults'] ? 'Yet to Create Connectors..' : 'Yet to Create Connectors..';
    this.clearSelected = this.completerConfig['clearSelected'] ? this.completerConfig['clearSelected'] : true;
    this.openOnFocus = this.completerConfig['openOnFocus'] ? this.completerConfig['openOnFocus'] : true;
    this.fillHighlighted = this.completerConfig['fillHighlighted'] ? this.completerConfig['fillHighlighted'] : false;
  }

  selectedvalues(selectedValue: CompleterItem) {
    if (selectedValue !== null) {
      this.selectedItem.emit(selectedValue);
    }
    this.searchStr = " ";
  }

  public resetModel(e) {
    this.searchStr = "";
  }

}
