import { Component, OnInit, AfterViewInit, ViewChild, HostListener } from '@angular/core';
import { CommonService } from './sharedModules/common.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { LoaderService } from "../app/_services/loader.service";
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from './_services';
import {ActivatedRoute} from '@angular/router';
import Cookies from 'universal-cookie';
import { EnvService } from './env.service';
import { Router } from '@angular/router';
import { AuthService } from './_services/auth/auth.service';
import jwt_decode from 'jwt-decode'
import { Idle } from '@ng-idle/core';
import { Location } from '@angular/common';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {


  //------------------------------------------------------------
  // PUBLIC VARIABLES AND DECLARATIONS
  //------------------------------------------------------------
  title = 'selfBI';
  formValue: any = [];
  loaded: boolean = false;
  public loader: boolean = false;
  public updateDateTime = new BehaviorSubject('');
  public domain:string
  ssoAuth: boolean;




  //------------------------------------------------------------
  // CONSTRUCTOR
  //------------------------------------------------------------
  constructor(private common: CommonService,
    private _loaderService: LoaderService,
    private globalService:GlobalService,
    public translate: TranslateService,
    public router : Router,
    public route : ActivatedRoute,
    public location:Location,
    public env : EnvService,
    public authService : AuthService,
    private idle: Idle) {
      this.domain = env.domain
      this.ssoAuth = env.ssoAuth

  }

  @HostListener('window:unload', [ '$event' ])
  unloadHandler(event) {

  }

  @HostListener('window:beforeunload', [ '$event' ])
  beforeUnloadHander(event) {
    return 'ssss';
  }
  //------------------------------------------------------------
  // INITIALIZATION
  //------------------------------------------------------------
  ngOnInit() {

    const cookies = new Cookies();
    let token = cookies.get('accessToken')
    if(!token && this.ssoAuth){
      this.router.navigate(['redirectlogin']);
    }
    if(token){ 
      let jwtToken:any =jwt_decode(token)
      this.idle.setIdle(jwtToken.exp);
      this.idle.setTimeout(30); // Timeout 10 seconds after being idle
      // Start
      this.idle.watch();
    
    }
    
  }
  // ------------------------------------------------------------
  //
  // ------------------------------------------------------------
  ngAfterViewInit() {
    window.onload = (ev) => {
      localStorage.setItem('pageTitle','Dashboard');
      this.globalService.pageTitle="Dashboard";
      localStorage.setItem('pagePath','SSBI/Viewer');
      this.globalService.pagePath="SSBI/Viewer";
     };
  }
}
