import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidechildMenuComponent } from './sidechild-menu/sidechild-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2CompleterModule } from 'ng2-completer';
import { CytoscapeModule } from "ngx-cytoscape";
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileDropModule } from 'ngx-file-drop';
import { ToastrModule } from 'ngx-toastr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollDispatchModule } from '@angular/cdk/scrolling';

import { DatePipe } from './datePipe/pipe';
import { CommonTableComponent } from './common-table/common-table.component';
import { TreeViewComponent } from './tree-view/tree-view.component'
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { TreeListComponent } from './tree-list/tree-list.component';
import { ManualQueryComponent } from './manual-query/manual-query.component';

import { FusionChartsModule } from 'angular-fusioncharts';
import { ScrollingModule } from '@angular/cdk/scrolling';

// Import FusionCharts library and chart modules
import * as FusionCharts from 'fusioncharts';
import * as Charts from 'fusioncharts/fusioncharts.charts';


import * as Ocean from 'fusioncharts/themes/fusioncharts.theme.ocean';
import * as Fint from 'fusioncharts/themes/fusioncharts.theme.fint';
import * as Candy from 'fusioncharts/themes/fusioncharts.theme.candy';
import * as Gammel from 'fusioncharts/themes/fusioncharts.theme.gammel';
import * as Zune from 'fusioncharts/themes/fusioncharts.theme.zune';
import * as Carbon from 'fusioncharts/themes/fusioncharts.theme.carbon';

import * as powercharts from 'fusioncharts/fusioncharts.powercharts';
import * as widgets from 'fusioncharts/fusioncharts.widgets';
import * as gantt from 'fusioncharts/fusioncharts.gantt';
import * as treemap from 'fusioncharts/fusioncharts.treemap';
import * as zoomscatter from 'fusioncharts/fusioncharts.zoomscatter';
import * as zoomline from 'fusioncharts/fusioncharts.zoomline';
import * as overlappedbar2d from 'fusioncharts/fusioncharts.overlappedbar2d';
import * as FusionTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';

import { TreeModule } from 'angular-tree-component';
import { OnlyNumber } from './numbersOnly/onlynumber.directive';
import { alphanumeric } from './alphaNumeric/alphanumeric';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ExecuteQueryComponent } from './execute-query/execute-query.component';
import { MagnifierPopupComponent } from '../query-module/popup-components/magnifier-popup/magnifier-popup.component';
import { GenericPopupComponent } from './generic-popup/generic-popup.component';
import { ExecuteQueryNewComponent } from './execute-query-new/execute-query-new.component';
import { AccordionModule, AccordionConfig } from 'ngx-bootstrap/accordion';
import { FilterScreenComponent } from '../dashboard-module/Widget/filter-screen/filter-screen.component';
import { FilterForChartComponent } from '../dashboard-module/Widget/filter-for-chart/filter-for-chart.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { UserManagementComponent } from './share-ownership/user-management/user-management.component';
import { ShareOwnershipComponent } from './share-ownership/share-ownership.component';
import { ConfirmationPopupComponent } from './confirmation-popup/confirmation-popup.component';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SearchComponent } from './search/search.component';
import { NgxTableComponent } from './ngx-table/ngx-table.component';
import { DeactivateRouteComponent } from '../_services/gaurd/deactivate-route/deactivate-route.component';
import { ClipboardModule } from 'ngx-clipboard';
import {  RxReactiveFormsModule } from "@rxweb/reactive-form-validators"
import { MagnifierSharedComponent } from './magnifier/magnifier.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { DomSanPipe } from './dom-san.pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SmartTableComponent } from './smart-table/smart-table.component';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

// Pass the fusioncharts library and chart modules
FusionChartsModule.fcRoot(FusionCharts, Charts,
  Ocean,
  Fint,
  Candy,
  Gammel,
  Zune,
  Carbon,
  powercharts, FusionTheme,widgets, gantt, treemap, zoomscatter, zoomline, overlappedbar2d);

  FusionCharts.options['license']({
    key:'uuA5exvF3F3I2C2A11C6C5A3F5E1A2E1mllC7E2B4dD4F3H3yxoF4E2D3isbB6C1E3ycaC1G4B1B8B4A4B3D3B4H3A33fhhD8B1SG4lhJ-7A9C11A5veE3NA1A1sslE2D6G2F3H3J3A7A5A4A2F4C2D1H4z==',
       creditLabel: false,
  });

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: false,
  suppressScrollY: false
};

@NgModule({
  declarations: [
    SidechildMenuComponent,
    CommonTableComponent,
    DatePipe,
    TreeViewComponent,
    AutoCompleteComponent,
    TreeListComponent,
    MagnifierSharedComponent,
    OnlyNumber,
    alphanumeric,
    DomSanPipe,
    MagnifierPopupComponent,
    ManualQueryComponent,
    UserManagementComponent,
    ShareOwnershipComponent,
    ExecuteQueryComponent,
    GenericPopupComponent,
    FilterForChartComponent, FilterScreenComponent,
    ExecuteQueryNewComponent,
    ConfirmationPopupComponent,
    SearchComponent,
    NgxTableComponent,
    SmartTableComponent,
    DeactivateRouteComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    CytoscapeModule,
    ReactiveFormsModule,
    FileDropModule,
    HttpClientModule,
    NgxUsefulSwiperModule,
    Ng2CompleterModule,
    NgSelectModule,
    DpDatePickerModule,
    PerfectScrollbarModule,
    ToastrModule.forRoot(),
    TreeModule.forRoot(),
    AccordionModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    FusionChartsModule,
    ScrollDispatchModule,
    // DropdownListModule,
    ScrollingModule,
    DragDropModule,
    RxReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ClipboardModule
  ],
  exports: [
    TranslateModule,
    SidechildMenuComponent,
    CommonTableComponent,
    TreeViewComponent,
    NgxTableComponent,
    SmartTableComponent,
    UserManagementComponent,
    MagnifierSharedComponent,
    ShareOwnershipComponent,
    AutoCompleteComponent,
    NgbModule,
    ScrollDispatchModule,
    HttpClientModule,
    FormsModule,
    CytoscapeModule,
    NgxUsefulSwiperModule,
    ReactiveFormsModule,
    FileDropModule,
    Ng2CompleterModule,
    DatePipe,
    DomSanPipe,
    NgSelectModule,
    ToastrModule,
    TreeListComponent,
    TreeModule,
    DpDatePickerModule,
    FusionChartsModule,
    NgMultiSelectDropDownModule,
    AccordionModule,
    // DropdownListModule,
    OnlyNumber,
    PerfectScrollbarModule,
    alphanumeric,
    DragDropModule,
    DeactivateRouteComponent,
    ManualQueryComponent,
    MagnifierPopupComponent,
    ExecuteQueryComponent,
    GenericPopupComponent,
    ExecuteQueryNewComponent,
    FilterForChartComponent,
    FilterScreenComponent,
    ConfirmationPopupComponent,
    SearchComponent,
    ClipboardModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: AccordionConfig, useFactory: getAccordionConfig }
  ],
  entryComponents: [
    MagnifierPopupComponent,
    MagnifierSharedComponent,
    ExecuteQueryComponent,
    GenericPopupComponent,
    ExecuteQueryNewComponent,
    FilterForChartComponent, FilterScreenComponent,
    ConfirmationPopupComponent,
    SearchComponent,
    DeactivateRouteComponent
  ]
})
export class SharedModule { }

export function getAccordionConfig(): AccordionConfig {
  return Object.assign(new AccordionConfig(), { closeOthers: true });
}

 