import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from 'src/app/_services/api.service';
import { TreeViewService } from 'src/app/sharedModules/tree-view/tree-view.service';
import { environment } from '../../../environments/environment'
import { CommonService } from 'src/app/sharedModules/common.service';
import { forkJoin } from 'rxjs';
import { isNull } from 'util';
import { DummyService } from './dummy.service';
@Component({
  selector: 'app-share-ownership',
  templateUrl: './share-ownership.component.html',
  styleUrls: ['./share-ownership.component.css']
})
export class ShareOwnershipComponent implements OnInit {

  //------------------------------------------------------------
  // PUBLIC VARIABLES AND DECLARATIONS
  //------------------------------------------------------------
  treeData: any = [];
  treeData2: any = [];
  pushbutton = false; leftpushbutton = true;
  treeview: boolean = false;
  treeview2: boolean = false;
  @Input() sharableDet;
  @Input() viewerPerspect: boolean = false;
  @Output() repBack = new EventEmitter();
  dataSet = [];
  shareMode = 'USERGROUP';
  new: boolean = false;
  searchToggle: any = false;
  main_resp: any = [];
  tryvalue: any = [];
  treeData_cpy: any = [];
  overalDet: any = {};
  editList_cpy: any = [];
  edit_userlist: any = [];
  draggedData = [];
  userlistArry: any = {};
  selected_filter: any = '';
  finalResult: any = {};
  secondDrag = [];

  option = {
    prepareData: true,
    checkbox: true,
    delete: false,
    readability: false
  }

  option2 = {}

  //------------------------------------------------------------
  // CONSTRUCTOR
  //------------------------------------------------------------
  constructor(
    private service: ApiService,
    private dummyService: DummyService,
    private treeService: TreeViewService,
    private commonService: CommonService

  ) {
  }



  //------------------------------------------------------------
  //  ngOnInit
  //------------------------------------------------------------
  ngOnInit() {
    this.option2 = {
      prepareData: true,
      checkbox: true,
      delete: false,
      readability: this.viewerPerspect
    }

    this.generateAPI().subscribe(resp => {
      this.framingObject(resp[0]['response'], resp[1]['response']['lodProfileList']);
      this.edit_userlist = resp[1]['response']['lodUserList'] != null ? resp[1]['response']['lodUserList'] : [];
    },
      error => {

      }
    )

    this.userListAPI();

  }


  //-----------------------------------------------------------------
  //  both Api for forming whole tree(left) with selected tree(right)
  //-----------------------------------------------------------------
  generateAPI() {
    let mainModel = {};
    mainModel["mainModel"] = this.sharableDet['details'];
    return forkJoin(
      this.service.get(environment.get_userGroupProfile),
      this.service.post(this.sharableDet['apiLink'], mainModel),
    );
  }

  //------------------------------------------------------------
  //  forming  Data for both tree
  //------------------------------------------------------------
  framingObject(respOne, respTwo) {
    let user_group_list: any = [];
    if (!isNull(respTwo)) {
      respTwo.forEach((element, index, array) => {
        array[index]['ID'] = element.hasOwnProperty('userGroup') ? element['userProfile'] + '' + element['userGroup'] : element['userProfile'];
        array[index]['filter_name'] = 'filter_name'
        array[index]['isSelected'] = true;
        array[index]['children'] = []
        user_group_list.includes(element.userGroup) ? '' : user_group_list.push(element.userGroup);
      });

      user_group_list.forEach(element => {
        let user_Group: any = {};
        user_Group = {
          isSelected: true,
          ID: element,
          parent: false,
          isIndeterminate: false,
          userProfile: element,
          NAME: element,
          filter_name: element,
        };
        respTwo.push(user_Group);
      });


      respTwo = this.uniqueArray(respTwo, 'ID');
      this.editList_cpy = respTwo;
      this.secondDrag = respTwo;
      this.treeData2 = this.secondDrag;
      this.treeview2 = true;
      this.draggedData = this.formingChildArray(this.secondDrag);
      this.indeterminentCheck();
      this.userListAPI();
    }

    this.main_resp = respOne;
    this.datatest(respOne);
    this.treeData = this.tryvalue;
    this.treeData_cpy = this.tryvalue;
    this.treeview = true;
  }



  //------------------------------------------------------------
  //  grouping children under parent structure
  //------------------------------------------------------------
  formingChildArray(array_list: any) {
    let parent_Array = [];
    let child_Array = {};
    array_list.forEach(element => {
      if (!element.hasOwnProperty('userGroup')) {
        let detail = {
          ID: element.ID,
          filter_name: element.userProfile,
          userProfile: element.userProfile,
          isSelected: true,
          isIndeterminate: false,
          parent: true,
          children: []
        }
        parent_Array.push(detail);
      }
    });
    array_list.forEach(child => {
      parent_Array.forEach((parent, index, array) => {
        if (child.hasOwnProperty('userGroup')) {
          if (parent['userProfile'] == child['userGroup']) {
            child_Array = {
              ID: child.ID,
              filter_name: child.userProfile,
              userProfile: child.userProfile,
              userGroup: child.userGroup,
              isSelected: true,
              isIndeterminate: false,
              parent: true,
              children: []
            }
            array[index]['children'].push(child);
          }
        }
      });
    });
    return parent_Array;
  }


  //------------------------------------------------------------
  // here we count the selected child of maintree
  //------------------------------------------------------------
  indeterminentCheck() {
    let overal_Det = {};
    let array_list = this.formingChildArray(this.secondDrag) || [];
    array_list.forEach(element => {
      if (element.isSelected || element.isIndeterminate) {
        overal_Det[element.ID] = element.children.length
      }
    }
    );
    this.overalDet = overal_Det;
    this.tryvalue = [];
    this.datatest(this.main_resp);
  }


  //------------------------------------------------------------
  //  Here Formed required data structure for forming tree
  //------------------------------------------------------------
  datatest(data: any) {
    data.forEach((element, index) => {
      if (element.children.length) {
        this.datatest(element.children);
        this.tryvalue.push({
          ID: element.userGroup,
          NAME: element.userGroup,
          userProfile: element.userGroup,
          isSelected: false,
          isIndeterminate: this.overalDet.hasOwnProperty(element.userGroup) ? this.overalDet[element.userGroup] == element.children.length || this.overalDet[element.userGroup] == 0 ? false : true : false,
          filter_name: element.userGroup,
          userGroupAt: element.userGroupAt, userProfileAt: element.userProfileAt,
          parent: false,
          childrenLength: element.children.length
        })
      }
      else {
        this.tryvalue.push({
          ID: element.userProfile + element.userGroup,
          filter_name: element.userGroup,
          NAME: element.userProfile,
          userGroup: element.userGroup, userProfile: element.userProfile,
          userGroupAt: element.userGroupAt, userProfileAt: element.userProfileAt,
          parent: false,
          isSelected: false
        })
      }
    });
  }



  //------------------------------------------------------------
  //  here we get checked data from tree component 
  //------------------------------------------------------------

  onChange(event) {
    this.draggedData.push(event);
    this.secondDrag = [];
    this.draggedData = this.uniqueArray(this.draggedData, 'userProfile');
    var data = this.draggedData;
    data.forEach((element, index, array) => {
      if (element.isSelected == false) {
        let count = 0;
        element.children.forEach(child => {
          if (child['isSelected']) {
            count++
          }
        });
        if (count == 0) {
          array[index]['isIndeterminate'] = false
        }
      }
    });
    this.subTree(data);
    this.indeterminentCheck();
  }


  //-----------------------------------------------------------------
  //  Here we are forming sub tree using selected data from main tree
  //-----------------------------------------------------------------
  subTree(data: any) {
    data.forEach((element, index) => {
      if (element.children.length) {
        this.subTree(element.children);
        if (element.isSelected || element.isIndeterminate) {
          this.secondDrag.push({
            ID: element.userProfile,
            filter_name: element.userProfile,
            userProfile: element.userProfile, isSelected: true, parent: true,
          })
        }
      }
      else if (element.children.length == 0) {
        if (element.isSelected) {
          this.secondDrag.push({
            ID: element.userProfile + element.userGroup,
            userGroup: element.userGroup,
            filter_name: element.userGroup,
            parentLevel: false,
            userGroupAt: element.userGroupAt, userProfileAt: element.userProfileAt,
            visionId: element.visionId, isSelected: element.isSelected, userProfile: element.userProfile, parent: false
          })
        }
      }
    });
  }


  //------------------------------------------------------------
  //  to push data from main tree to sub tree
  //------------------------------------------------------------
  pushdata() {
    this.treeData2 = this.secondDrag.slice(0);
    this.treeview2 = true;
    this.userListAPI();
    this.indeterminentCheck();
    // this.datatest(this.treeData_cpy)
  }



  //------------------------------------------------------------
  //  to push unwanted data from sub tree to main tree
  //------------------------------------------------------------
  leftpushdata() {
    this.tryvalue.forEach((element: any, index: number, array) => {
      this.new = false;
      this.secondDrag.forEach((element2: any, index2: number, array1) => {
        if (element.ID == element2.ID) {
          this.new = true;
        }
      })
      if (this.new) { element.isSelected = true; } else { element.isSelected = false; }
    });
    this.treeData = this.tryvalue.slice(0);
    this.treeData2 = this.secondDrag.slice(0);
    this.indeterminentCheck();
  }

  //------------------------------------------------------------
  //  select tag which we used to get type on what basis going to sort
  //------------------------------------------------------------


  filterType(event: any) {
    this.selected_filter = event;
  }

  //------------------------------------------------------------
  //  AUTO SUGGESTION SEARCH
  /** @param event, @param allData, @param resultedData, @param searchBy[userGroup, userProfile] */
  //------------------------------------------------------------
  public autoSuggestionSearch(event, allData, resultedData) {
    const initialData = this[allData];
    let filtered_array = [];
    let input = event.target.value.toLowerCase();
    filtered_array = initialData.filter(ele => (ele['NAME'].toLowerCase().indexOf(input) != -1));
    filtered_array.forEach(ele => {
      if (ele['filter_name'] !== ele['NAME']) {
        if (filtered_array.map(ele => ele.filter_name).indexOf(ele['NAME']) == -1) {
          let pos = initialData.map(ele => ele.ID).indexOf(ele['filter_name']);
          let chilPos = filtered_array.map(ele => ele['NAME']).indexOf(initialData[pos]['NAME']);
          if (chilPos == -1) {
            filtered_array.push(initialData[pos]);
          }
        }
      } else {

      }
    });
    this[resultedData] = filtered_array;
  }

  //------------------------------------------------------------
  //  filtering tree
  //------------------------------------------------------------
  filterData(event: any) {
    let filter_data = event.target.value;
    let fields = this.treeData_cpy[0];
    let filtered_array = [];
    let items = this.treeData_cpy;
    let parent = [];
    if (this.selected_filter == 'userProfile') {
      items = items.filter(item => {
        if (item.hasOwnProperty('userGroup')) {
          if (item['NAME'].toLowerCase().indexOf(filter_data.toLowerCase()) != -1) {
            parent.indexOf(item['userGroup']) == -1 ? parent.push(item['userGroup']) : '';
            return true;
          }
          else {
            return false;
          }
        }
        else {
          return true;
        }

      });
      parent.forEach(element => {
        items.forEach(item => {
          if (element == item['filter_name']) {
            filtered_array.push(item)
          }
        });
      })
      this.treeData = filtered_array;
    }
    else if (this.selected_filter == 'userGroup') {
      items = items.filter(item => {

        if (item['filter_name'].toLowerCase().indexOf(filter_data.toLowerCase()) != -1) {
          return true;
        }
        else {
          return false;
        }

      });
      this.treeData = items;
    }
  }

  refresh() {
    this.treeData = this.treeData_cpy;
    this.selected_filter = '';
  }


  //-----------------------------------------------------------------------------------------------------------------------------------------------------
  //  under Methods are used forforming userlist 
  //-----------------------------------------------------------------------------------------------------------------------------------------------------

  userListAPI() {
    this.dataSet = [];
    this.secondDrag.forEach((element, index, array) => {
      if (element.hasOwnProperty('userGroup')) {
        this.dataSet.push(element)
      }
    });
    this.service.post('levelOfDisplay/userListExcludingUserGrpAndProf', this.dataSet).subscribe(resp => {
      if (resp['status']) {
        this.userProfileList(this.respFormat(resp['response']))
      }
    })
  }

  //------------------------------------------------------------
  //  forming table data (checked & unchecked) based on user get from edit API
  //------------------------------------------------------------
  userProfileList(resp: any) {
    this.userlistArry = this.dummyService.arrayListCheckBinging(resp, this.edit_userlist);
    this.leftpushdata();
  }

  respFormat(resp) {
    let resp_Det: any = [];
    if (this.viewerPerspect) {
      let res = resp.slice(0);
      res.forEach(element => {
        element['writeFlag'] = false;
        resp_Det.push(element);
      });
    }
    else {
      resp_Det = resp;
    }
    return resp_Det;
  }




  userDetails(userList: any) {

    this.apply(userList);
  }

  //------------------------------------------------------------
  //  Submitting data to API
  //------------------------------------------------------------
  apply(userListData?: any) {

    this.shareMode = 'USERGROUP';
    if (this.sharableDet['type'] == 'dataConnector') {
      this.finalResult = {
        "mainModel": {
          "macroVar": this.sharableDet['details'].macroVar,
          "scriptType": this.sharableDet['details'].scriptType,
        }
      }
    } else if (this.sharableDet['type'] == 'DesignQuery') {
      this.finalResult = {
        "mainModel": {
          "macroVar": this.sharableDet['details'].macroVar,
          "scriptType": this.sharableDet['details'].scriptType,
        }
      }
    } else if (this.sharableDet['type'] == 'ManualQuery') {
      this.finalResult = {
        "mainModel": {
          "macroVar": this.sharableDet['details'].macroVar,
          "scriptType": this.sharableDet['details'].scriptType,
        }
      }
    } else if (this.sharableDet['type'] == 'dashboard') {
      this.finalResult = {
        "mainModel": {
          "dashboardId": this.sharableDet['details'].dashboardId,
          "rsdStatus": 0,
          "maker": this.sharableDet['details'].maker,
        }
      }
    } else if (this.sharableDet['type'] == 'widget') {
      this.finalResult = {
        "mainModel": {
          "widgetId": this.sharableDet['details'].widgetId,
        }
      }
    } else {
      this.finalResult = {
        "mainModel": {
          "catalogId": this.sharableDet['details'].catalogId,
        }
      }
    }

    this.finalResult["lodUserList"] = userListData;

    let userProfile_list = [];
    userProfile_list = this.secondDrag.filter(resp => {
      if (!resp.hasOwnProperty('userGroup')) {
        return false;
      }
      else {
        return true
      }
    })
    this.finalResult['lodProfileList'] = userProfile_list;
    this.service.post(this.sharableDet['saveLinK'], this.finalResult).subscribe(resp => {
      if (resp['status']) {
        this.commonService.showToastr.success(resp['message']);
        this.repBack.emit('data');
      }
      else {
        this.commonService.showToastr.error(resp['message']);
      }
    })

  }



  disableApply() {
    return !Object.keys(this.finalResult).length;
  }


  //----------------------------------------------------------------
  //  switching from userGroup to user tab and API for getting user
  //----------------------------------------------------------------
  userTab() {
    if (this.userlistArry.length) {
      this.shareMode = 'USER';
    }

  }


  //----------------------------------------------------------------
  //  common method for unique array object
  //----------------------------------------------------------------
  uniqueArray(array, elem_name) {
    return Object.values(array.reduce((acc, cur) => Object.assign(acc, { [cur[elem_name]]: cur }), {}))
  }

  //------------------------------------------------------------
  //  this one for closing the popup
  //------------------------------------------------------------
  closeModel() {
    this.repBack.emit('data');
  }
}
























