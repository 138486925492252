import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JwtService {

  constructor() { }

  get(flag: string): string {
    return localStorage.getItem(flag);
  }

  set(flag: string, token: string): void {
    localStorage.setItem(flag, token);
  }
  
  unset(flag: string): void {
    localStorage.removeItem(flag);
  }

  clear(): void {
    localStorage.clear();
  }
  
}
