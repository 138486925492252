import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/_services/api.service';
import { FormGroup, FormBuilder, FormArray, NgModel, Validators, AbstractControl, FormControl } from '@angular/forms';
import { MagnifierPopupComponent } from 'src/app/query-module/popup-components/magnifier-popup/magnifier-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isNull, isUndefined,isArray} from 'util';
import { CommonService } from 'src/app/sharedModules/common.service';

@Component({
  selector: 'app-filter-for-chart',
  templateUrl: './filter-for-chart.component.html',
  styleUrls: ['./filter-for-chart.component.css']
})
export class FilterForChartComponent implements OnInit {


  //-----------------------------
  //  
  //-----------------------------
  orginalData: any = [];
  @Input() getDetails;
  @Output() queryOutput = new EventEmitter();
  framedData: any = [];
  formVisible: any = false;
  myForm: FormGroup;
  config_multi: any = {
    format: "DD-MMM-YYYY",
    allowMultiSelect: true,
    appendTo: "body"
  }
  config_single: any = {
    format: "DD-MMM-YYYY",
    allowMultiSelect: false,
    appendTo: "body"
  }


  //-----------------------------
  //  
  //-----------------------------
  constructor(
    private service: ApiService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private commonService: CommonService,) { 
     
    }


  //-----------------------------
  //  
  //-----------------------------
  ngOnInit() {
    
    this.myForm = this.fb.group({
      filterData: this.fb.array([])
    })
    this.framingData();
  }


  //-----------------------------
  //  
  //-----------------------------
  framingData() {
    let control = <FormArray>this.myForm.controls.filterData;
    this.getDetails.forEach((element,ind,arr) => {
      arr[ind]['DEFAULT_VALUE1'] = (element.CATEGORY == 'Dropdown' && element.SELECTION_TYPE == 'multi' && !isArray(element.DEFAULT_VALUE1) ) ? element.DEFAULT_VALUE1.split(",") : element.DEFAULT_VALUE1;
      control.push(this.fb.group(this.initArray(element)))
    });
    this.dropDownBinding();
  }


  //-----------------------------
  //  
  //-----------------------------
  closeModalFn() {
    this.queryOutput.emit('Y');
    // this.modalService.dismissAll();
  }


  //-----------------------------
  //  
  //-----------------------------
  initArray(element) {
    let mul_data = (element.CATEGORY == 'Dropdown' && element.SELECTION_TYPE == 'multi') ? element.value1.split(",").length == 1 ? element.value1.split(",")[0].length == 0 ? '' : element.value1.split(",") : element.value1.split(",") : '';

    let def_mul_data = (element.CATEGORY == 'Dropdown' && element.SELECTION_TYPE == 'multi') ? isUndefined(element.DEFAULT_VALUE1) ? '' : element.DEFAULT_VALUE1 : '';

    let data = {
      startDisp: true,
      endDisp: element.CATEGORY == 'Range' ? true : false,
      selectionType: element.SELECTION_TYPE,
      type: element.COL_DISPLAY_TYPE == 'D' ? 'date' :
        element.CATEGORY == 'Magnifier' ? 'popup' :
          element.CATEGORY == 'Dropdown' ? 'select' :
            element.CATEGORY == 'Default' && element.SELECTION_TYPE == 'date' ? 'date' :
              element.CATEGORY == 'Default' && element.SELECTION_TYPE == 'number' ? 'number' :
                element.CATEGORY == 'Range' && element.SELECTION_TYPE == 'date' ? 'date' :
                  element.CATEGORY == 'Range' && element.SELECTION_TYPE == 'number' ? 'number' : 'text',
      startValue: (element.CATEGORY == 'Dropdown' && element.SELECTION_TYPE == 'multi') ? [mul_data || def_mul_data] : [element.value1 || element.DEFAULT_VALUE1],
      displayName: element.DISPLAY_NAME,
      useColumn: element.USE_COLUMN,
      queryId: element.QUERY_ID,
      displayColumn: element.DISPLAY_COLUMN,
      dropDownDetails: '',
      default_value1: [element.DEFAULT_VALUE1],
      default_value2: [element.DEFAULT_VALUE2],
      category: element.CATEGORY,
      config: element.SELECTION_TYPE == 'multi' ? this.config_multi : this.config_single,
      columnName: element.COL_ALIAS_NAME,
      endValue: [element.value2 || element.DEFAULT_VALUE2]
    }
    // console.log("filter-chart.....",data)
    // if (element.CATEGORY == 'Range') {
    //   data['endValue'] = [element.value2];
    // } else {
    //   data['endValue'] = [element.value2];
    // }
    // console.log(data)
    return data;
  }

  resetForm() {
    let array = <FormArray>this.myForm.controls.filterData;
    array['value'].forEach((element, index, arr) => {
      array['controls'][index].patchValue({
        startValue: array['value'][index]['default_value1'],
        endValue: array['value'][index]['default_value2']
      })
    })
  }
  //-----------------------------
  //  
  //-----------------------------
  submit(value: any) {
    this.orginalData = [];
    // if (!this.myForm.valid) {
    //   this.markAllDirty(this.myForm);
    //   return false;
    // }
    let queryData = value.filterData;
    queryData.forEach((element, index, array) => {
      let obj = {
        displayName: element.displayName,
        value1: `'${element.startValue}'`,
        value2: !isNull(element.endValue) ? `'${element.endValue}'` : '',
        condition: element.selectionType == 'multi' ? 'in' : element.category == 'Range' ? 'between' : '=',
        type: element.type != 'date' && element.type != 'number' ? 'text' : element.type == 'date' ? 'd' : 'number',
        columnName: element.columnName,
        default_value1: element.default_value1 || '',
        default_value2:element.default_value2 || '',
        dateConversionSyntax: this.getDetails[index].DATECONVERSIONSYNTAX,
        dateFormattingSyntax: this.getDetails[index].DATEFORMATTINGSYNTAX
      }
      this.orginalData.push(obj);
    });
    this.queryOutput.emit(this.orginalData);
  }


  //-----------------------------
  //  
  //-----------------------------
  openModal(index, item) {
    let control = <FormArray>this.myForm.controls.filterData;
    const modelRef = this.modalService.open(MagnifierPopupComponent, {
      size: <any>'md',
      backdrop: 'static'
    });
    let filter = item.value;
    let data = {
      selected_index: index,
      input_key: 'startValue',
      useColumn: filter.useColumn,
      selectedList: Array.isArray(filter.startValue) ? filter.startValue : filter.startValue.split(','),
      selection_type: filter.selectionType == 'multi' ? 'multiple' : 'single',
      filter_key: filter.displayColumn,
      magList: filter.selectionType == 'multi' ? filter['dropDownDetails'] : this.listOfMultiValues(filter['dropDownDetails']),
      allType: filter.selectionType == 'multi' ? true : false
    }
    modelRef.componentInstance.getDetails = data;
    modelRef.componentInstance.afterSelected.subscribe((selected_element) => {
      control.controls[index].patchValue({
        startValue: selected_element['selectedList']
      });
    });
    modelRef.componentInstance.repBack.subscribe((e) => {
      if (e) {
        modelRef.close();
      }
    });
  }


  //-----------------------------
  //  
  //-----------------------------
  dropDownBinding() {
    let control = <FormArray>this.myForm.controls.filterData;
    this.getDetails.forEach((element, index, array) => {
      let obj = {
        useColumn: control.value[index]['useColumn'],
        queryId: control.value[index]['queryId'],
        displayColumn: control.value[index]['displayColumn'],
      }
      
      this.service.post(environment.get_designAnalysis_magData, obj).subscribe(resp => {
        if (resp['status']) {
        
          if (control.controls[index].value.category == 'Dropdown') {
            control.controls[index].patchValue({
              dropDownDetails: this.listOfValues(resp["response"])
            });
          } else {
            control.controls[index].patchValue({
              dropDownDetails: resp["response"]
            });
          }

          this.formVisible = true;
        }
        else {
        
          control.controls[index].patchValue({
            dropDownDetails: ''
          });
          this.formVisible = true;
        }
      })
    })
  }

  selectionChange(item, index) {
    if (item.value.selectionType == 'multi') {
      let bol_start = item.value.startValue.some(element => element == 'All');
      if (bol_start == true) {
        let control = <FormArray>this.myForm.controls.filterData;
        control.controls[index].patchValue({
          startValue: ['All']
        });
      }
    }
  }

  listOfValues(resp) {
    let data = Object.assign({}, resp[0]);
    for (let val in data) {
      data[val] = 'All'
    }
    return [data, ...resp];
  }

  listOfMultiValues(resp) {
    let data = Object.assign({}, resp[0]);
    for (let val in data) {
      if (val != 'isChecked' && val != 'id' && val != 'pagination_id') {
        data[val] = 'All'
      }
    }
    return [data, ...resp];

  }

  //-----------------------------
  // Validation Error
  //-----------------------------
  markAllDirty(control: AbstractControl) {
    if (control.hasOwnProperty('controls')) {
      control.markAsDirty({ onlySelf: true }) // mark group
      let ctrl = <any>control;
      for (let inner in ctrl.controls) {
        this.markAllDirty(ctrl.controls[inner] as AbstractControl);
      }
    }
    else {
      (<FormControl>(control)).updateValueAndValidity();
      (<FormControl>(control)).markAsDirty({ onlySelf: true });
    }
  }

}  