import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/sharedModules/common.service';
import { CanDeactivateService } from './can-deactivate.service';
import { EnvService } from './../../env.service';
import { ApiService } from 'src/app/_services/api.service';
import { AuthService } from './../auth/auth.service';
import { JwtService } from './../jwt.service';
import Cookies from 'universal-cookie';
import jwt_decode from "jwt-decode";
import { isNullOrUndefined } from 'util';


export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}
@Injectable({
  providedIn: 'root'
})
export class AuuthGaurdGuard implements CanActivate, CanDeactivate<any> {
  private domain: any = ''
  private ssoAuth:boolean
  constructor(private router: Router, private common: CommonService, private dialogService: CanDeactivateService,
    private jwtService: JwtService,
    private service: ApiService, public authService: AuthService, public env: EnvService) { 
    this.domain = env.domain
    const cookies = new Cookies()
    if(env.ssoAuth){
      this.authService.healthCheck().subscribe((success)=>{
        if(success.status === "UP"){
          cookies.set('ssoAuth', true , {domain: this.domain})
          this.ssoAuth =  true
        }
        else if(success.status === "DOWN"){
          cookies.set('ssoAuth', false , {domain: this.domain})
          this.ssoAuth =  false
        }
      })
    }
    else{
      cookies.set('ssoAuth', false , {domain: this.domain})
      this.ssoAuth =  false
    }

  }


  //------------------------------------------------------------
  //  CAN ACTIVATE ROUTE
  //------------------------------------------------------------
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let path = route.data[0];
    this.common.toolBarTitle.next(path);
    const cookie = new Cookies()
    const accessToken = cookie.get('accessToken')
    const refreshToken = cookie.get('refreshToken')
    this.ssoAuth = cookie.get('ssoAuth')?JSON.parse(cookie.get('ssoAuth')) :false 
    let jwtAccessToken;
    let jwtRefreshToken;
    let accessTokenTimer
    let refreshTokenTimer
    let authCheck

    if (accessToken) {
      let jwtToken: any = jwt_decode(accessToken)
      jwtAccessToken= jwt_decode(accessToken)
      accessTokenTimer = jwtToken.exp * 1000
      if (jwtToken.productList !== []) {
             authCheck = jwtToken.productList.some(el => el === 'SSBI'); 
      }
      else {
        authCheck = false
      }

    }
    if (refreshToken) {
      let jwtToken: any = jwt_decode(refreshToken)
      jwtRefreshToken= jwt_decode(refreshToken)
      refreshTokenTimer = jwtToken.exp * 1000
    }
    const authToken1 = this.jwtService.get('VisionAuthenticate');
    if(this.ssoAuth){
      if(!isNullOrUndefined(jwtAccessToken.exp)){
        if (accessTokenTimer && (Date.now() >= accessTokenTimer)) {
          if(this.env.ssoAuth){
            this.authService.healthCheck().subscribe((success)=>{
                if(success.status === "UP"){
                    this.authService.refreshToken().subscribe((response: any) => {
                        cookies.remove('accessToken', { domain: this.domain })
                        cookies.remove('refreshToken', { domain: this.domain })
                        cookies.set('accessToken', response.access_token, { domain: this.domain });
                        cookies.set('refreshToken', response.refresh_token, { domain: this.domain });
  
                    });
                  const cookies = new Cookies()
                  cookies.set('ssoAuth',true, { domain: this.domain });
                }
                else if(success.status === "DOWN"){
                  const cookies = new Cookies()
                  cookies.set('ssoAuth',false, { domain: this.domain });
                  this.router.navigate(['/auth/login'])
                  
                }
              })
            }
            else{
                const cookies = new Cookies()
                cookies.set('ssoAuth',false, { domain: this.domain });
                this.router.navigate(['/auth/login'])
            }
        }
      }
    }

    // if(accessTokenTimer && (Date.now() <= accessTokenTimer)  === false){
    //       cookie.remove('accessToken')

    // }

    // if (accessToken && accessTokenTimer && (Date.now() <= accessTokenTimer)) {
    //   let response = await this.service.getUser(accessToken).toPromise();
    //   if (response) {
    //     let project = response.productList.forEach(product => {
    //       authCheck = product.applicationAccess === 'SSBI'? true : false
    //     });
    //   }

    // }
    // else {
    //   cookie.remove('accessToken')
    //   cookie.remove('refreshToken')
    // }
    if (this.ssoAuth) {
      if (accessToken && refreshToken) {
        if (authCheck) {
          this.jwtService.unset('VisionAuthenticate')
          return true
        }
        else {
          this.router.navigate(['/redirectlogin'], { state: { data: authCheck } })
          return false
        }

      }
      else if (!refreshToken && !accessToken) {
        // if (!refreshToken && refreshTokenTimer && (Date.now() > refreshTokenTimer)) {
        if(!isNullOrUndefined(jwtRefreshToken.exp)){
          if(Date.now() > refreshTokenTimer){
            this.jwtService.unset('VisionAuthenticate')
            cookie.remove('authToken')
            cookie.remove('accessToken', { domain: this.domain })
            cookie.remove('refreshToken', { domain: this.domain })
            cookie.remove('url')
            this.router.navigate(['/redirectlogin']);
            return false
          }
        }
        // }
        // this.router.navigate(['/redirectlogin']);
        // return false

      }
      else {

        this.router.navigate(['/redirectlogin']);
        // this.router.navigate(['/auth/login'])
        return false

      }
    }
    else {
      if (authToken1) {
        return true
      }
      else {
        this.router.navigate(['/auth/login'])
        return false
      }
    }
  }

  canDeactivate(component: any, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): any {
    if (!component.isUpdating) {
      component.isUpdating = false;
      return this.dialogService.confirm('Discard changes for Country?',currentState.url);
    }
    return true;
  }


}
