import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { isNull, isUndefined } from 'util';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/_services/api.service';
import { environment } from 'src/environments/environment';
import { ExecutedService } from '../../query-module/query-service/executedQueryService/executed.service';

import { SaveQueryService } from '../../query-module/query-service/save-query.service';
import { CommonService } from 'src/app/sharedModules/common.service';

@Component({
  selector: 'app-execute-query',
  templateUrl: './execute-query.component.html',
  styleUrls: ['./execute-query.component.css']
})
export class ExecuteQueryComponent implements OnInit, AfterViewInit {


  @Input() getDetails;
  @Output() closeModelPopup = new EventEmitter();

  tbodyDetails: any = [];
  theadDetails: any = [];

  viewPort: any = {
    report_Details: false,
    formView: false,
    tableView: false,
    button_text: 'show',
    heading: 'Execute Query'
  }
  reportDescription: any = ''
  hashTagForm: FormGroup;
  hashTag: any = [];
  hashDetails: any = {};
  reportId: any = '';
  constructor(
    private modelService: NgbModal,
    private service: ApiService,
    private executeService: ExecutedService,
    private commonService: CommonService,
    
    private fb: FormBuilder) { }


  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  ngOnInit() {
    this.reportId = this.getDetails['mainModel']['reportId'];
    this.viewPort['button_text'] = 'save' in this.getDetails ? 'Apply' : 'show';
    this.generateForm();
    this.viewPort['heading'] = 'save' in this.getDetails ? this.reportId.length ? this.getDetails.hashArray.length ? `Hash List for  ${this.reportId}` : `Report Description for ${this.reportId}` :
      'Report Description' : Object.keys(this.getDetails.response).length ? `Hash List for  ${this.reportId}` : 'Executed List';
  }

  ngAfterViewInit(): void {

  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  generateForm() {
    let resp_onse = isNull(this.getDetails['response']) ? {} : this.getDetails['response'];
    if (Object.keys(resp_onse).length) {
      this.viewPort['formView'] = true;
      let key = Object.keys(resp_onse);
      this.hashTag = key;
      let value = Object.values(resp_onse);
      this.hashTagForm = this.dynamicForm(key, value);
    }
    else {
      if ('save' in this.getDetails) {
        this.viewPort['report_Details'] = true;
        this.viewPort['heading'] = 'Report Description';
      }
      else {
        this.viewPort['formView'] = false;
        this.viewPort['tableView'] = true;
        this.applyHashTag({});
      }
    }

  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  dynamicForm(key: any, data: any) {
    const group = this.fb.group({});
    key.forEach((field, index, array) => {
      const control = this.fb.control(
        data[index], Validators.required
      );
      group.addControl(field, control);
    });
    return group;
  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  applyHashTag(formValue: any) {
    let obj_model = {};
    let orginal_model = Object.assign({}, this.getDetails)
    obj_model['mainModel'] = orginal_model['mainModel'];
    obj_model['hashArray'] = Object.keys(formValue) || [];
    obj_model['hashValueArray'] = Object.values(formValue) || [];

    if ('reportFields' in this.getDetails) {
      obj_model['reportFields'] = this.getDetails['reportFields']
    }

    if ('dashboard' in this.getDetails) {
      this.closeModelPopup.emit(obj_model);
    } else {

      this.hashDetails['hashArray'] = obj_model['hashArray'];
      this.hashDetails['hashValueArray'] = obj_model['hashValueArray'];

      if ('save' in this.getDetails) {
        this.viewPort['report_Details'] = true;
        this.viewPort['heading'] = 'Report Description';
      }
      else {
        
        let execute = Object.assign({}, obj_model);
        execute['execute'] = true;
        this.commonService.reportDetails.next(execute);
        this.service.post(this.getDetails['apiLink'], obj_model).subscribe(resp => {
          if (resp['status']) {
            this.executedTable(resp);
          
          }
          else {
            this.commonService.showToastr.error(resp['message']);
            this.closeModelPopup.emit('');
          
          }
        })
      }
    }
  }


  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  executedTable(resp: any) {
    this.viewPort['formView'] = false;
    this.viewPort['tableView'] = true;
    this.viewPort['heading'] = 'Executed List';
    this.viewPort['heading'] = this.reportId.length ? `Exected List of  ${this.reportId}` : this.viewPort['heading']
    let response_data = isNull(resp['response']) ? {} : resp['response'];
    if ('response' in response_data) {
      this.theadDetails = this.executeService.unWrappingHeader(response_data['response']);
      let obj = this.executeService.queryUnWrapping(response_data['response']);
      this.tbodyDetails = obj['tableList'];
    }
    // if ('execute' in this.getDetails || this.getDetails['status']) {
    //   this.overlay.classList.add('show')
    // }

  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  applyQuery() {
    let report_Details = Object.assign({}, this.getDetails);
    delete report_Details['response'];
    report_Details['hashArray'] = this.hashDetails['hashArray'] || [];
    report_Details['hashValueArray'] = this.hashDetails['hashValueArray'] || [];
    
    this.service.post(environment.get_designAnalysis_Save, report_Details).subscribe(resp => {
      if (resp['status']) {
        if (!isNull(resp['response']) && !isNull(resp['response']['otherInfo'])) {
          let repoDetails = {
            reportDescription: resp['response']['otherInfo'].reportDescription,
            reportId: resp['response']['otherInfo'].reportId,
            hashArray: report_Details['hashArray'],
            hashValueArray: report_Details['hashValueArray'],
            inlineSave: true
          }
          this.commonService.reportDetails.next(repoDetails);
          this.commonService.showToastr.success(resp['message']);
          this.modelService.dismissAll();
        }
      
      }
      else {
        this.commonService.showToastr.error(resp['message']);
      
      }
    });
    document.getElementById('loadTog')['disabled'] = false;
  }

  //------------------------------------------------------------
  //  
  //------------------------------------------------------------
  closeModel() {
    this.closeModelPopup.emit('');
  }

}
