export const filterModel = {
    ND: [{
            id: 'dropdown',
            text: 'Dropdown',
            type: [{ id: 'single', text: 'Single Select' }, { id: 'multi', text: 'Multiple Select' }]
        },
        {
            id: 'magnifier',
            text: 'Magnifier',
            type: [{ id: 'single', text: 'Single Select' }, { id: 'multi', text: 'Multiple Select' }]
        },
        {
            id: 'range',
            text: 'Range',
            type: [{ id: 'date', text: 'Date' }, { id: 'number', text: 'Number' }]
        },
        {
            id: 'default',
            text: 'Default',
            type: [
                { id: 'input', text: 'Input' },
                { id: 'number', text: 'Number' },
                { id: 'date', text: 'Date' }
            ]
        }
    ],
    D: [{
            id: 'range',
            text: 'Range',
            type: [{ id: 'date', text: 'Date' }, { id: 'number', text: 'Number' }]
        },
        {
            id: 'default',
            text: 'Default',
            type: [
                { id: 'input', text: 'Input' },
                { id: 'number', text: 'Number' },
                { id: 'date', text: 'Date' }
            ]
        }
    ]

    // N: [{
    //         id: 'dropdown',
    //         text: 'Dropdown',
    //         type: [{ id: 'single', text: 'Single Select' }, { id: 'multi', text: 'Multiple Select' }]
    //     },
    //     {
    //         id: 'magnifier',
    //         text: 'Magnifier',
    //         type: [{ id: 'single', text: 'Single Select' }, { id: 'multi', text: 'Multiple Select' }]
    //     },
    //     {
    //         id: 'range',
    //         text: 'Range',
    //         type: [{ id: 'date', text: 'Date' }, { id: 'number', text: 'Number' }]
    //     },
    //     {
    //         id: 'default',
    //         text: 'Default',
    //         type: [{ id: 'input', text: 'Input' }]
    //     }
    // ],

    // T: [{
    //         id: 'dropdown',
    //         text: 'Dropdown',
    //         type: [{ id: 'single', text: 'Single Select' }, { id: 'multi', text: 'Multiple Select' }]
    //     },
    //     {
    //         id: 'magnifier',
    //         text: 'Magnifier',
    //         type: [{ id: 'single', text: 'Single Select' }, { id: 'multi', text: 'Multiple Select' }]
    //     },
    //     {
    //         id: 'range',
    //         text: 'Range',
    //         type: [{ id: 'date', text: 'Date' }, { id: 'number', text: 'Number' }]
    //     },
    //     {
    //         id: 'default',
    //         text: 'Default',
    //         type: [{ id: 'input', text: 'Input' }]
    //     }
    // ],

    // C: [{
    //         id: 'dropdown',
    //         text: 'Dropdown',
    //         type: [{ id: 'single', text: 'Single Select' }, { id: 'multi', text: 'Multiple Select' }]
    //     },
    //     {
    //         id: 'magnifier',
    //         text: 'Magnifier',
    //         type: [{ id: 'single', text: 'Single Select' }, { id: 'multi', text: 'Multiple Select' }]
    //     },
    //     {
    //         id: 'range',
    //         text: 'Range',
    //         type: [{ id: 'date', text: 'Date' }, { id: 'number', text: 'Number' }]
    //     },
    //     {
    //         id: 'default',
    //         text: 'Default',
    //         type: [{ id: 'input', text: 'Input' }]
    //     }
    // ],


};