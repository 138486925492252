import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CommonService } from '../common.service';
import { Subscription } from 'rxjs';
import { isUndefined } from 'util';

@Component({
  selector: 'ngx-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.css']
})
export class CommonTableComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() dataColumnList;
  @Input() tableDatas;

  @Output() selectedata = new EventEmitter();
  compact_css: boolean = false;
  dltFilterData: any = [];
  subscription: Subscription;


  constructor(private commonService: CommonService) {
    this.subscription = this.commonService.compactTableProp.subscribe(resp => {
      this.compact_css = resp;
    });
  }

  tableType: any = '';
  ngOnInit() {
    this.tableType = this.dataColumnList[0]['parentId'];
  }


  ngAfterViewInit() {
  
  }

  shareIconVisible(value: any) {
    let return_value = true;
    return_value = this.tableType != 'catalog' || (this.tableType == 'catalog' && value.hasOwnProperty('vcStatusDesc') && value.vcStatusDesc == 'Published') ? true : false;
    return return_value;
  }

  removeFilter(value: any) {

    this.dltFilterData.splice(this.dltFilterData.indexOf(value), 1)
    this.dltColumnFilChange();
  }


  dltColumnFilChange() {
    if (!isUndefined(this.dataColumnList)) {
      this.dataColumnList.forEach(element => {
        let new1 = false;
        this.dltFilterData.forEach(element1 => {
          if (element.id == element1) {
            new1 = true;
          }
        });
        new1 ? element['icon'] = 'fa fa-filter' : delete element['icon']
      });
    }
  }


  dataTypes(data: any) {
    return typeof (data);
  }


  getRecordsToEdit(item, action) {
    // event.stopPropagation;
    var data = {
      items: item,
      action: action
    };
    this.selectedata.emit(data);
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


  styleClass(key: any, array: any) {
    if (key == 'Catalog ID') {
      return "wd70 right-padding-5";
    }
    else if (key == 'Flag') {
      return "wd10";
    }
    else if (key == 'Status' || key == 'Script Type' || key == 'Connector ID' || key == 'Maker') {
      if (array[0].columnName.split(' ')[0] == 'Connector')
        return "wd100 "
    }
  }


  displayProp(align: any, key: any) {
    let css_prop = `${align} `;
    if (key.hasOwnProperty('display')) {
      css_prop = css_prop + key.display;
    }
    return css_prop;
  }


  statusStyle(key: any, items: any) {
    if (key == 'vcrStatusDesc' || key == 'vcqStatus' || key == 'statusDesc') {
      let css_prop = 'status-btn ';
      if (items == 'Delete') {
        css_prop += 'status-btn-danger'
      }
      else if (items == 'Active') {
        css_prop += 'status-btn-success'
      }
      else if (items == 'Old') {
        css_prop += 'status-btn-warning'
      }
      return css_prop;
    }

  }
}
