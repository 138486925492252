import { Injectable } from '@angular/core';
import { isUndefined } from 'util';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from '../_services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CommonFilterPaginationService {


  dummyData: any = [];
  dummyData_cpy: any = [];
  filter_data: any = [];
  limited_data = new BehaviorSubject([]);
  start: any = 0;
  end: any = 15;
  totaldata_display = 15;

  constructor(private api: ApiService) { }


  //----------------------------------------------------------------
  //  Getting data from
  //----------------------------------------------------------------
  getDummyData(respon: any) {

    this.formatData(respon);
  }

  totalDataToDisplay(count: any) {
    this.totaldata_display = parseInt(count);
    this.start = 0,
      this.end = parseInt(count);
    let paginated_data = {
      totaldata_display: this.totaldata_display,
      list_user: this.dummyData_cpy.slice(this.start, this.end)
    }
    this.limited_data.next([paginated_data])
  }

  //----------------------------------------------------------------
  //  
  //----------------------------------------------------------------
  formatData(resp: any) {
    if (resp.length) {
      this.dummyData = resp;
      this.dummyData_cpy = resp;
      this.filter_data = resp.slice(0);
      this.dummyData_cpy.forEach((element, index, array) => {
        this.dummyData[index]['isChecked'] = element['isChecked'] || false;
        array[index]['isChecked'] = array[index]['isChecked'] || false;
        element['id'] = index + 1;
        element['pagination_id'] = index + 1;
      });
      let paginated_data = {
        totaldata_display: this.totaldata_display,
        total: resp.length,
        first: this.dummyData[0]['pagination_id'],
        list_user: this.dummyData_cpy.slice(this.start, this.end)
      }
      this.limited_data.next([paginated_data])
    }else{
      this.limited_data.next([])
    }
  }

  //----------------------------------------------------------------
  //  
  //----------------------------------------------------------------
  next() {
    this.start = this.start + this.totaldata_display;
    this.end = this.end + this.totaldata_display;
    let paginated_data = {
      totaldata_display: this.totaldata_display,
      list_user: this.dummyData_cpy.slice(this.start, this.end)
    };
    this.limited_data.next([paginated_data]);
  }

  //----------------------------------------------------------------
  //  
  //----------------------------------------------------------------
  previous() {
    this.start = this.start - this.totaldata_display;
    this.end = this.end - this.totaldata_display;
    let paginated_data = {
      totaldata_display: this.totaldata_display,
      list_user: this.dummyData_cpy.slice(this.start, this.end)
    };
    this.limited_data.next([paginated_data])
  }

  //----------------------------------------------------------------
  //  
  //----------------------------------------------------------------
  filterData(searched_list: any) {
    let fields = this.filter_data[0],
      items = this.filter_data.slice(0);
    if (items && items.length) {
      let filldata = items.filter(item => {
        for (let prop in fields) {
          for (let i = 0; i < searched_list.length; i++) {
            if (searched_list[i]['types'] == 'contains' || searched_list[i]['types'] == null) {
              if (searched_list[i][prop] &&
                item[prop].toString().toLowerCase().indexOf(searched_list[i][prop].toString().toLowerCase()) === -1) {
                return false;
              }
            }
            else if (searched_list[i]["types"] == "endWith") {
              if (
                searched_list[i][prop] &&
                item[prop]
                  .toLowerCase()
                  .endsWith(searched_list[i][prop].toLowerCase()) - 1
              ) {
                return false;
              }
            }
            else if (searched_list[i]["types"] == "startWith") {
              if (
                searched_list[i][prop] &&
                item[prop]
                  .toLowerCase()
                  .startsWith(searched_list[i][prop].toLowerCase()) - 1
              ) {
                return false;
              }
            }
            else if (searched_list[i]["types"] == "equals") {
              if (
                searched_list[i][prop] &&
                item[prop].toLowerCase() !==
                searched_list[i][prop].toLowerCase()
              ) {
                return false;
              }
            }
          }
        }
        return true;
      });
      filldata.forEach((element, index, array) => {
        array[index]['pagination_id'] = index + 1;
      });
      this.dummyData_cpy = filldata;
      this.resetPagination();
      let final_data = filldata.slice(0, this.totaldata_display);

      let last = isUndefined(filldata[filldata.length - 1]) ? this.totaldata_display : filldata[filldata.length - 1]['pagination_id'];
      let first = isUndefined(filldata[0]) ? 1 : filldata[0]['pagination_id'];

      let paginated_data = {
        total: last,
        first: first,
        totaldata_display: this.totaldata_display,
        list_user: final_data
      }
      this.limited_data.next([paginated_data]);
    }
  }



  filterData2(event: any, arr: any) {
    let fillter_target = event.target.value || '';
    let items = [...this.filter_data];
    let filldata = items.filter(element => {
      if (element[arr[0]].toLowerCase().indexOf(fillter_target.toLowerCase()) != -1 || element[arr[1]].toLowerCase().indexOf(fillter_target.toLowerCase()) != -1) {
        return true;
      }
    });
    filldata.forEach((element, index, array) => {
      array[index]['pagination_id'] = index + 1;
    });
    this.dummyData_cpy = filldata;
    this.resetPagination();
    let final_data = filldata.slice(0, this.totaldata_display);

    let last = isUndefined(filldata[filldata.length - 1]) ? this.totaldata_display : filldata[filldata.length - 1]['pagination_id'];
    let first = isUndefined(filldata[0]) ? 1 : filldata[0]['pagination_id'];

    let paginated_data = {
      total: last,
      first: first,
      totaldata_display: this.totaldata_display,
      list_user: final_data
    }

    this.limited_data.next([paginated_data]);
  }



  resetPagination() {
    this.start = 0;
    this.end = 15;
    this.totaldata_display = 15;
  }
  resetFilter() {
    this.start = 0;
    this.end = 15;
    this.totaldata_display = 15;
  }

  //----------------------------------------------------------------
  //  
  //----------------------------------------------------------------
  resetData() {
    this.dummyData_cpy = this.dummyData.slice(0);
    let paginated_data = {
      total: this.dummyData_cpy.length,
      totaldata_display: this.totaldata_display,
      first: this.dummyData_cpy[0]['pagination_id'],
      list_user: this.dummyData_cpy.slice(0, this.totaldata_display)
    }
    this.start = 0;
    this.end = this.totaldata_display;
    this.limited_data.next([paginated_data]);
  }
  //----------------------------------------------------------------
  //  
  //----------------------------------------------------------------
  individualCheck(checkType: any, selected_id: any) {
    if (checkType == 'single') {
      this.dummyData.forEach((element, index, array) => {
        if (element['id'] == selected_id['id']) {
          array[index]['isChecked'] = true;
        }
        else {
          array[index]['isChecked'] = false;

        }
      });
    }
    else {
      selected_id['isChecked'] = !selected_id['isChecked'];
      this.dummyData.forEach((element, index, array) => {
        if (element['id'] == selected_id['id']) {
          array[index]['isChecked'] = selected_id['isChecked'];
        }
      });
    }
  }
  allCheck() {
    this.dummyData_cpy.forEach((element, index, array) => {
      array[index]['isChecked'] = false;
    });
  }

  mainCheckbox() {
    let count = 0;
    this.dummyData_cpy.forEach((element, index, array) => {
      if (element.isChecked) {
        count++;
      }
    });

    return (count == this.dummyData_cpy.length) ? true : false;
  }

  overAllCheck(shecked_data: any) {
    this.dummyData_cpy.forEach((element, index, array) => {
      if (shecked_data) {
        array[index]['isChecked'] = true;
      }
      else {
        array[index]['isChecked'] = false;
      }
    });
  }

  apply() {
    let selected_data: any = [];
    this.dummyData.forEach(element => {
      if (element.isChecked) {
        selected_data.push(element);
      }
    });
    return selected_data;
  }

}
