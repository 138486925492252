import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ITreeOptions, IActionMapping, TREE_ACTIONS } from 'angular-tree-component';

@Component({
  selector: 'app-tree-list',
  templateUrl: './tree-list.component.html',
  styleUrls: ['./tree-list.component.css']
})
export class TreeListComponent implements OnInit {
  @Input() nodes: any;
  @Input() options: any;
  @Input() template: any;
  @Output() selectedTables: EventEmitter<any> = new EventEmitter<any>();
  public treenode: any = [];
  public selectedNode: any = [];
  public options1: ITreeOptions = {
    useCheckbox: true,
    useVirtualScroll : true,
    nodeHeight: 20,
    getChildren: this.getChildren.bind(this), 
  };

  constructor() {
    this.setTreeOptions();
  }

  public getChildren(node: any) {
    const newNodes = [
      {
        name: 'child3'
      }, {
        name: 'child4'
      }
    ];
    
    return new Promise((resolve, reject) => {
       setTimeout(() => resolve(newNodes), 100);
    });
  }

  ngOnInit() {
    let preparedNode: any =[];
    for(let i=0; i<1200;i++) {
      this.nodes[i]['hasChildren'] = true;
      preparedNode.push(this.nodes[i]);
    }
    this.treenode = preparedNode;
    // this.selectedNode = [
    //   {
    //     id: 1,
    //     name: 'root1',
    //     hasChildren: true
    //   },
    //   {
    //     id: 4,
    //     name: 'root2',
    //     children: [
    //       { id: 5, name: 'child2.1' },
    //       {
    //         id: 6,
    //         name: 'child2.2'
    //       }
    //     ]
    //   }
    // ];    
  }

  setTreeOptions() {
    let defaultOptions = { useVirtualScroll : true,
      nodeHeight: 20
    }
    this.options = defaultOptions;
    const defaultActionMapping: IActionMapping = {
      mouse: {
        click: TREE_ACTIONS.TOGGLE_ACTIVE,
        dblClick: null,
        contextMenu: null,
        //expanderClick: TREE_ACTIONS.TOGGLE_EXPANDED,
        checkboxClick: TREE_ACTIONS.TOGGLE_SELECTED,
        drop: TREE_ACTIONS.MOVE_NODE
      },
      // keys: {
      //   [KEYS.RIGHT]: TREE_ACTIONS.DRILL_DOWN,
      //   [KEYS.LEFT]: TREE_ACTIONS.DRILL_UP,
      //   [KEYS.DOWN]: TREE_ACTIONS.NEXT_NODE,
      //   [KEYS.UP]: TREE_ACTIONS.PREVIOUS_NODE,
      //   [KEYS.SPACE]: TREE_ACTIONS.TOGGLE_ACTIVE,
      //   [KEYS.ENTER]: TREE_ACTIONS.TOGGLE_ACTIVE
      // }
    };
  }
  public onSelect(event) {
    if(event.node.data.hasChildren && event.node.data.children == undefined) {
      event.node.expand();
      this.setSelectedNode(event);
    } else {
      
    }
  }

  public onDeselect(event) {
    //console.log(event.node.parent.data);
  }

  setSelectedNode(event) {
    let parentNode = event.node.data;
    const selected = Object.assign({}, event.treeModel.selectedLeafNodeIds);
    //console.log(selected);
    let nodeExist = false;
    for(let i=0; i<this.selectedNode.length;i++) {
      if(parentNode.id == this.selectedNode[i].id){
        nodeExist = true;
      }
    }
    if(!nodeExist) {
      this.selectedNode = [...this.selectedNode, event.node.data];
    }
  }

  public applySelectedNode() {
    this.selectedTables.emit(this.selectedNode);
  }

  public clickHandler(e, n) {
    n.expand();
    for(let i=0; i< n.children.length; i++) {
      // console.log(n.children[i].setIsActive(true));
      n.children[i].setActiveAndVisible();
    }
  }
}
